import React from 'react';

const TennisRules = () => {
return (
<>
<div className='toogle-Items tennisRules'>
<ul>
<li><span className='danger'>Match</span></li>
<li><span>Match Odds: - If 1st set has been not completed at the time of the retirement or disqualification, then all bets relating to that individual match will be void.</span></li>
<li><span>Game Winner: - Predict which player will win the stated game. The nominated game will be featured in the name of the bet type, for example, 2ndset – 7th game – Winner. If a game is not completed for any reason, bets on it will be void. Tie break points will not be counted for this bet type. unless the specific market outcome is already determined.</span></li>
<li><span>Under / Over Games:- Finished set stand, the unfinished set can be played to its natural conclusion and settled as in the example: Example: A set is abandoned at 4-4 I win if I placed a bet on Over 9.5 (since any natural conclusion to the set would have at least 10 games); I lost the bet if I placed a bet on Under 9.5 (since any natural conclusion to the set would have at least 10 games); I get my stake back if I placed a bet on O/U 10.5 (it is undecided, the set could have ended 6-4).</span></li>
</ul>
</div>
</>
)
}

export default TennisRules;
