import React from 'react';
import $ from 'jquery';

const SportsLock = () => {

const closeSportsLock = () => {
$('#sports_lock').hide();
}

return (
<>
<div className='modle_sm' id='sports_lock'>
<div className='modle_sm_content'>
<div className='modle_sm_header'>
<span>sport lock</span>
<i className='fa fa-close' onClick={closeSportsLock}></i>
</div>
<div className='modle_sm_body'>
<div className='wrapContent'>
<img src='/images/lock.png' alt='lock' />
<span>sport is temporarily locked</span>
<span>contact your service provider</span>
<button onClick={closeSportsLock}>close</button>
</div>
</div>
</div>
</div>
</>
)
}

export default SportsLock;
