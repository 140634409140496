import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import Footer from '../extra/Footer';
import AcceptAge from '../modals/AcceptAge';
import { GlobalContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';

const VirtualMain = () => {

const { customer, spoSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const Navigate = useNavigate();

const headTail = () => {
if (customer.length > 0){
Navigate('/head-tails');
}
else{
$('#AcceptAgeModal').show();
}      
}

const lucky0to9 = () => {
if (customer.length > 0){
$('#upcomingModal').show();
}
else{
$('#AcceptAgeModal').show();
}      
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock2').css({display: 'flex'});
}else{
$('#sports_lock2').hide();
}
}
},[lockSport]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='singleImage'>
<img src='/images/slide5.jpg' alt='singleImg' />
</div>

<div className='game-contents'>
<div className='multimarketdIV'><img src='/images/virtualCasino.png' alt='highImg' /> <span>all available virtual casino</span></div>
<div className='rowsFours'>

<div className='sports_lock' id='sports_lock2'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<div className='colsFourSm'>
<div className='liveDiv'>
<span className='success'>live</span>
</div>
<div className='img-wrap'>
<img src='/images/head.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Head & Tails</span></div>
<div className='colsSm' onClick={() => headTail()}><span>Play Now</span></div>
</div>
</div>
<div className='colsFourSm'>
<div className='liveDiv'>
<span className='danger'>upcoming</span>
</div>
<div className='img-wrap'>
<img src='/images/luc9.png' alt='picImg' />
</div>
<div className='colRowImg'>
<div className='colsLg'><span>Lucky 0 To 9</span></div>
<div className='colsSm' onClick={() => lucky0to9()}><span>Play Now</span></div>
</div>
</div>
</div>

<Footer />
</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default VirtualMain;
