import React from 'react';

const ErrorPage = () => {
return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='game-body'>

<div className='ErrorPage'>
<div className='ErrorPageCon'>
<img src='/images/oops.png' alt='oops' />
<span className='errTxt1'>404 page not found!</span>
<span className='errTxt2'>try to search another page...</span>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default ErrorPage;
