import React, { useEffect } from 'react';
import { getAllBetsMarket } from './Betsmarket';

const MarketBets = ({eventid}) => {
//console.log(eventid);
useEffect(() => {
getAllBetsMarket(eventid);
},[eventid]);

return (
<>
<div id='allMarket_bets' className='allMarket_bets'>
<table className='betsMarket_table'>
<thead>
<tr>
<th>team</th>
<th>sec</th>
<th>type</th>
<th>odds</th>
<th>size</th>
<th>amount</th>
<th>profit</th>
<th>loss</th>
<th>date time</th>
</tr>
</thead>
<tbody id='allMarketBets'></tbody>
</table>
</div>
</>
)
}

export default MarketBets;
