import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';

const AllSports = () => {

const { spoSett, customer, tennList, soccList, gameSett, cricCompAll } = GlobalContext();
const [lockSport, setLockSport] = useState([]);
const [currData2, setCurrData2] = useState([]);
const [compCric2, setCompCric2] = useState([]);
const [compSoc2, setCompSoc2] = useState([]);
const [compTenn2, setCompTenn2] = useState([]);
const Navigate = useNavigate();

const sportsShow = async(eid) => {
var compArr = [1, 2, 4];
$('.sideDivUl2').hide();
$('#loading_sideMenu2').css({display: 'flex'});
setTimeout(function(){
$('#loading_sideMenu2').hide();
},500);

compArr.forEach((ele) => {
if(ele === eid){
$('#allComp2_'+ele).toggle(); 
}
else{
$('#allComp2_'+ele).hide(); 
}
});

if(eid === 4){
const checkLock = lockSport.length > 0 && lockSport.filter((e) => e.sport === 'cricket');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
var cricData = [];
cricCompAll.forEach((e) => {
var data = {
cname: e.comp_name,
cid: e.comp_id   
}

const findInd = cricData.findIndex((o) => o.cid === e.comp_id );
if(findInd === -1){
cricData.push(data); 
}
});
setCompCric2(cricData);
}
}
else if(eid === 1){
const checkLock = lockSport.length > 0 && lockSport.filter((e) => e.sport === 'soccer');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
var allCompSoc = [];
soccList.forEach((e) => {
var data = {
cname: e.cname,
cid: e.cid
}

const findInd = allCompSoc.findIndex((o) => o.cid === e.cid);
if(findInd === -1){
allCompSoc.push(data);
}
});
setCompSoc2(allCompSoc);
}
}
else if(eid === 2){
const checkLock = lockSport.length > 0 && lockSport.filter((e) => e.sport === 'tennis');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
var allCompSin = [];
tennList.forEach((e) => {
var data = {
cname: e.cname,
cid: e.cid
}

const findInd = allCompSin.findIndex((o) => o.cid === e.cid);
if(findInd === -1){
allCompSin.push(data);
}
});
setCompTenn2(allCompSin);
}
}
}

const showEvents2 = async(id,eid) => {
$('#loading_sideMenu2').css({display: 'flex'});
setTimeout(function(){
$('#loading_sideMenu2').hide();
},500);

if(eid === 4){
if(cricCompAll.length > 0){
cricCompAll.forEach((ele) => {
if(ele.comp_id === id){
$('#eventListDiv2_'+ele.comp_id).show();
}else{
$('#eventListDiv2_'+ele.comp_id).hide();
}
});

const findData = cricCompAll.filter((e) => e.comp_id === id);
if(findData.length > 0){
setCurrData2(findData);  
}
else{
setCurrData2([]);   
}
}
}
else if(eid === 1){
if(soccList.length > 0){
soccList.forEach((ele) => {
if(ele.cid === id){
$('#eventListDiv2_'+ele.cid).show();
}else{
$('#eventListDiv2_'+ele.cid).hide();
}
});

const findData = soccList.filter((e) => parseFloat(e.cid) === parseFloat(id));
//console.log(findData);
if(findData.length > 0){
setCurrData2(findData);
}
else{
setCurrData2([]);
}
}
}
else if(eid === 2){
if(tennList.length > 0){
tennList.forEach((e) => {
if(e.cid === id){
$('#eventListDiv2_'+e.cid).show();
}  
else{
$('#eventListDiv2_'+e.cid).hide();
}
});

const findData = tennList.filter((e) => parseFloat(e.cid) === parseFloat(id));
if(findData.length > 0){
setCurrData2(findData);
}
else{
setCurrData2([]);
}
}
}

}

const openMarket2 = (eventid,marketid,eid) => {
if(eid === 4){
Navigate('/market-4/'+eventid+'/'+marketid);
}
else if(eid === 1){
Navigate('/market-1/'+eventid+'/'+marketid);
}
else if(eid === 2){
Navigate('/market-2/'+eventid+'/'+marketid);
}
}

const headtailsSpo = () => {
if(lockSport.length > 0 && customer.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/head-tails');   
}
}
else if(customer.length > 0){
Navigate('/head-tails');
}
else{
$('#AcceptAgeModal').show();
}
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
var CrrSpo = [];
if(gameSett.length > 0){
gameSett.forEach((e) => {
var data = {
sports: e.sport
}

const findInd = CrrSpo.findIndex((o) => o.sports === e.sport);
if(findInd === -1){
CrrSpo.push(data); 
}
});

CrrSpo.forEach((e) => {
if(e.sports === 'cricket'){
compCric2.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'cricket')).forEach((k) => {
$('#comLock2_'+k.cid).css({display: 'grid'});
});
}

if(e.sports === 'soccer'){
compSoc2.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'soccer')).forEach((k) => {
$('#comLock2_'+k.cid).css({display: 'grid'});
});
}

if(e.sports === 'tennis'){
compTenn2.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'tennis')).forEach((k) => {
$('#comLock2_'+k.cid).css({display: 'grid'});
});
}

});
}
},[gameSett, compCric2, compSoc2, compTenn2]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>

<div className='sportsDivWrap'>
<div className='loading-sideMenu2' id='loading_sideMenu2'>
<span className='loadingDot_side'></span>
<span className='loadingDot_side'></span>
<span className='loadingDot_side'></span>
</div>

<div className="highlightdIV"><img src="/images/cricket.png" alt="highImg" /> <span>All Sports</span></div>

<div className='all-sportsDiv'>
<ul className='sportsDivUl1'>
<li><span onClick={() => sportsShow(4)}><img src='/images/side-cricket.png' alt='sideimg' /> cricket <i className='fa fa-angle-right'></i></span>
<div className='allComp2' id='allComp2_4'>
<ul>
{
compCric2.length > 0 && compCric2.map((data, index) => {
return (
<li key={index}><span onClick={() => showEvents2(data.cid,4)}><i className='fa fa-angle-double-right'></i> {data.cname}</span>
<div className='compLock' id={'comLock2_'+data.cid}>
<span>game is locked <i className='fa fa-lock colLock'></i></span>
</div>
<div className='sideDivUl2' id={'eventListDiv2_'+data.cid}>
<ul>
{
currData2.length > 0 && currData2.map((data2, index2) => {
return (
<li key={index2} onClick={() => openMarket2(data2.event_id,data2.market_id,4)}><span><i className='fa fa-dot-circle-o'></i> {data2.event_name}</span></li>
)
})
}
</ul>
</div>
</li>
)
}) 
}
</ul>
</div>
</li>
<li><span onClick={() => sportsShow(1)}><img src='/images/side-soccer.png' alt='sideimg' /> soccer <i className='fa fa-angle-right'></i></span>
<div className='allComp2' id='allComp2_1'>
<ul>
{
compSoc2.length > 0 && compSoc2.map((data, index) => {
return (
<li key={index}><span onClick={() => showEvents2(data.cid,1)}><i className='fa fa-arrow-right'></i> {data.cname}</span>
<div className='compLock' id={'comLock2_'+data.cid}>
<span>game is locked <i className='fa fa-lock colLock'></i></span>
</div>
<div className='sideDivUl2' id={'eventListDiv2_'+data.cid}>
<ul>
{
currData2.length > 0 && currData2.map((data2, index2) => {
return (
<li key={index2} onClick={() => openMarket2(data2.gmid,data2.mid,1)}><span><i className='fa fa-dot-circle-o'></i> {data2.ename}</span></li>
)
})
}
</ul>
</div>
</li>
)
}) 
}
</ul>
</div>
</li>
<li><span onClick={() => sportsShow(2)}><img src='/images/side-tennis.png' alt='sideimg' /> tennis <i className='fa fa-angle-right'></i></span>
<div className='allComp2' id='allComp2_2'>
<ul>
{
compTenn2.length > 0 && compTenn2.map((data, index) => {
return (
<li key={index}><span onClick={() => showEvents2(data.cid,2)}><i className='fa fa-arrow-right'></i> {data.cname}</span>
<div className='compLock' id={'comLock2_'+data.cid}>
<span>game is locked <i className='fa fa-lock colLock'></i></span>
</div>
<div className='sideDivUl2' id={'eventListDiv2_'+data.cid}>
<ul>
{
currData2.length > 0 && currData2.map((data2, index2) => {
return (
<li key={index2} onClick={() => openMarket2(data2.gmid,data2.mid,2)}><span><i className='fa fa-dot-circle-o'></i> {data2.ename}</span></li>
)
})
}
</ul>
</div>
</li>
)
}) 
}
</ul>
</div>
</li>
</ul>
</div>

<div className="highlightdIV"><img src="/images/casino.png" alt="highImg" /> <span>All Casino</span></div>

<div className='all-sportsDiv'>
<ul className='sportsDivUl1'>
<li><span><img src='/images/card-game.png' alt='sideimg' /> teenpatti 20 20 <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/card-game.png' alt='sideimg' /> teenpatti one day <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/card-game.png' alt='sideimg' /> teenpatti test <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/leo-lion-head-side.png' alt='sideimg' /> dragon tiger 20 20 <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/leo-lion-head-side.png' alt='sideimg' /> dragon tiger one day <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/7.png' alt='sideimg' /> lucky 7 a <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/7.png' alt='sideimg' /> lucky 7 b <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/card-game.png' alt='sideimg' /> andar bahar <i className='fa fa-angle-right'></i></span></li>
</ul>
</div>

<div className="highlightdIV"><img src="/images/virtualCasino.png" alt="highImg" /> <span>All Virtual Game</span></div>

<div className='all-sportsDiv'>
<ul className='sportsDivUl1'>
<li onClick={headtailsSpo}><span><img src='/images/coin-toss.png' alt='sideimg' /> head & tails <i className='fa fa-angle-right'></i></span></li>
<li><span><img src='/images/casino-wheel.png' alt='sideimg' /> lucky 0 to 9 <i className='fa fa-angle-right'></i></span></li>
</ul>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default AllSports;
