import React from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const MobileFooter = () => {
const Navigate = useNavigate();
const { customer } = GlobalContext();

const footMenu = (val) => {
var arrData = [1,2,3,4,5];
arrData.forEach((e) => {
if(e === val){
$('#footMenu_'+e).addClass('mobileFooteractive');
}else{
$('#footMenu_'+e).removeClass('mobileFooteractive');    
}
});

$('.mainMenuLi').removeClass('activeMain');

if(val === 1){
Navigate('/');
}
else if(val === 2){
Navigate('/in-play');
}
else if(val === 3){
Navigate('/all-sports');
}
else if(val === 4){
Navigate('/multimarket');
}
else if(val === 5){
if(customer.length > 0){
Navigate('/mobile-account');
}
else{
$('#AcceptAgeModal').show();
}
}

}

return (
<>
<div className='mobileFooter'>
<div className='col'>
<div className='footMenu' id='footMenu_1' onClick={() => footMenu(1)}>
<img src='/images/home_f.png' alt='home_f' />
<span>home</span>
</div>
</div>
<div className='col'>
<div className='footMenu' id='footMenu_2' onClick={() => footMenu(2)}>
<img src='/images/timer_f.png' alt='timer_f' />
<span>in-play</span>
</div>
</div>
<div className='col'>
<div className='footMenu sportsDiv' id='footMenu_3' onClick={() => footMenu(3)}>
<img src='/images/trophy_f.png' alt='trophy_f' />
<span>sports</span>
</div>
</div>
<div className='col'>
<div className='footMenu' id='footMenu_4' onClick={() => footMenu(4)}>
<img src='/images/pin_f.png' alt='pin_f' />
<span>multi market</span>
</div>
</div>
<div className='col'>
<div className='footMenu' id='footMenu_5' onClick={() => footMenu(5)}>
<img src='/images/user_f.png' alt='user_f' />
<span>account</span>
</div>
</div>
</div>
</>
)
}

export default MobileFooter;
