import { createContext, useContext, useEffect, useReducer } from "react";
import axios from 'axios';
import Reducer from "../reducer/Reducer";

const Context = createContext();

const CricListApi = '/server/cricket-list';
const SoccListApi = '/server/betfair-soccer';
const TennListApi = '/server/betfair-tennis';
const customerData = '/server/active-customer';
const customerMulti = '/server/customer-multimarket';
const userActivityLog = '/server/user-activity-log';
const userExposure = '/server/user-exposure';
const noticeApi = '/server/notifications';
const spoSettApi = '/server/sports_settings';
const gameSettApi = '/server/game_settings';
const betSettingApi = '/server/bet_settings';
const CompetitionCricListApi = '/server/competition-list/4';
const EventListApi = '/server/events-list';

const initialstate = {
isLoadCric: false,
isCricErr: false,
cicList: [],
isLoadSoc: false,
isSocErr: false,
soccList: [],
isLoadTenn: false,
isTennErr: false,
tennList: [],
isUserLoad: false,
isUserErr: false,
customer: [],
isMultiLoad: false,
isMultiErr: false,
multimarket: [],
isLoadActvity: false,
isErrActivity: false,
activityLog: [],
isExpoLoad: false,
isExpoErr: false,
exposure: [],
isProfitLoad: false,
isProfitErr: false,
profitLossUser: [],
isCricMarLoad: false,
isCricMarErr: false,
cricMarket: [],
isSoccMarLoad: false,
isSoccMarErr: false,
soccMarket: [],
isTennMarLoad: false,
isTennMarErr: false,
tennMarket: [],
isCompetitionLoad : false,
isCompetitionErr : false,
CompetitionList : [],
noticeLoad: false,
noticeErr: false,
noticeAll: [],
loadSpoSett: false,
errSpoSett: false,
spoSett: [],
loadGameSett: false,
errGameSett: false,
gameSett: [],
loadCricComp: [],
errCricComp: [],
cricCompAll: [],
loadBetSett: false,
errBetSett: false,
betSett: [],
loadAllBet: false,
errAllBet: false,
allBetsData: []
}

const Provider = ({children}) => {
const [state, dispatch] = useReducer(Reducer, initialstate);

const GetCricList = async(URL) => {
dispatch({type: 'CRICKET_LIST_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'CRICKET_LIST_SET', payload: resData});
} catch (error) {
dispatch({type: 'CRICKET_LIST_ERROR'}); 
}
}

const GetSoccList = async(URL) => {
dispatch({type: 'SOCCER_LIST_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SOCCER_LIST_SET', payload: resData});
} catch (error) {
dispatch({type: 'SOCCER_LIST_ERROR'}); 
}
}

const GetTennList = async(URL) => {
dispatch({type: 'TENNIS_LIST_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'TENNIS_LIST_SET', payload: resData});
} catch (error) {
dispatch({type: 'TENNIS_LIST_ERROR'}); 
}
}

const GetUserList = async(URL) => {
dispatch({type: 'USER_LIST_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'USER_LIST_SET', payload: resData});
} catch (error) {
dispatch({type: 'USER_LIST_ERROR'}); 
}
}

const GetMultiList = async(URL) => {
dispatch({type: 'MULTI_LIST_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'MULTI_LIST_SET', payload: resData});
} catch (error) {
dispatch({type: 'MULTI_LIST_ERROR'}); 
}
}

const GetUserActivity = async(URL) => {
dispatch({type: 'ACTIVITY_LOG_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'ACTIVITY_LOG_SET', payload: resData});
} catch (error) {
dispatch({type: 'ACTIVITY_LOG_ERROR'}); 
}
}

const GetUserExposure = async(URL) => {
dispatch({type: 'USER_EXPO_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'USER_EXPO_SET', payload: resData});
} catch (error) {
dispatch({type: 'USER_EXPO_LOG_ERROR'}); 
}
}

const GetProfitLoss = async(select_sport,start_date,end_date) => {
dispatch({type: 'PROFIT_LOSS_LOADING'});
try {
const sendData = await axios.post('/server/profit-loss/', {
select_sport: select_sport,
start_date: start_date,
end_date: end_date
});
const resData = await sendData.data;
dispatch({type: 'PROFIT_LOSS_SET', payload: resData});
} catch (error) {
dispatch({type: 'PROFIT_LOSS_ERROR'}); 
}
}

const GetCricketMarket = async(URL) => {
dispatch({type: 'CRICKET_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'CRICKET_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'CRICKET_MARKET_ERROR'}); 
}
}

const GetSoccerMarket = async(URL) => {
dispatch({type: 'SOCCER_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SOCCER_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'SOCCER_MARKET_ERROR'}); 
}
}

const GetTennisMarket = async(URL) => {
dispatch({type: 'TENNIS_MARKET_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'TENNIS_MARKET_SET', payload: resData});
} catch (error) {
dispatch({type: 'TENNIS_MARKET_ERROR'}); 
}
}

const GetNotice = async(URL) => {
dispatch({type: 'NOTICE_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'NOTICE_SET', payload: resData});
} catch (error) {
dispatch({type: 'NOTICE_ERROR'}); 
}
}

const GetSpoSett = async(URL) => {
dispatch({type: 'SPORT_SETTING_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'SPORT_SETTING_SET', payload: resData});
} catch (error) {
dispatch({type: 'SPORT_SETTING_ERROR'}); 
}
}

const GetGameSett = async(URL) => {
dispatch({type: 'GAME_SETTING_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'GAME_SETTING_SET', payload: resData});
} catch (error) {
dispatch({type: 'GAME_SETTING_ERROR'}); 
}
}

const GetCompCric = async(URL) => {
var pushData = [];
var pushData2 = [];
dispatch({type: 'CRICKET_COMP_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
if(resData.length > 0){
resData.forEach( async(e) => {
var competitionId = e.competition.id;
var competitionName = e.competition.name;
try {
const getData = await axios.get(`${EventListApi}/${competitionId}`);
const resData2 = getData.data;
const findEvent = resData2.length > 0 && resData2.filter((f) => f.marketname === 'match odds' || f.marketname === 'match_odds');
if(findEvent.length > 0){
findEvent.forEach((g) => {
var data = {
comp_id : competitionId,
comp_name: competitionName,
event_id : g.event.id,
market_id : g.marketid,
event_name : g.event.name
}

const findInd = pushData.findIndex((h) => h.comp_id === competitionId && h.event_id === g.event.id);
if(findInd === -1){
pushData.push(data); 
}
});
}
} catch (error) {
console.log('frontend appcontext eventList_cric api error : ' + error);
}
const plusJson = pushData2.concat(pushData);
if(plusJson.length > 0){
dispatch({type: 'CRICKET_COMP_SET', payload: plusJson});
}
});
}
} catch (error) {
dispatch({type: 'CRICKET_COMP_ERROR'}); 
}
}

const getBetSet = async(URL) => {
dispatch({type: 'BET_SETTING_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'BET_SETTING_SET', payload: resData});
} catch (error) {
dispatch({type: 'BET_SETTING_ERROR'}); 
}
}

const getAllBets = async(URL) => {
dispatch({type: 'ALL_BETS_LOADING'});
try {
const sendData = await axios.get(URL);
const resData = await sendData.data;
dispatch({type: 'ALL_BETS_SET', payload: resData});
} catch (error) {
dispatch({type: 'ALL_BETS_ERROR'}); 
}
}

useEffect(() => {
GetCricList(CricListApi);
GetSoccList(SoccListApi);
GetTennList(TennListApi);
GetUserList(customerData);
GetMultiList(customerMulti);
GetUserActivity(userActivityLog);
GetUserExposure(userExposure);
GetNotice(noticeApi);
GetSpoSett(spoSettApi);
GetGameSett(gameSettApi);
GetCompCric(CompetitionCricListApi);
getBetSet(betSettingApi);
},[]);

return(
<Context.Provider value={{...state, GetTennList, GetSoccList, GetCricList, getAllBets, GetMultiList, GetUserList, GetUserExposure, GetProfitLoss, GetCricketMarket, GetSoccerMarket, GetTennisMarket}}>{children}</Context.Provider>
)
}

const GlobalContext = () => {
return useContext(Context);
}

export {Context, Provider, GlobalContext};