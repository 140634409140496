import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';
let betAmt = 0;
let betRate = 0;
var allSid = [];
var matchOddsRunner = [];
var bettingAct = false;

function clearId(){
allSid = [];
matchOddsRunner = [];  
}

function oddsClick(mid,sid,type,tno,userAct){
//console.log(type +' -- '+ mid +' -- '+ sid);
if(!userAct){
$('#AcceptAgeModal').show();
return false;
}
if(type === 'back'){
betRate = $('#price_back_'+mid+sid+tno).text();
}else{
betRate = $('#price_lay_'+mid+sid+tno).text();
}
$('#bet-rate_'+mid+sid).val(betRate);
$('#bet-amount_'+mid+sid).val(0);
$('#selBetType_'+mid+sid).text(type);
$('#tno_'+mid+sid).text(tno);
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');

if(type === 'back'){
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(207, 100%, 26%, 1)', background: 'linear-gradient(180deg, hsla(207, 100%, 26%, 1) 0%, hsla(207, 80%, 61%, 1) 50%, hsla(207, 100%, 26%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#badcf7', borderTop: '2px solid #217ec4'});
}else{
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(347, 98%, 68%, 1)', background: 'linear-gradient(180deg, hsla(347, 98%, 68%, 1) 0%, hsla(347, 79%, 85%, 1) 50%, hsla(347, 98%, 68%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#fee7ec', borderTop: '2px solid #f76e8c'});    
}

for(let i=0; i < matchOddsRunner.length; i++){
if(mid === matchOddsRunner[i].mid && sid === matchOddsRunner[i].sid){
$('#placeBet_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).show();
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).text('');
}   
else{
$('#placeBet_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).hide();
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).text('');
} 
}
}

function cancelBet(mid,sid){
$('#placeBet_'+mid+sid).hide();
$('#bet-rate_'+mid+sid).val(0);
$('#bet-amount_'+mid+sid).val(0);

for(let i=0; i < matchOddsRunner.length; i++){
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).hide();   
}
}

function beforePls(mid,sid){
let rate = $('#bet-rate_'+mid+sid).val();
const type = $('#selBetType_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val(); 
//const sec = $('#sec_'+mid+sid).text().trim();
const gtype = $('#gtype_'+mid+sid).text().trim();
let a = 0;
let b = 0;
if(gtype === 'match'){
a = parseFloat((rate * betAmt - betAmt).toFixed(2)).toLocaleString();
b = betAmt;
}
else{
a = parseFloat((rate * betAmt / 100).toFixed(2)).toLocaleString();
b = betAmt;
}

//console.log(rate +' -- '+ type +' -- '+ betAmt);
for(let i=0; i < matchOddsRunner.length; i++){
if(mid === matchOddsRunner[i].mid && type === 'back'){
if(sid === matchOddsRunner[i].sid){
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#44B10A'}).show().text('('+a+')');
}
else{
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).show().text('('+b+')');
}
}
else if(mid === matchOddsRunner[i].mid && type === 'lay'){
if(sid === matchOddsRunner[i].sid){
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).show().text('('+a+')');
}
else{
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#44B10A'}).show().text('('+b+')');
}
}
}
}

function minusRate(mid,sid){
betRate = $('#bet-rate_'+mid+sid).val();
$('#bet-rate_'+mid+sid).val((parseFloat(betRate) - parseFloat('0.01')).toFixed(2));
beforePls(mid,sid);
}

function plusRate(mid,sid){
betRate = $('#bet-rate_'+mid+sid).val();
$('#bet-rate_'+mid+sid).val((parseFloat(betRate) + parseFloat('0.01')).toFixed(2)); 
beforePls(mid,sid);
}

function minusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) / 2).toFixed());
beforePls(mid,sid);
}

function plusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls(mid,sid);
}

function stakeBet(mid,sid,amt){
//console.log(sid +' -- '+ amt);
$('#bet-amount_'+mid+sid).val(amt);
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');
beforePls(mid,sid);
}

function changeAmt(mid,sid,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#bet-amount_'+mid+sid).val(0);
}else{
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');  
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');    
$('#bet-amount_'+mid+sid).val(Math.round(val));
}
beforePls(mid,sid);
}

function betSend(mid,sid){
var bet_type = $('#selBetType_'+mid+sid).text().trim();
var tno = $('#tno_'+mid+sid).text();
var gtype = $('#gtype_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val();
betRate = $('#bet-rate_'+mid+sid).val();
bettingAct = true;
let currMin = 0;
let currMax = 0;
let betDelay = 1;

$('#betMsgWrap_'+mid+sid).css({display: 'grid'});
$('.oddsDiv').attr('disabled', 'disabled');

allSid.forEach((e) => {
if(gtype === 'match'){
if(e.sec === 'match_odds'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}
}
else if(gtype === 'match1'){
if(e.sec === 'bookmaker'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}  
}
});

if (parseFloat(betAmt) < parseFloat(currMin)){
$('#bet_msg_'+mid+sid).show().html('Minimum bet '+ currMin);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
else if(parseFloat(betAmt) > parseFloat(currMax)){
$('#bet_msg_'+mid+sid).show().html('Maximum bet '+ currMax);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}

setTimeout(function(){
if(bet_type === 'back'){
let currRateBack = $('#price_back_'+mid+sid+tno).text();
if(parseFloat(currRateBack) > parseFloat(betRate)){
//console.log('bet no allowed' + betRate +' -- '+ currRateBack);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
else if(bet_type === 'lay'){
let currRateLay = $('#price_lay_'+mid+sid+tno).text();
if(parseFloat(betRate) > parseFloat(currRateLay)){
//console.log('bet no allowed' + betRate +' -- '+ currRateLay);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
finalPlaceBet(mid,sid,betDelay);
},(betDelay * 1000));
}

async function finalPlaceBet(marketId,sid,betDelay){
var currRate = 0;
var eid = $('#eventid_'+marketId+sid).text();
var mid = $('#marketid_'+marketId+sid).text();
var eventname = $('#eventname').text().trim();
var team = $('#teamSel_'+marketId+sid).text().trim();
var bet_type = $('#selBetType_'+marketId+sid).text().trim();
var tno = $('#tno_'+marketId+sid).text();
betAmt = $('#bet-amount_'+marketId+sid).val();
if(bet_type === 'back'){
currRate = $('#price_back_'+marketId+sid+tno).text();
}
else{
currRate = $('#price_lay_'+marketId+sid+tno).text();   
}
var sec = $('#sec_'+marketId+sid).text();
var gtype = $('#gtype_'+marketId+sid).text();
var sport = $('#sport_'+marketId+sid).text();

//console.log(eid +' -- '+ mid +' -- '+ eventname +' -- '+ team +' -- '+ bet_type +' -- '+ tno +' -- '+ betAmt +' -- '+ currRate +' -- '+ sec +' -- '+ sport);

if(currRate > 0 || eid !== '' || mid !== '' || eventname !== ''){
try {
const sendData = await axios.post('/server/cricket-matchOdds-saveBet/', {
eid: eid,
mid: mid,
eventname: eventname,
team: team,
bet_type: bet_type,
betAmt: betAmt,
betRate: currRate,
sec: sec,
gtype: gtype,
sport: sport,
tno: tno,
betDelay: betDelay
});
const resData = await sendData.data;
//console.log(resData);
if(resData === 'success'){
setExposure();
getAllBetsMarket(eid,mid);
matchOdds_pls(eid,sport);
$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);    
}
else{
$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);    
}
} catch (error) {
console.log('front soccer-matchodds-savebet error: ' + error);
}
}else{
$('#bet_msg_'+marketId+sid).show().html('Bet no placed');
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);  
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);
}

async function matchOdds_pls(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
if(matchOddsRunner.length > 0){

//console.log(response);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

allSec.forEach((ex) => {
//console.log(ex);
const findTeam = matchOddsRunner.filter((e) => e.sec.trim() === ex.trim());
//console.log(findTeam);
if(findTeam.length > 0){
//console.log(findTeam[0].rc);
//var currSec = '';
var t1Prf = 0;
var t2Prf = 0;
var t3Prf = 0;
var t4Prf = 0;
var t5Prf = 0;
var t6Prf = 0;
var t7Prf = 0;
var t8Prf = 0;
var t9Prf = 0;
var t10Prf = 0;
var t11Prf = 0;
var t12Prf = 0;
var t13Prf = 0;
var t14Prf = 0;
var t15Prf = 0;
var t16Prf = 0;
var t17Prf = 0;
var t18Prf = 0;
var t19Prf = 0;


response.filter((e) => e.sec.trim() === ex.trim()).forEach((exx) => {
//console.log(exx.sec);
var runner1 = null;
var runner2 = null;
var runner3 = null;
var runner4 = null;
var runner5 = null;
var runner6 = null;
var runner7 = null;
var runner8 = null;
var runner9 = null;
var runner10 = null;
var runner11 = null;
var runner12 = null;
var runner13 = null;
var runner14 = null;
var runner15 = null;
var runner16 = null;
var runner17 = null;
var runner18 = null;
var runner19 = null;

if(findTeam[0].rc === 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
}
else if(findTeam[0].rc === 2){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
}
else if(findTeam[0].rc > 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
runner4 = (findTeam[3] !== undefined ? findTeam[3].runner : null);
runner5 = (findTeam[4] !== undefined ? findTeam[4].runner : null);
runner6 = (findTeam[5] !== undefined ? findTeam[5].runner : null);
runner7 = (findTeam[6] !== undefined ? findTeam[6].runner : null);
runner8 = (findTeam[7] !== undefined ? findTeam[7].runner : null);
runner9 = (findTeam[8] !== undefined ? findTeam[8].runner : null);
runner10 = (findTeam[9] !== undefined ? findTeam[9].runner : null);
runner11 = (findTeam[10] !== undefined ? findTeam[10].runner : null);
runner12 = (findTeam[11] !== undefined ? findTeam[11].runner : null);
runner13 = (findTeam[12] !== undefined ? findTeam[12].runner : null);
runner14 = (findTeam[13] !== undefined ? findTeam[13].runner : null);
runner15 = (findTeam[14] !== undefined ? findTeam[14].runner : null);
runner16 = (findTeam[15] !== undefined ? findTeam[15].runner : null);
runner17 = (findTeam[16] !== undefined ? findTeam[16].runner : null);
runner18 = (findTeam[17] !== undefined ? findTeam[17].runner : null);
runner19 = (findTeam[18] !== undefined ? findTeam[18].runner : null);
}

//console.log(exx.sec +' -- '+ runner1 +' --'+ runner2 +' -- '+ runner3 +' -- '+ runner4 
//+' --'+ runner5 +' --'+ runner6 +' -- '+ runner7 +' -- '+ runner8 +' -- '+ runner9);

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'back'){
//currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
//console.log(runner4);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
}

if(exx.team.trim() === (runner4 !== null ? runner4.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner5 !== null ? runner5.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner6 !== null ? runner6.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner7 !== null ? runner7.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner8 !== null ? runner8.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner9 !== null ? runner9.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner10 !== null ? runner10.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner12 !== null ? runner12.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner13 !== null ? runner13.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner14 !== null ? runner14.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner15 !== null ? runner15.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner16 !== null ? runner16.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner17 !== null ? runner17.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner18 !== null ? runner18.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}

if(exx.team.trim() === (runner19 !== null ? runner19.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
if(runner4 !== null){
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}
}

if(exx.team.trim() === (runner4 !== null ? runner4.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) - parseFloat(exx.loss);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner5 !== null ? runner5.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) - parseFloat(exx.loss);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner6 !== null ? runner6.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) - parseFloat(exx.loss);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit);
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit);  
}

if(exx.team.trim() === (runner7 !== null ? runner7.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) - parseFloat(exx.loss);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner8 !== null ? runner8.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) - parseFloat(exx.loss);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner9 !== null ? runner9.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) - parseFloat(exx.loss); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner10 !== null ? runner10.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) - parseFloat(exx.loss); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner11 !== null ? runner11.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) - parseFloat(exx.loss); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner12 !== null ? runner12.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) - parseFloat(exx.loss); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner13 !== null ? runner13.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) - parseFloat(exx.loss); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner14 !== null ? runner14.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) - parseFloat(exx.loss); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner15 !== null ? runner15.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) - parseFloat(exx.loss); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner16 !== null ? runner16.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) - parseFloat(exx.loss); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner17 !== null ? runner17.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) - parseFloat(exx.loss); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner18 !== null ? runner18.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) - parseFloat(exx.loss); 
t19Prf = parseFloat(t19Prf) + parseFloat(exx.profit); 
}

if(exx.team.trim() === (runner19 !== null ? runner19.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
t4Prf = parseFloat(t4Prf) + parseFloat(exx.profit);
t5Prf = parseFloat(t5Prf) + parseFloat(exx.profit);
t6Prf = parseFloat(t6Prf) + parseFloat(exx.profit);
t7Prf = parseFloat(t7Prf) + parseFloat(exx.profit);
t8Prf = parseFloat(t8Prf) + parseFloat(exx.profit);
t9Prf = parseFloat(t9Prf) + parseFloat(exx.profit); 
t10Prf = parseFloat(t10Prf) + parseFloat(exx.profit); 
t11Prf = parseFloat(t11Prf) + parseFloat(exx.profit); 
t12Prf = parseFloat(t12Prf) + parseFloat(exx.profit); 
t13Prf = parseFloat(t13Prf) + parseFloat(exx.profit); 
t14Prf = parseFloat(t14Prf) + parseFloat(exx.profit); 
t15Prf = parseFloat(t15Prf) + parseFloat(exx.profit); 
t16Prf = parseFloat(t16Prf) + parseFloat(exx.profit); 
t17Prf = parseFloat(t17Prf) + parseFloat(exx.profit); 
t18Prf = parseFloat(t18Prf) + parseFloat(exx.profit); 
t19Prf = parseFloat(t19Prf) - parseFloat(exx.loss); 
}
    
});

//console.log(currSec +' -- '+ t1Prf +' -- '+ t2Prf +' -- '+ t3Prf +' -- '+ t4Prf +' -- '+ t5Prf 
//+' -- '+ t6Prf +' -- '+ t7Prf +' -- '+ t8Prf +' -- '+ t9Prf);

if(findTeam[0] !== undefined){
if(t1Prf > 0){
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[1] !== undefined){
if(t2Prf > 0){
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[2] !== undefined){
if(t3Prf > 0){
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(parseFloat(t3Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(parseFloat(t3Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[3] !== undefined){
if(t4Prf > 0){
$('#teamPrf_'+findTeam[3].mid+findTeam[3].sid).html(parseFloat(t4Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[3].mid+findTeam[3].sid).html(parseFloat(t4Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[4] !== undefined){
if(t5Prf > 0){
$('#teamPrf_'+findTeam[4].mid+findTeam[4].sid).html(parseFloat(t5Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[4].mid+findTeam[4].sid).html(parseFloat(t5Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[5] !== undefined){
if(t6Prf > 0){
$('#teamPrf_'+findTeam[5].mid+findTeam[5].sid).html(parseFloat(t6Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[5].mid+findTeam[5].sid).html(parseFloat(t6Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[6] !== undefined){
if(t7Prf > 0){
$('#teamPrf_'+findTeam[6].mid+findTeam[6].sid).html(parseFloat(t7Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[6].mid+findTeam[6].sid).html(parseFloat(t7Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[7] !== undefined){
if(t8Prf > 0){
$('#teamPrf_'+findTeam[7].mid+findTeam[7].sid).html(parseFloat(t8Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[7].mid+findTeam[7].sid).html(parseFloat(t8Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[8] !== undefined){
if(t9Prf > 0){
$('#teamPrf_'+findTeam[8].mid+findTeam[8].sid).html(parseFloat(t9Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[8].mid+findTeam[8].sid).html(parseFloat(t9Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[9] !== undefined){
if(t10Prf > 0){
$('#teamPrf_'+findTeam[9].mid+findTeam[9].sid).html(parseFloat(t10Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[9].mid+findTeam[9].sid).html(parseFloat(t10Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[10] !== undefined){
if(t11Prf > 0){
$('#teamPrf_'+findTeam[10].mid+findTeam[10].sid).html(parseFloat(t11Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[10].mid+findTeam[10].sid).html(parseFloat(t11Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[11] !== undefined){
if(t12Prf > 0){
$('#teamPrf_'+findTeam[11].mid+findTeam[11].sid).html(parseFloat(t12Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[11].mid+findTeam[11].sid).html(parseFloat(t12Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[12] !== undefined){
if(t13Prf > 0){
$('#teamPrf_'+findTeam[12].mid+findTeam[12].sid).html(parseFloat(t13Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[12].mid+findTeam[12].sid).html(parseFloat(t13Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[13] !== undefined){
if(t14Prf > 0){
$('#teamPrf_'+findTeam[13].mid+findTeam[13].sid).html(parseFloat(t14Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[13].mid+findTeam[13].sid).html(parseFloat(t14Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[14] !== undefined){
if(t15Prf > 0){
$('#teamPrf_'+findTeam[14].mid+findTeam[14].sid).html(parseFloat(t15Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[14].mid+findTeam[14].sid).html(parseFloat(t15Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[15] !== undefined){
if(t16Prf > 0){
$('#teamPrf_'+findTeam[15].mid+findTeam[15].sid).html(parseFloat(t16Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[15].mid+findTeam[15].sid).html(parseFloat(t16Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[16] !== undefined){
if(t17Prf > 0){
$('#teamPrf_'+findTeam[16].mid+findTeam[16].sid).html(parseFloat(t17Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[16].mid+findTeam[16].sid).html(parseFloat(t17Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[17] !== undefined){
if(t18Prf > 0){
$('#teamPrf_'+findTeam[17].mid+findTeam[17].sid).html(parseFloat(t18Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[17].mid+findTeam[17].sid).html(parseFloat(t18Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[18] !== undefined){
if(t19Prf > 0){
$('#teamPrf_'+findTeam[18].mid+findTeam[18].sid).html(parseFloat(t19Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[18].mid+findTeam[18].sid).html(parseFloat(t19Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

}
});
}
}
} catch (error) {
console.log('frontend soccer matchOdds pls bets api : ' + error);
}
}

export {
oddsClick,
allSid,
cancelBet,
minusRate,
plusRate,
minusAmt,
plusAmt,
stakeBet,
changeAmt,
betSend,
matchOddsRunner,
clearId,
matchOdds_pls,
bettingAct
};