import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';
import { allSid } from './betting';

let betAmt = 0;
let betRate = 0;
var allMid = [];
var allTeamLine = [];
let sid = 0;
var bettingAct4 = false;

//console.log(allMid);

function clearLineId(){
allMid = [];
allTeamLine = [];   
}

function stakeBet(mid,amt){
//console.log(sid +' -- '+ amt);
$('#bet-amount_'+mid).val(amt);
$('#betsPlace_'+mid).addClass('active').removeAttr('disabled');
$('#betsPlace2_'+mid).addClass('active').removeAttr('disabled');
beforePls(mid);
}

function minusAmt(mid){
betAmt = $('#bet-amount_'+mid).val();
$('#bet-amount_'+mid).val((parseFloat(betAmt) / 2).toFixed());
beforePls(mid);
}

function plusAmt(mid){
betAmt = $('#bet-amount_'+mid).val();
$('#bet-amount_'+mid).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls(mid);
}

function changeAmt(mid,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_'+mid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid).removeClass('active').attr('disabled', 'disabled');
$('#bet-amount_'+mid).val(0);
}else{
$('#betsPlace_'+mid).addClass('active').removeAttr('disabled');   
$('#betsPlace2_'+mid).addClass('active').removeAttr('disabled');    
$('#bet-amount_'+mid).val(Math.round(val));
}
beforePls(mid);
}

function oddsClick(mid,sid,type,tno,userAct){
if(!userAct){
$('#AcceptAgeModal').show();
return false;
}
if(type === 'back'){
betRate = $('#price_back_'+mid+sid+tno).text();
}else{
betRate = $('#price_lay_'+mid+sid+tno).text();
}
$('#bet-rate_'+mid).val(betRate);
$('#currSid_'+mid).text(sid);
$('#bet-amount_'+mid).val(0);
$('#selBetType_'+mid).text(type);
$('#tno_'+mid).text(tno);
$('#betsPlace_'+mid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid).removeClass('active').attr('disabled', 'disabled');

if(type === 'back'){
$('#betRow_'+mid).css({backgroundColor: 'hsla(207, 100%, 26%, 1)', background: 'linear-gradient(180deg, hsla(207, 100%, 26%, 1) 0%, hsla(207, 80%, 61%, 1) 50%, hsla(207, 100%, 26%, 1) 100%)'});
$('#betColBet_'+mid).css({background: '#badcf7', borderTop: '2px solid #217ec4'});
}else{
$('#betRow_'+mid).css({backgroundColor: 'hsla(347, 98%, 68%, 1)', background: 'linear-gradient(180deg, hsla(347, 98%, 68%, 1) 0%, hsla(347, 79%, 85%, 1) 50%, hsla(347, 98%, 68%, 1) 100%)'});
$('#betColBet_'+mid).css({background: '#fee7ec', borderTop: '2px solid #f76e8c'});    
}

for(let i=0; i < allMid.length; i++){
if(mid === allMid[i]){
$('#placeBet_'+allMid[i]).show();
$('#pls2_'+allMid[i]).css({color: '#bd0000'}).text('('+0+')');
}   
else{
$('#placeBet_'+allMid[i]).hide();
$('#pls2_'+allMid[i]).css({color: '#bd0000'}).text('('+0+')');
} 
}
}

function beforePls(mid){
let rate = $('#bet-rate_'+mid).val();
const type = $('#selBetType_'+mid).text();
betAmt = $('#bet-amount_'+mid).val(); 
let a = 0;
a = parseFloat((rate * betAmt / 100).toFixed(2)).toLocaleString();
//console.log(rate +' -- '+ type +' -- '+ betAmt);
for(let i=0; i < allMid.length; i++){
if(type === 'back'){
if(mid === allMid[i]){
$('#pls2_'+allMid[i]).css({color: '#44B10A'}).show().text('('+a+')');
}
}
else if(type === 'lay'){
if(mid === allMid[i]){
$('#pls2_'+allMid[i]).css({color: '#bd0000'}).show().text('('+a+')');
}
}
}
}

function cancelBet(mid){
$('#placeBet_'+mid).hide();
$('#bet-rate_'+mid).val(0);
$('#bet-amount_'+mid).val(0);

for(let i=0; i < allMid.length; i++){
$('#pls2_'+allMid[i]).hide();   
}
}

function betSend(mid){
var bet_type = $('#selBetType_'+mid).text().trim();
var tno = $('#tno_'+mid).text();
betAmt = $('#bet-amount_'+mid).val();
betRate = $('#bet-rate_'+mid).val();
sid = $('#currSid_'+mid).text();
let currMin = 0;
let currMax = 0;
let betDelay = 1;
bettingAct4 = true;

$('#betMsgWrap_'+mid).css({display: 'grid'});
$('.oddsDiv').attr('disabled', 'disabled');

allSid.forEach((e) => {
if(e.sec === 'fancy'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}
});

if (parseFloat(betAmt) < parseFloat(currMin)){
$('#bet_msg_'+mid).show().html('Minimum bet '+ currMin);
setTimeout(function(){
$('#betMsgWrap_'+mid).css({display: 'none'});
$('#bet_msg_'+mid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(mid);
},1000);
return false;
}
else if(parseFloat(betAmt) > parseFloat(currMax)){
$('#bet_msg_'+mid).show().html('Maximum bet '+ currMax);
setTimeout(function(){
$('#betMsgWrap_'+mid).css({display: 'none'});
$('#bet_msg_'+mid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(mid);
},1000);
return false;
}

setTimeout(function(){
if(bet_type === 'back'){
let currRateBack = $('#price_back_'+mid+sid+tno).text();
if(parseFloat(currRateBack) > parseFloat(betRate)){
//console.log('bet no allowed' + betRate +' -- '+ currRateBack);
$('#bet_msg_'+mid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid).css({display: 'none'});
$('#bet_msg_'+mid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(mid);
},1000);
return false;
}
}
else if(bet_type === 'lay'){
let currRateLay = $('#price_lay_'+mid+sid+tno).text();
if(parseFloat(betRate) > parseFloat(currRateLay)){
//console.log('bet no allowed' + betRate +' -- '+ currRateLay);
$('#bet_msg_'+mid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid).css({display: 'none'});
$('#bet_msg_'+mid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(mid);
},1000);
return false;
}
}
finalPlaceBet(mid,sid,betDelay);
},(betDelay * 1000));
}

async function finalPlaceBet(marketId,sid,betDelay){
var currRate = 0;
var currSize = 0;
var eid = $('#eventid_'+marketId).text();
var mid = $('#marketid_'+marketId).text();
var eventname = $('#eventname').text().trim();
var team = $('#teamSel_'+marketId).text().trim();
var bet_type = $('#selBetType_'+marketId).text().trim();
var tno = $('#tno_'+marketId).text();
betAmt = $('#bet-amount_'+marketId).val();
if(bet_type === 'back'){
currRate = $('#price_back_'+marketId+sid+tno).text();
}
else{
currRate = $('#price_lay_'+marketId+sid+tno).text();   
}
var sec = $('#sec_'+marketId).text();
var sport = $('#sport_'+marketId).text();

// console.log(eid +' -- '+ mid +' -- '+ eventname +' -- '+ team +' -- '+ bet_type +' -- '+ tno +' -- '+ betAmt +' -- '+ currRate +' -- '+ sec +' -- '+ sport);

if(currRate > 0 || eid !== '' || mid !== '' || eventname !== ''){
try {
const sendData = await axios.post('/server/cricket-fancy-saveBet/', {
eid: eid,
mid: mid,
eventname: eventname,
team: team,
bet_type: bet_type,
betAmt: betAmt,
betRate: currRate,
betSize: currSize,
sec: sec,
sport: sport,
tno: tno,
betDelay: betDelay
});
const resData = await sendData.data;
if(resData === 'success'){
// console.log(resData);
setExposure();
getAllBetsMarket(eid,mid);

if(sec === 'line'){
lineFancy_pls(eid,sport);
}

$('#bet_msg_'+marketId).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId).css({display: 'none'});
$('#bet_msg_'+marketId).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(marketId);
},1000);    

}
else{
$('#bet_msg_'+marketId).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId).css({display: 'none'});
$('#bet_msg_'+marketId).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(marketId);
},1000);    
}
} catch (error) {
console.log('front cricket-matchodds-savebet error: ' + error);
}
}else{
$('#bet_msg_'+marketId).show().html('Bet no placed');
setTimeout(function(){
$('#betMsgWrap_'+marketId).css({display: 'none'});
$('#bet_msg_'+marketId).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct4 = false;
cancelBet(marketId);
},1000);  
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function lineFancy_pls(eventid,sport){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server/all-fancy-exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamLine.length > 0){
//console.log(allTeamLine);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'line').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamLine.length; p++){
if(filterData[i].team === allTeamLine[p].runner && allTeamLine[p].sec === 'line'){
$('#teamPrf_'+allTeamLine[p].mid).html(parseFloat(filterData[i].loss.toFixed(2)).toLocaleString()).css({color: 'rgb(189, 0, 0)'});
}
}
}
}
}
}
} catch(error){
console.log('front line fancy pls error : ' + error);
}
}

export {
stakeBet,
minusAmt,
plusAmt,
changeAmt, 
oddsClick, 
beforePls, 
allMid,
allTeamLine,
cancelBet,
betSend,
finalPlaceBet,
clearLineId,
lineFancy_pls,
bettingAct4
};