
import React from 'react';

const SoccerRules = () => {
return (
<>
<div className='toogle-Items soccerRules'>
<ul>
<li><span className='danger'>Bookmaker</span></li>
<li><span>If the match will not take place within 48 hours of the original kick-off time bets will be void.</span></li>
<li><span>If the selection is in a multiple bet or accumulator any refund must be requested before the kick-off of the first leg of the multiple bets.</span></li>
<li><span>Where a confirmed postponed match features as part of a multiple bet, the bet will stand on the remaining selections in the multiple.</span></li>
<li><span>Please note that games that have their kick-off altered well in advance to accommodate live TV, or to ease fixture congestion will not be classed as postponed.</span></li>
<li><span>If a match is forfeited or a team is given a walkover victory without the match having kicked off, then all bets will be void. Any subsequently awarded scoreline will not count for settlement purposes.</span></li>
<li><span className='danger'>Fancy</span></li>
<li><span>Tournament Total Goals, Team Total Goals FT: -. scored in 90 minutes or extra time will count.Goals scored in penalty shootouts do not count.</span></li>
<li><span>Tournament Corners - Only corners taken in 90 minutes count.</span></li>
<li><span>Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra time, and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span></li>
<li><span className='danger'>Match</span></li>
<li><span>Match Odds :- All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included. For the cancellation of a goal, due to VAR, bets matched between the time of the goal being scored and the time at which the video assistant referee finishes the review will be voided. For the cancellation of a red card, due to VAR, bets matched after the time at which the video assistant referee commences the review will be voided.</span></li>
<li><span>Under_Over Goals: - In the event of a match starting but not being completed, all bets will be void, unless the specific market outcome is already determined,</span></li>
<li><span>1st Period Winner: - Bets will be void if the match is abandoned before half-time.</span></li>
<li><span>Next Goal: - Own goals count to the side credited with the goal.</span></li>
<li><span>Draw No Bet: - Predict which team will be the winner. In case of a draw, all bets will be void. If a game is abandoned, bets will be void.</span></li>
<li><span>Both Teams to Score: - Predict whether both teams will score at least one goal in the game. Own goals count towards the team credited with the goal. If a game is abandoned, bets will be void, unless the outcome of these bets is already determined. Yes” – meaning that both teams will score. “No” – means that either team will not score.</span></li>
<li><span>Total Corners: - Predict which team will take the named corner in the game. If this specific corner is not taken in the game, bets will be void. For example, the game finishes or is abandoned with 8 corners taken – all bets on any corner after the 8th will be void (9th, 10th, etc.).</span></li>
<li><span>Goals Odd/Even: - Any match resulting in 0-0 will be settled on an even number of goals. For Team Odd/Even markets, if the specified team does not score then we will settle on an even number of goals. In the event of an abandoned match then bets for that match will be void.</span></li>
<li><span>1X2 Corners: - Predict which team will get more corners in a match. Awarded, but not taken corners (there is a corner, but before it is taken the referee signals for the end of the first half or the match) will not count for settlement purposes. Also, if a corner needs to be re-taken for any reason, it will be counted as 1 corner.</span></li>
<li><span>Under/Over Card in Match (Number of cards): - Predict the number of cards that will be shown in a match. If a player is sent off for 2 yellow cards, this counts as 2 yellow cards and 1 red card for betting purpose es. If the match is abandoned for any reason, all bets will be void unless the market is already determined, e.g., Team 1 over 2,5 yellow cards - 3 yellow cards awarded to Team 1 before abandonment is settled as a winner. Cards for non-players (already substituted players, managers, and players on the bench who are not substituted) are not considered. The settlement will be made concerning all available evidence to cards shown during the scheduled 90 minutes play. Any card shown after the full-time whistle-blow will be disregarded.</span></li>
<li><span>First Half Under/Over Goals: - How many goals will be scored in the first half of this match? All bets apply to Full Time according to the match officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.</span></li>
<li><span>Penalty Taken? : - Will a penalty be awarded and taken during this match? All bets apply to Full Time according to the match officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.</span></li>
<li><span>Correct Score: - Predict the score of this match. This market will not be partially settled during the fixture and will be fully settled full-time. All bets apply to Full Time according to the match officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.</span></li>
<li><span>Team A/B +1/2/3: - Who will win this match with the stated handicap applied? All bets apply to Full Time according to the match officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.</span></li>
<li><span>HT/FT: - Bets will be void if the match is abandoned. Extra-time and penalty shootouts do not count.</span></li>
</ul>
</div>
</>
)
}

export default SoccerRules;
