import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';
import AcceptAge from '../modals/AcceptAge';
import LoginModal from '../modals/LoginModal';
import MainBal from './MainBal';

const customerDataApi = '/server/active-customer';
const userExposure = '/server/user-exposure';

const Header = () => {

const { customer, GetUserList, GetUserExposure } = GlobalContext();
const Navigate = useNavigate();

const reloadBal = () => {
$('.mainBalWrap').hide(); 
$('.loadingDots_sm').css({display: 'flex'});
GetUserList(`${customerDataApi}`);
GetUserExposure(`${userExposure}`);
setTimeout(function(){
$('.mainBalWrap').show(); 
$('.loadingDots_sm').hide();
},1000);  
}

const loginShow = () => {
$('#loginForm').show();  
var pas = document.getElementById('clientPass');
$('#iconPassEye').addClass('fa-eye-slash').removeClass('fa-eye'); 
pas.type = 'password';
}

const logOut = async() => {
try {
const sendData = await axios.get('/server/logout');
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('logout successfull');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.replace('/');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
}
} catch (error) {
console.log('frontend logout-api error: ' + error);
}
}

const stakePage = () => {
Navigate('/stake-setting');
}

const changePass = () => {
Navigate('/change-password');    
}

const activityLog = () => {
Navigate('/activity-log');   
}

const betHistory = () => {
Navigate('/bet-history');    
}

const profitLoss = () => {
Navigate('/profit-loss');    
}

const statement = () => {
Navigate('/statement');
}

const demoLoginShow = () => {
$('#demoLoginModal').show();
}

return (
<>
<div className='app-header'>
<div className='container-med'>
<div className='rows'>
<div className='cols-two'>
<div className='logo-area'>
<div className='logo-text-wrap'>
{
/*
<img src='/images/flower.png' alt='logo-img' />
<span>24ExchangeBet</span>
*/
}
<Link to='/'>
<img src='/images/24exchLogo.png' alt='logo-img' />
</Link>
</div>
</div>
</div>
<div className='cols-two'>
{
customer.length > 0 ?
<div className='logData'>
<MainBal />
<button onClick={() => reloadBal()} className='reloadBal'><i className='fa fa-rotate-left'></i></button>
<div className='myAccDiv'>
<button><i className='fa fa-user'></i> <span>my account</span></button>
<div className='drop-div'>
<ul>
<div className='ul-head'><span><i className='fa fa-sign-in'></i> login: {customer[0].customer}</span></div>
<li onClick={() => stakePage()}><i className='fa fa-angle-double-right'></i> stake setting</li>
<li onClick={() => betHistory()}><i className='fa fa-angle-double-right'></i> bet history</li>
<li onClick={() => profitLoss()}><i className='fa fa-angle-double-right'></i> profit / loss</li>
<li onClick={() => statement()} ><i className='fa fa-angle-double-right'></i> statement</li>
<li onClick={() => activityLog()}><i className='fa fa-angle-double-right'></i> activity log</li>
<li onClick={() => changePass()}><i className='fa fa-angle-double-right'></i> change password</li>
<div className='ul-foot'><button onClick={() => logOut()}><i className='fa fa-sign-out'></i> log out</button></div>
</ul>
</div>
</div>
</div>
:
<div className='logData'> 
<div className='input-div-login'>
<button onClick={() => loginShow()}>Login</button>
<button onClick={() => demoLoginShow()}>Demo Id</button>
</div>  
</div>
}

</div>
</div>
</div>
</div>

<AcceptAge />
<LoginModal />
</>
)
}

export default Header;
