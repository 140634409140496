import React from 'react';

const FancyRules = () => {
return (
<>
<div className='toogle-Items fancyRules'>
<ul>
<li><span className='danger'>1. All fancy bets will be validated when match has been tied.</span></li>
<li><span className='danger'>2. All advance fancy will be suspended before toss or weather condition.</span></li>
<li><span className='danger'>3. In case technical error or any circumstances any fancy is suspended and does not resume result will be given all previous bets will be valid (based on haar/jeet).</span></li>
<li><span className='danger'>4. If any case wrong rate has been given in fancy that particular bets will be cancelled.</span></li>
<li><span className='danger'>5. In any circumstances management decision will be final related to all exchange items. Our scorecard will be considered as valid if there is any mismatch in online portal</span></li>
<li><span className='danger'>6. In case customer make bets in wrong fancy we are not liable to delete, no changes will be made and bets will be consider as confirm bet.</span></li>
<li><span className='danger'>7. Due to any technical error market is open and result has came all bets after result will be deleted.</span></li>
<li><span className='danger'>8. Manual bets are not accepted in our exchange</span></li>
<li><span className='danger'>9.Our exchange will provide 5 second delay in our tv.</span></li>
<li><span className='danger'>10. Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at same time (Punching) and others. Note : only winning bets will be voided, For example: If we find such entries (above mentioned) from any id and their bets are (200000 lay in a 6 over session for the rate 40 and 200000 back for the rate of 48) and the actual score is 38, bets of 40 lay will be voided and the bets for 48 back will be considered valid.</span></li>
<li><span className='danger'>11. Company reserves the right to void any bets (only winning bets) of any event at any point of the match if the company believes there is any cheating/wrong doing in that particular event by the players (either batsman/bowler)</span></li>
<li><span className='danger'>12. Once our exchange give username and password it is your responsibility to change a password.</span></li>
<li><span className='danger'>13. Penalty runs will not be counted in any fancy.</span></li>
<li><span className='danger'>14. Warning:- live scores and other data on this site is sourced from third party feeds and may be subject to time delays and/or be inaccurate. If you rely on this data to place bets, you do so at your own risk. Our exchange does not accept responsibility for loss suffered as a result of reliance on this data.</span></li>
<li><span className='danger'>15.Traders will be block the user ID if find any misinterpret activities, No queries accept regarding.</span></li>
<li><span className='danger'>16. Our exchange is not responsible for misuse of client id.</span></li>
<li><span className='danger'>Test</span></li>
<li><span className='danger'>1 Session:-</span></li>
<li><span>1.1 Complete session valid in test.</span></li>
<li><span>1.2 Middle session and Session is not completed due to Innings declared or all out so that particular over considered as completed and remaining over counted in next team Innings for ex:- In case of Innings declared or all out In 131.5th over Considered as 132 over completed remaining 1 over counted for 133 over middle session and 3 over counted for 135 over session from next team Innings and One over session and Only over session is not completed due to innings declared so that Particular over session bets will be deleted and all out considered as valid for ex:- In case of Innings declared In 131.5th over so 132 over will be deleted and if all out then 132 over and Only 132 over will be Valid.</span></li>
<li><span>1.3 1st day 1st session run minimum 25 over will be played then result is given otherwise 1st day 1st session will be deleted.</span></li>
<li><span>1.4 1st day 2nd session run minimum 25 over will be played then result is given otherwise 1st day 2nd session will be deleted.</span></li>
<li><span>1.5 1st day total run minimum 80 over will be played then result is given otherwise 1st day total run will be deleted.</span></li>
<li><span>1.6 Test match both advance session is valid.</span></li>
<li><span className='danger'>2 Test lambi/ Inning run:-</span></li>
<li><span>2.1 Mandatory 70 over played in test lambi paari/ Innings run. If any team all-out or declaration lambi paari/ innings run is valid.</span></li>
<li><span>2.2 In case due to weather situation match has been stopped all lambi trades will be deleted.</span></li>
<li><span>2.3 In test both lambi paari / inning run is valid in advance fancy.</span></li>
<li><span className='danger'>3 Test batsman:-</span></li>
<li><span>3.1 In case batsmen is injured he/she is made 34 runs the result will be given 34 runs.</span></li>
<li><span>3.2 Batsman 50/100 run if batsman is injured or declaration the result will be given on particular run.</span></li>
<li><span>3.3 In next men out fancy if player is injured particular fancy will be deleted.</span></li>
<li><span>3.4 In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.</span></li>
<li><span>3.5 Test match both advance fancy batsmen run is valid.</span></li>
<li><span className='danger'>4 Test partnership:-</span></li>
<li><span>4.1 In partnership one batsman is injured or Retired out means partnership will continued in next batsman.</span></li>
<li><span>4.2 Partnership and player runs due to weather condition or match abandoned the result will be given as per score.</span></li>
<li><span>4.3 Advance partnership is valid in case both players are different or same.</span></li>
<li><span>4.4 Test match both advance fancy partnership is valid.</span></li>
<li><span className='danger'>5 Other fancy advance (test):-</span></li>
<li><span>5.1 Four, sixes, wide, wicket, extra run, total run, highest over and top batsmen is valid only if 300 overs has been played or the match has been won by any team otherwise all these fancy will be deleted. Additionally all events are valid only for 1st innings( this is applicable to all individual team events also)</span></li>
<li><span className='danger'>2 Odi rule:-</span></li>
<li><span className='danger'>Session:-</span></li>
<li><span>Match 1st over run advance fancy only 1st innings run will be counted.</span></li>
<li><span>Complete session is valid in case due to rain or match abandoned particular session will be deleted.</span></li>
<li><span>For example:- 35 over run team a is playing any case team A is all-out in 33 over team a has made 150 run the session result is validated on particular run.</span></li>
<li><span>Advance session is valid in only 1st innings.</span></li>
<li><span className='danger'>50 over runs:-</span></li>
<li><span>In case 50 over is not completed all bet will be deleted due to weather or any condition.</span></li>
<li><span>Advance 50 over runs is valid in only 1st innings.</span></li>
<li><span className='danger'>Odi batsman runs:-</span></li>
<li><span>In case batsman is injured he/she is made 34 runs the result will be given 34 runs.</span></li>
<li><span>In next men out fancy if player is injured particular fancy will be deleted.</span></li>
<li><span>In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.</span></li>
<li><span className='danger'>Odi partnership runs:-</span></li>
<li><span>In partnership one batsman is injured or Retired out means partnership will continued in next batsman.</span></li>
<li><span>Advance partnership is valid in case both players are different or same.</span></li>
<li><span>Both team advance partnerships are valid in particular match.</span></li>
<li><span className='danger'>Other fancy:-</span></li>
<li><span>Four, sixes, wide, wicket, extra run, total run, highest over ,top batsman,maiden over,caught-out,no-ball,run-out,fifty and century are valid only match has been completed in case due to rain over has been reduced all other fancy will be deleted.</span></li>
<li><span className='danger'>T20:-</span></li>
<li><span className='danger'>Session:-</span></li>
<li><span>Match 1st over run advance fancy only 1st innings run will be counted.</span></li>
<li><span>Complete session is valid in case due to rain or match abandoned particular session will be deleted.</span></li>
<li><span>For example :- 15 over run team a is playing any case team a is all-out in 13 over team A has made 100 run the session result is validated on particular run.</span></li>
<li><span>Advance session is valid in only 1st innings.</span></li>
<li><span className='danger'>20 over runs:-</span></li>
<li><span>Advance 20 over run is valid only in 1st innings. 20 over run will not be considered as valid if 20 overs is not completed due to any situation</span></li>
<li><span className='danger'>T20 batsman runs:-</span></li>
<li><span>In case batsman is injured he/she is made 34 runs the result will be given 34 runs.</span></li>
<li><span>In next men out fancy if player is injured particular fancy will be deleted.</span></li>
<li><span>In advance fancy opening batsmen is only valid if same batsmen came in opening the fancy will be valid in case one batsmen is changed that particular player fancy will be deleted.</span></li>
<li><span className='danger'>T20 partnership runs:-</span></li>
<li><span>In partnership one batsman is injured or Retired out means partnership will continued in next batsman.</span></li>
<li><span>Advance partnership is valid in case both players are different or same.</span></li>
<li><span>Both team advance partnerships are valid in particular match.</span></li>
<li><span className='danger'>1st 2 & 3 Wickets runs:- T20/ODI</span></li>
<li><span>Advance event is valid in only 1st Innings.</span></li>
<li><span>If over reduced due to rain or weather condition or match abandoned the result will be given as per score.</span></li>
<li><span className='danger'>Other fancy:-</span></li>
<li><span>T-20 ,one day and test match in case current innings player and partnership are running in between match has been called off or abandoned that situation all current player and partnership results are valid.</span></li>
<li><span>Four, sixes, wide, wicket, extra run, total run, highest over and top batsman,maiden over,caught-out,no-ball,run-out,fifty and century are valid only match has been completed in case due to rain over has been reduced all other fancy will be deleted. 1st 6 over dot ball and 20 over dot ball fancy only valid is 1st innings.</span></li>
<li><span>1st wicket lost to any team balls meaning that any team 1st wicket fall down in how many balls that particular fancy at least minimum one ball have to be played otherwise bets will be deleted.</span></li>
<li><span>1st wicket lost to any team fancy valid both innings.</span></li>
<li><span>How many balls for 50 runs any team meaning that any team achieved 50 runs in how many balls that particular fancy at least one ball have to be played otherwise that fancy bets will be deleted.</span></li>
<li><span>How many balls for 50 runs fancy any team only first inning valid.</span></li>
<li><span>1st 6 inning boundaries runs any team fancy will be counting only according to run scored fours and sixes at least 6 over must be played otherwise that fancy will be deleted.</span></li>
<li><span>1st inning 6 over boundaries runs any team run like wide ,no-ball ,leg-byes ,byes and over throw runs are not counted this fancy.</span></li>
<li><span>How many balls face any batsman meaning that any batsman how many balls he/she played that particular fancy at least one ball have to be played otherwise that fancy bets will be deleted.</span></li>
<li><span>How many balls face by any batsman both innings valid.</span></li>
<li><span>Lowest scoring over will be considered valid only if the over is completed fully (all six deliveries has to be bowled)</span></li>
<li><span>Total Match 30s : If a Player reached 50 means, Not considered in this Event</span></li>
<li><span>Total Boundaries in 1st Power play : Number of Boundaries Scored in 1st Power play, 1st Innings only Valid In T20/ODI Both</span></li>
<li><span>Total Dot balls in 1st Power play : Number of Dot balls coming in 1st Power play, 1st Innings only Valid In T20/ODI Both</span></li>
<li><span>Total match Wicket keeper's Dismissals: Wicket keepers Caught outs and Stumping Only Considered In T20/ODI Both</span></li>
<li><span>1st Inn Death Over Runs : Runs Scored, Last Over Only Considered, 1st Innings only Valid</span></li>
<li><span>Total Match Single Digit Scores By Players : Duck outs Not Considered in this Event. If Not out Batsman/Injured Batsman facing One Legal Delivery and nothing scored ('0') means Considered as Single Digit</span></li>
<li><span>Match 1st Over Dot ball : Number of Dot balls in Match 1st Over. 1st Innings Only Valid</span></li>
<li><span>Most Balls Faced By a Batsman : Maximum Balls Faced by an Individual Batsman in Match</span></li>
<li><span>High Pship Boundaries in the Match : Maximum Number of Boundaries Scored during any Partnershi</span></li>
<li><span className='danger'>Concussion in Test:-</span></li>
<li><span>All bets of one over session will be deleted in test scenario, in case session is incomplete. For example innings declared or match suspended to bad light or any other conditions.</span></li>
<li><span>1. All bets will be considered as valid if a player has been replaced under concussion substitute, result will be given for the runs scored by the mentioned player. For example DM Bravo gets retired hurt at 23 runs, then result will be given for 23.</span></li>
<li><span>2. Bets of both the player will be valid under concussion substitute.</span></li>
<li><span className='danger'>Total Match- Events (test):-</span></li>
<li><span>Minimum of 300 overs to be bowled in the entire test match, otherwise all bets related to the particular event will get void. For example, Total match caught outs will be valid only if 300 overs been bowled in the particular test match</span></li>
<li><span className='danger'>Limited over events-Test:-</span></li>
<li><span>This event will be considered valid only if the number of overs defined on the particular event has been bowled, otherwise all bets related to this event will get void. For example 0-25 over events will be valid only if 25 overs has been bowled, else the same will not be considered as valid</span></li>
<li><span>If the team gets all out prior to any of the defined overs, then balance overs will be counted in next innings. For example if the team gets all out in 23.1 over the same will be considered as 24 overs and the balance overs will be counted from next innings.</span></li>
<li><span className='danger'>Bowler Wicket event's- Test:-</span></li>
<li><span>Minimum of one legal over (one complete over) has to be bowled by the bowler mentioned in the event, else the same will not be considered as valid</span></li>
<li><span className='danger'>Bowler over events- Test:-</span></li>
<li><span>The mentioned bowler has to complete the defined number of overs, else the bets related to that particular event will get void. For example if the mentioned bowler has bowled 8 overs, then 5 over run of that particular bowler will be considered as valid and the 10 over run will get void</span></li>
<li><span className='danger'>Player ball event's- Test:-</span></li>
<li><span>This event will be considered valid only if the defined number of runs made by the mentioned player, else the result will be considered as 0 (zero) balls</span></li>
<li><span>For example if Root makes 20 runs in 60 balls and gets out on 22 runs, result for 20 runs will be 60 balls and the result for balls required for 25 run Root will be considered as 0 (Zero) and the same will be given as result</span></li>
<li><span className='danger'>Limited over events-ODI:-</span></li>
<li><span>This event will be considered valid only if the number of overs defined on the particular event has been bowled, otherwise all bets related to this event will get void. 0-50 over events will be valid only if 50 over completed, if the team batting first get all out prior to 50 over the balance over will be counted from second innings. For example if team batting first gets all out in 35 over balance 15 over will be counted from second innings, the same applies for all events if team gets all out before the defined number of overs</span></li>
<li><span>The events which remains incomplete will be voided if over gets reduced in the match due to any situation, for example if match interrupted in 15 overs due to rain/badlight and post this over gets reduced. Events for 0-10 will be valid, all other events related to this type will get deleted.</span></li>
<li><span>This events will be valid only if the defined number of over is completed. For example team batting first gets all out in 29.4 over then the same will be considered as 30 over, the team batting second must complete 20 overs only then 0-50 over events will be considered as valid. In case team batting second gets all out in 19.4 over then 0-50 over event will not be considered as valid, This same is valid for 1st Innings only.</span></li>
<li><span className='danger'>Bowler event- ODI:-</span></li>
<li><span>The mentioned bowler has to complete the defined number of overs, else the bets related to that particular event will get void. For example if the mentioned bowler has bowled 8 overs, then 5 over run of that particular bowler will be considered as valid and the 10 over run will get void</span></li>
<li><span>Both innings are valid</span></li>
<li><span className='danger'>Other event:- T20</span></li>
<li><span>The events for 1-10 over and 11-20 over will be considered valid only if the number of over mentioned has been played completely. However if the over got reduced before the particular event then the same will be voided, if the team batting first get all out prior to 20 over the balance over will be counted from second innings. For example if team batting first gets all out in 17 over balance 3 over will be counted from second innings and that 3 over all events are counted. This same is valid for 1st Innings only.</span></li>
<li><span>If over got reduced in between any running event, then the same will be considered valid and the rest will be voided. For example.., match started and due to rain/bad light or any other situation match got interrupted at 4 over and later over got reduced. Then events for 1-10 is valid rest all will be voided</span></li>
<li><span>Bowler Session: Bowler session advance events only valid for 1st inning. This event is valid only if the bowler has completed his maximum quota of overs, else the same will be voided. However if the match has resulted and the particular bowler has already started bowling his final over then result will be given even if he haven't completed the over. For example B Kumar is bowling his final over and at 3.4 the match has resulted then result will be given for B Kumar over runs</span></li>
<li><span>Incase of DLS, the over got reduced then the bowler who has already bowled his maximum quota of over that result will be considered as valid and the rest will be voided</span></li>
<li><span className='danger'>Dot ball Event:</span></li>
<li><span>Only No run will count as dot ball.</span></li>
<li><span>If wicket means that will not count as dot ball.</span></li>
<li><span className='danger'>Most Dot balls By a Bowler Event:</span></li>
<li><span>Leg bye run and wickets considered as Dot ball in bowler</span></li>
<li><span className='danger'>Boundary on Match 1st Free hit</span></li>
<li><span>Both innings are valid</span></li>
<li><span>Boundary hit on Free hit only be considered as valid</span></li>
<li><span>Bets will be deleted if there is no Free hit in the mentioned match</span></li>
<li><span>Boundary by bat will be considered as valid</span></li>
<li><span className='danger'>Boundaries by Player</span></li>
<li><span>Both Four and six are valid</span></li>
<li><span className='danger'>No Boundaries Event:</span></li>
<li><span>Both Four and Six are valid</span></li>
<li><span>Batsman bat boundaries only considered as valid</span></li>
<li><span>Free hit boundaries also valid</span></li>
<li><span>Bets will be voided if that particular ball not completed</span></li>
<li><span>Result will be Given 0 or 4 (No or Yes). For Example batsman hit boundary in particular ball means Result is 0 otherwise Result is 4.</span></li>
<li><span className='danger'>Bowler Session:</span></li>
<li><span>The mentioned bowler has to complete the defined number of overs, else the bets related to that particular event will get void. For example if the mentioned bowler has bowled 8 overs, then 5 over run of that particular bowler will be considered as valid and the 10 over run will get void</span></li>
<li><span>Wide & No ball runs will be counted in bowler Session.</span></li>
<li><span>Byes & Leg byes runs will not be counted in bowler Session.</span></li>
<li><span className='danger'>Inning Run Bhav Event :</span></li>
<li><span>Inning run bhav bets are valid if over reduced due to rain or weather condition or match abandoned the result will be given as per official result.</span></li>
<li><span>Settlement occurs once the stipulated conditions are met, which involves either completion of the allotted overs or the batting team's dismissal, including weather disturbances.</span></li>
<li><span>In the event of a weather-shortened match, all Bhav Bets placed in the Inning Run Bhav market will be settled according to the official result. For limited overs matches, this includes results determined by the Duckworth Lewis method.</span></li>
<li><span>In case of pitch vandalism, player safety concerns, stadium damage, acts of terrorism, or acts of God, the company holds the authority to nullify all bets, with the exception of those related to markets that have already been conclusively settled.</span></li>
<li><span>Bets made during instances of incorrect scorecard updates, inaccurate commentary, delays in suspending the Bhav Bets of Total Innings Runs market, or erroneous updates of rates and odds for Bhav Bets in Total Innings Runs will be removed and deleted from user accounts.</span></li>
<li><span>Ex: 1st inning run bhav(ENG v AUS),2nd Inning run bhav(ENG v AUS) - England vs Australia T20 Match</span></li>
<li><span className='danger'>Power Surge Rule in Big Bash</span></li>
<li><span>Power Play First Four Overs + Power Surge Two Overs-Batters Choice</span></li>
<li><span>The batting side chooses when to take control with the addition of the Power Surge.</span></li>
<li><span>There’s still a four-over power play at the start of the innings, but now the batting team can take the other two Power Surge overs any time from the 11th over onwards.</span></li>
<li><span className='danger'>Any query regarding result or rate has to be contacted within 7 days from the event, query after 7 days from the event will not be considered as valid</span></li>
<li><span className='danger'>Virtual Cricket</span></li>
<li><span>Scorecard available on the video will be considered as valid. At any situation, if there is a difference between the scorecard in the website and the scorecard available on video. Score card available on video will be valid</span></li>
<li><span>In case of any technical issues the video gets interrupted/stopped and the same cannot be continued, the existing markets will be voided. However the markets which are already finished/settled will remain valid.</span></li>
<li><span className='danger'>CPL:-</span></li>
<li><span>If CPL fixture 0f 33 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this)</span></li>
<li><span>Fancy based on all individual teams are valid only for league stage</span></li>
<li><span>Total 1st over runs: Average 6 runs will be given in case match abandoned or over reduced</span></li>
<li><span>Total fours: Average 22 fours will be given in case match abandoned or over reduced</span></li>
<li><span>Total sixes: Average 13 sixes will be given in case match abandoned or over reduced</span></li>
<li><span>Total Wickets - Average will 13 Wickets be given in case match abandoned or over reduced</span></li>
<li><span>Total Wides - Average 10 wides will be given in case match abandoned or over reduced</span></li>
<li><span>Total Extras - Average 18 extras will be given in case match abandoned or over reduced</span></li>
<li><span>Total No ball - Average 1 no ball will be given in case match abandoned or over reduced</span></li>
<li><span>Total Fifties - Average 1 fifties will be given in case match abandoned or over reduced</span></li>
<li><span>Total Caught outs: Average 9 caught out will be given in case match abandoned or over reduced</span></li>
<li><span>At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid</span></li>
<li><span>Management decision will be final</span></li>
<li><span>Highest innings run - Only first innings is valid</span></li>
<li><span>Lowest innings run - Only first innings is valid</span></li>
<li><span>Highest over run: Both innings are valid</span></li>
<li><span>Highest 1st over run in individual match: Both innings are valid, however for CPL we have created the fancy for 1st innings only</span></li>
<li><span>Highest Fours in individual match: Both innings are valid</span></li>
<li><span>Highest Sixes in individual match: Both innings are valid</span></li>
<li><span>Highest Extras in individual match: Both innings are valid</span></li>
<li><span>Highest Wicket in individual match: Both innings are valid</span></li>
<li><span>Super over will not be included</span></li>
<li><span className='danger'>Barbados Tridents</span></li>
<li><span>Opening partnership run: Average 24 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 45 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>St Kitts and Nevis Patriots</span></li>
<li><span>Opening partnership run: Average 25 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 45 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>Trinbago Knight Riders</span></li>
<li><span>Opening partnership run: Average 22 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 46 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>Guyana Amazon Warriors</span></li>
<li><span>Opening partnership run: Average 23 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 44 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>St Lucia Zouks</span></li>
<li><span>Opening partnership run: Average 22 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 43 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>Jamaica Tallawahs</span></li>
<li><span>Opening partnership run: Average 24 runs will be given in case match abandoned or over reduced</span></li>
<li><span>First 6 over run: Average 46 run will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>Tour Special Events</span></li>
<li><span className='danger'>Australia tour of Sri Lanka, 2022</span></li>
<li><span className='danger'>If first match of tour (T20 or ODI) cancelled or over reduce in first match, then all special fancy events will be deleted</span></li>
<li><span className='danger'>If First match played completely and next match gets over reduced or cancelled, then that match bets all bets will be deleted and first match bets will be valid and average will count in other bets</span></li>
<li><span className='danger'>Ex. : First match total 4's is 20 and second match over reduced so bets after first match upto second match started that all deleted and for other bets average counted total 4's is 24 and third match total 4's is 26, So Result of Total 4's is 70</span></li>
<li><span className='danger'>T20 :</span></li>
<li><span>Total Matches 1st over runs : Average 6 runs will be given in case match abandoned or over reduced (only 1st innings valid)</span></li>
<li><span>Total Matches 1st 6 over runs : Average 45 runs will be given in case match abandoned or over reduced (Only 1st Innings valid)</span></li>
<li><span>Total fours: Average 24 fours will be given in case match abandoned or over reduced</span></li>
<li><span>Total sixes: Average 9 sixes will be given in case match abandoned or over reduced</span></li>
<li><span>Total Boundaries: Average 33 Boundaries will be given in case match abandoned or over reduced</span></li>
<li><span>Total Fifties - Average 2 Fifties will be given in case match abandoned or over reduced</span></li>
<li><span>Total Wickets - Average 12 Wickets will be given in case match abandoned or over reduced</span></li>
<li><span>Total Wides - Average 8 Wides will be given in case match abandoned or over reduced</span></li>
<li><span>Total Extras - Average 15 Extras will be given in case match abandoned or over reduced</span></li>
<li><span>Total Caught outs: Average 8 Caught out will be given in case match abandoned or over reduced</span></li>
<li><span>Total Bowled:- Average 2 Bowled out will be given in case match abandoned or over reduced</span></li>
<li><span>Total LBW:- Average 2 LBW will be given in case match abandoned or over reduced</span></li>
<li><span>Total Run out:- Average 1 Run out will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid</span></li>
<li><span>Most runs given by Bowler in an Inning of Tour : Maximum How much Runs conceded by a individual Bowler in an Innings. Ex : For T20I How much runs conceded by a bowler in his 4 overs Quota.</span></li>
<li><span>Highest total runs in individual match of IPL : Maximum Total runs of both Teams in individual match.</span></li>
<li><span>Highest innings run - Only first inning is valid</span></li>
<li><span>Highest Partnership - Both innings are valid</span></li>
<li><span>Highest 1st over run of individual match: only first inning is valid</span></li>
<li><span>Highest Fours of individual match: Both innings are valid</span></li>
<li><span>Highest Sixes of individual match: Both innings are valid</span></li>
<li><span>Highest Extras of individual match: Both innings are valid</span></li>
<li><span>Highest over run: Both innings are valid</span></li>
<li><span>Top Batsman Runs in Tour : Most runs by an Individual Player in any Individual matches in ODI/T20I Format of Tour</span></li>
<li><span>Highest Wickets by Bowler in Tour : Most Wickets taken by Individual Player in Overall ODI/T20I Format of Tour</span></li>
<li><span>Highest Over runs in Tour : Most Runs Scored in Any Single Over of any Individual Match in Overall ODI/T20I Format of Tour</span></li>
<li><span>Player Total Runs : Total Runs Scored by an Individual Player in Full ODI/T20I Format of Tour</span></li>
<li><span>Player Total 4s : Total 4s Hitted by an Individual Player in Full ODI/T20I Format of Tour</span></li>
<li><span>Player Total 6s : Total 6s Hitted by an Individual Player in Full ODI/T20I Format of Tour</span></li>
<li><span>Player Boundaries : Total Boundaries Hitted by an Individual Player in Full ODI/T20I Format of Tour</span></li>
<li><span className='danger'>For Player based Events, If any Player not in playing 11 suppose First match any player played completely but if same player not available in next match, then that match bets all bets will be deleted and first match bets will be valid and average will count in other bets</span></li>
<li><span className='danger'>If any one match not in Playing 11 means Average Given for that match</span></li>
<li><span className='danger'>Average For Players:</span></li>
<li><span>A Finch Total Runs, Boundaries, 4s and 6s : 26,4,3 & 1.</span></li>
<li><span>D Warner Total Runs, Boundaries, 4s and 6s : 28,5,4& 1.</span></li>
<li><span>P Nissanka Total Runs, Boundaries, 4s and 6s : 22,3,2 & 1.</span></li>
<li><span>D Gunathilaka Total Runs, Boundaries, 4s and 6s : 23,3,2 & 1.</span></li>
<li><span>J Hazlewood,M Starc,D Chameera & M Theekshana : 2 wkts Average given if player not in playing 11.</span></li>
<li><span className='danger'>Indoor Cricket T10 League</span></li>
<li><span>9 Players squad with 7 players a side Over Arm Box Cricket Championship</span></li>
<li><span className='danger'>Scoring Rules :</span></li>
<li><span>Hitting the ball in Zone A (the front net, i.e., the net behind the wicket keeper) won't get you any bonus runs.</span></li>
<li><span>If the ball hits the net in Zone B (side nets between the striker's end and halfway down the pitch), you get 1 bonus run.</span></li>
<li><span>If the ball hits the net in Zone C (side nets between the bowler's end and halfway), you score 2 bonus runs.</span></li>
<li><span>Hitting the ball in Zone D (the back net, i.e., the net behind the bowler) allows you to score 4 or 6 bonus runs depending on how the ball hits the back net. If the ball hits the net after bouncing, you get 4 bonus runs. If the ball hits the net without bouncing on the ground, you score 6 bonus runs.</span></li>
<li><span>If the ball hits Zone B or C onto Zone D, you score 3 bonus runs.</span></li>
<li><span>Remember that at least one physical run must be taken for any bonus runs to be scored. Whatever bonus runs you get will be added to the physical runs. For example, if you strike the ball into the front net for 1 bonus run and take 2 physical runs, you score a total of 3 runs off the ball.</span></li>
<li><span className='danger'>Game Format :</span></li>
<li><span>10 over a-side innings</span></li>
<li><span>Power Play for the 1st 3 overs. Only 1 player allowed beyond the Inner box marking. After the end of power play over, only 2 players can be outside the Inner Box.</span></li>
<li><span>No Ball & Wide balls as per normal cricketing rules.</span></li>
<li><span>If the ball touches the upper net and if any player catches the ball, the batsman is considered out.</span></li>
<li><span>If the Ball Touches the Upper Net and lands safely on the field, then the batsman have to take a physical run if they want, if no physical run is taken there will be no runs.</span></li>
<li><span>Zone A shall concede 0 runs.</span></li>
<li><span>If the player hits the net after the middle line (Zone C) its 2 bonus runs. (only taken into consideration if the players take a physical run)</span></li>
<li><span>If the player hits the net before the middle line (Zone B) its 1 bonus run. (only taken into consideration if the players take a physical run)</span></li>
<li><span>If the ball goes straight to the boundary (Zone D) without a bounce, it’s a SIX.</span></li>
<li><span>If the ball bounces and goes to the boundary (Zone D) it’s a FOUR</span></li>
<li><span>If the ball hits the upper net and goes straight to the boundary (Zone D) it’s a 6.</span></li>
<li><span>If the ball hits the upper net and bounces and goes straight to the boundary (Zone D) it’s a 4.</span></li>
<li><span>Note: Bonus Runs are only applied if the ball hits or touches the Side Nets of that particular zone (B&C) and taken into consideration if the players take a physical Run.</span></li>
<li><span>The bowler is not allowed to touch the front line or the side line of the Crease, in case they do so it will be counted as a no ball and 2 runs will be given to the batting team and the ball will not be counted.</span></li>
<li><span>If a bowler bowls a no or a wide ball, the delivery will not be counted and each wide or no ball will be given 2 runs to the batting team total.</span></li>
<li><span>If the batsman is a right hander and if the ball goes out of the white wide line it will be given as a wide ball & if the ball is going leg side and is inside the Leg Side line the ball is counted.</span></li>
<li><span>Dismissals in Indoor Cricket are as followed: Bowled, Run Out, Catch Out, Stumping and Handling the Ball.</span></li>
<li><span>If the bowler is bowling directly above waist and one bounce above shoulder level it is counted as a no ball, but the batter has to play the ball from the crease, in case the batter is outside the crease and plays the ball it will be termed as a good ball.</span></li>
<li><span>Incomplete action or throwing the ball to the stump will be termed as a no ball and 2 runs will be given to the batting team.</span></li>
<li><span>If the batsman does not hit the ball after it is bowled it is considered as a Dot Ball, the batsman gets 0 runs.</span></li>
<li><span>If the batsman hits the ball and the fielders or the wicket-keeper catch it without it touching the floor, the batsman will be dismissed as Catch Out.</span></li>
<li><span>If the ball touches a fielder and then hits the nets (zones), the bonus runs will be counted, if the physical runs are taken by the batter.</span></li>
<li><span>No runs for overthrow.</span></li>
<li><span>If the ball is caught directly after touching the zones (B/C), it will be treated as NOT OUT and bonus runs are applicable if physical run is taken.</span></li>
<li><span>If the ball touches the bonus run zones and the fielder accomplishes a run out, the batter will be OUT and no bonus runs will be counted. Physical run will be counted if 1 run is taken and run out happens during the second run.</span></li>
<li><span>When a batter gets out, the next player coming in will take the strike.</span></li>
<li><span>Run out will ONLY be at the batter’s end.</span></li>
<li><span>When 6 wickets of a team fall, the last batter will be allowed to bat. The team will send a runner at non-striker’s end. After every physical run taken, the last batsman will have to go back to strike to face the next ball. Run out for the runner will mean dismissal for the last batsman.</span></li>
</ul>
</div>
</>
)
}

export default FancyRules;
