import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const TopHeader = () => {

const navigate = useNavigate();

const openMyBets = () => {
navigate('/my_bets');
}

return (
<>
<div className='top-header'>
<div className='container-med'>
<div className='rows'>
<div className='cols-two'>
<button onClick={() => openMyBets()} className='myBetsBtn'><img src='/images/bets.png' alt='betsimg' /> My Bets</button>
</div>
<div className='cols-two'>
<Link to='/games-rules'><span className='rulesSpan'>Games Rules</span></Link>
</div>
</div>
</div>
</div>
</>
)
}

export default TopHeader;
