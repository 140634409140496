import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';

let betAmt = 0;
let betRate = 0;
var allSid = [];
var matchOddsRunner = [];
var bettingAct = false;

function clearId(){
allSid = [];
matchOddsRunner = [];   
}

function stakeBet(mid,sid,amt){
//console.log(sid +' -- '+ amt);
$('#bet-amount_'+mid+sid).val(amt);
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');
beforePls(mid,sid);
}

function minusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) / 2).toFixed());
beforePls(mid,sid);
}

function plusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls(mid,sid);
}

function changeAmt(mid,sid,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#bet-amount_'+mid+sid).val(0);
}else{
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');  
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');    
$('#bet-amount_'+mid+sid).val(Math.round(val));
}
beforePls(mid,sid);
}

function oddsClick(mid,sid,type,tno,userAct){
if(!userAct){
$('#AcceptAgeModal').show();
return false;
}
if(type === 'back'){
betRate = $('#price_back_'+mid+sid+tno).text();
}else{
betRate = $('#price_lay_'+mid+sid+tno).text();
}
$('#bet-rate_'+mid+sid).val(betRate);
$('#bet-amount_'+mid+sid).val(0);
$('#selBetType_'+mid+sid).text(type);
$('#tno_'+mid+sid).text(tno);
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');

if(type === 'back'){
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(207, 100%, 26%, 1)', background: 'linear-gradient(180deg, hsla(207, 100%, 26%, 1) 0%, hsla(207, 80%, 61%, 1) 50%, hsla(207, 100%, 26%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#badcf7', borderTop: '2px solid #217ec4'});
}else{
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(347, 98%, 68%, 1)', background: 'linear-gradient(180deg, hsla(347, 98%, 68%, 1) 0%, hsla(347, 79%, 85%, 1) 50%, hsla(347, 98%, 68%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#fee7ec', borderTop: '2px solid #f76e8c'});    
}

for(let i=0; i < matchOddsRunner.length; i++){
if(mid === matchOddsRunner[i].mid && sid === matchOddsRunner[i].sid){
$('#placeBet_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).show();
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).text('');
}   
else{
$('#placeBet_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).hide();
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).text('');
} 
}
}

function beforePls(mid,sid){
let rate = $('#bet-rate_'+mid+sid).val();
const type = $('#selBetType_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val(); 
const gtype = $('#gtype_'+mid+sid).text().trim();
let a = 0;
let b = 0;

if(gtype === 'match'){
a = parseFloat((rate * betAmt - betAmt).toFixed(2)).toLocaleString();
b = betAmt;
}
else{
a = parseFloat((rate * betAmt / 100).toFixed(2)).toLocaleString();
b = betAmt;
}

//console.log(rate +' -- '+ type +' -- '+ betAmt);
for(let i=0; i < matchOddsRunner.length; i++){
if(mid === matchOddsRunner[i].mid && type === 'back'){
if(sid === matchOddsRunner[i].sid){
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#44B10A'}).show().text('('+a+')');
}
else{
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).show().text('('+b+')');
}
}
else if(mid === matchOddsRunner[i].mid && type === 'lay'){
if(sid === matchOddsRunner[i].sid){
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#bd0000'}).show().text('('+a+')');
}
else{
$('#pls2_'+mid+matchOddsRunner[i].sid).css({color: '#44B10A'}).show().text('('+b+')');
}
}
}
}

function minusRate(mid,sid){
betRate = $('#bet-rate_'+mid+sid).val();
$('#bet-rate_'+mid+sid).val((parseFloat(betRate) - parseFloat('0.01')).toFixed(2));
beforePls(mid,sid);
}

function plusRate(mid,sid){
betRate = $('#bet-rate_'+mid+sid).val();
$('#bet-rate_'+mid+sid).val((parseFloat(betRate) + parseFloat('0.01')).toFixed(2)); 
beforePls(mid,sid);
}

function cancelBet(mid,sid){
$('#placeBet_'+mid+sid).hide();
$('#bet-rate_'+mid+sid).val(0);
$('#bet-amount_'+mid+sid).val(0);

for(let i=0; i < matchOddsRunner.length; i++){
$('#pls2_'+matchOddsRunner[i].mid+matchOddsRunner[i].sid).hide();   
}
}

function betSend(mid,sid){
var bet_type = $('#selBetType_'+mid+sid).text().trim();
var tno = $('#tno_'+mid+sid).text();
var gtype = $('#gtype_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val();
betRate = $('#bet-rate_'+mid+sid).val();
let currMin = 0;
let currMax = 0;
let betDelay = 1;
bettingAct = true;

$('#betMsgWrap_'+mid+sid).css({display: 'grid'});
$('.oddsDiv').attr('disabled', 'disabled');

allSid.forEach((e) => {
if(gtype === 'match'){
if(e.sec === 'match_odds'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}
}
else if(gtype === 'match1'){
if(e.sec === 'bookmaker'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}  
}
});

if (parseFloat(betAmt) < parseFloat(currMin)){
$('#bet_msg_'+mid+sid).show().html('Minimum bet '+ currMin);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
else if(parseFloat(betAmt) > parseFloat(currMax)){
$('#bet_msg_'+mid+sid).show().html('Maximum bet '+ currMax);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}

setTimeout(function(){
if(bet_type === 'back'){
let currRateBack = $('#price_back_'+mid+sid+tno).text();
if(parseFloat(currRateBack) > parseFloat(betRate)){
//console.log('bet no allowed' + betRate +' -- '+ currRateBack);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
else if(bet_type === 'lay'){
let currRateLay = $('#price_lay_'+mid+sid+tno).text();
if(parseFloat(betRate) > parseFloat(currRateLay)){
//console.log('bet no allowed' + betRate +' -- '+ currRateLay);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
finalPlaceBet(mid,sid,betDelay);
},(betDelay * 1000));
}

async function finalPlaceBet(marketId,sid,betDelay){
var currRate = 0;
var eid = $('#eventid_'+marketId+sid).text();
var mid = $('#marketid_'+marketId+sid).text();
var eventname = $('#eventname').text().trim();
var team = $('#teamSel_'+marketId+sid).text().trim();
var bet_type = $('#selBetType_'+marketId+sid).text().trim();
var tno = $('#tno_'+marketId+sid).text();
betAmt = $('#bet-amount_'+marketId+sid).val();
if(bet_type === 'back'){
currRate = $('#price_back_'+marketId+sid+tno).text();
}
else{
currRate = $('#price_lay_'+marketId+sid+tno).text();   
}
var sec = $('#sec_'+marketId+sid).text();
var gtype = $('#gtype_'+marketId+sid).text();
var sport = $('#sport_'+marketId+sid).text();

// console.log(eid +' -- '+ mid +' -- '+ eventname +' -- '+ team +' -- '+ bet_type +' -- '+ tno +' -- '+ betAmt +' -- '+ currRate +' -- '+ sec +' -- '+ sport);

if(currRate > 0 || eid !== '' || mid !== '' || eventname !== ''){
try {
const sendData = await axios.post('/server/cricket-matchOdds-saveBet/', {
eid: eid,
mid: mid,
eventname: eventname,
team: team,
bet_type: bet_type,
betAmt: betAmt,
betRate: currRate,
sec: sec,
gtype: gtype,
sport: sport,
tno: tno,
betDelay: betDelay
});
const resData = await sendData.data;
if(resData === 'success'){
// console.log(resData);
setExposure();
getAllBetsMarket(eid,mid);
matchOdds_pls(eid,sport);
$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);    
}
else{
$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);    
}
} catch (error) {
console.log('front tennis-matchodds-savebet error: ' + error);
}
}else{
$('#bet_msg_'+marketId+sid).show().html('Bet no placed');
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct = false;
cancelBet(marketId,sid);
},1000);  
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);
}

async function matchOdds_pls(eventid,sport){
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
const response = resData.filter((e) => e.sport === sport && e.eventid === eventid);
if(response.length > 0){
if(matchOddsRunner.length > 0){

//console.log(response);
//console.log(matchOddsRunner);
var allSec = [];

matchOddsRunner.forEach((e) => {
if(!allSec.includes(e.sec)){
allSec.push(e.sec);
}
});

//console.log(allSec);

allSec.forEach((ex) => {
//console.log(ex);
const findTeam = matchOddsRunner.filter((e) => e.sec.trim() === ex.trim());
//console.log(findTeam);
if(findTeam.length > 0){
//console.log(findTeam[0].rc);
//var currSec = '';
var t1Prf = 0;
var t2Prf = 0;
var t3Prf = 0;

response.filter((e) => e.sec.trim() === ex.trim()).forEach((exx) => {
//console.log(exx.sec);
var runner1 = null;
var runner2 = null;
var runner3 = null;

if(findTeam[0].rc === 3){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
runner3 = findTeam[2].runner;
}
else if(findTeam[0].rc === 2){
runner1 = findTeam[0].runner;
runner2 = findTeam[1].runner;
}

//console.log(exx.sec +' -- '+ runner1 +' --'+ runner2 +' -- '+ runner3 +' -- '+ runner4 
//+' --'+ runner5 +' --'+ runner6 +' -- '+ runner7 +' -- '+ runner8 +' -- '+ runner9);

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'back'){
//currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'back'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'back'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner1 !== null ? runner1.trim() : null) && exx.bettype === 'lay'){
// currSec = exx.sec;
t1Prf = parseFloat(t1Prf) - parseFloat(exx.loss);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner2 !== null ? runner2.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) - parseFloat(exx.loss);
t3Prf = parseFloat(t3Prf) + parseFloat(exx.profit);
}

if(exx.team.trim() === (runner3 !== null ? runner3.trim() : null) && exx.bettype === 'lay'){
//  currSec = exx.sec;
t1Prf = parseFloat(t1Prf) + parseFloat(exx.profit);
t2Prf = parseFloat(t2Prf) + parseFloat(exx.profit);
t3Prf = parseFloat(t3Prf) - parseFloat(exx.loss);
}
    
});

//console.log(currSec +' -- '+ t1Prf +' -- '+ t2Prf +' -- '+ t3Prf +' -- '+ t4Prf +' -- '+ t5Prf 
//+' -- '+ t6Prf +' -- '+ t7Prf +' -- '+ t8Prf +' -- '+ t9Prf);

if(findTeam[0] !== undefined){
if(t1Prf > 0){
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[0].mid+findTeam[0].sid).html(parseFloat(t1Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[1] !== undefined){
if(t2Prf > 0){
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[1].mid+findTeam[1].sid).html(parseFloat(t2Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

if(findTeam[2] !== undefined){
if(t3Prf > 0){
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(parseFloat(t3Prf.toFixed(2)).toLocaleString()).css({color: '#44b10a'});
}
else{
$('#teamPrf_'+findTeam[2].mid+findTeam[2].sid).html(parseFloat(t3Prf.toFixed(2)).toLocaleString()).css({color: '#bd0000'});
}
}

}
});
}
}
} catch (error) {
console.log('frontend tennis matchOdds pls bets api : ' + error);
}
}

export {
stakeBet,
minusAmt,
plusAmt,
changeAmt, 
oddsClick, 
beforePls, 
allSid,
minusRate, 
plusRate,
cancelBet,
betSend,
finalPlaceBet,
matchOddsRunner,
matchOdds_pls,
clearId,
bettingAct
};