import React from 'react';

const KhadoRules = () => {
return (
<>
<div className='toogle-Items khadoRules'>
<ul>
<li><span>Only First inning valid for T20 and one day matches.</span></li>
<li><span>Same will be work like Lambi. If match abandoned or over reduced, all bets will be deleted.</span></li>
<li><span>You can choose your own value in this event.</span></li>
</ul>
</div>
</>
)
}

export default KhadoRules;
