import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';

const ProfitLoss = () => {

const Navigate = useNavigate();
const { GetProfitLoss, profitLossUser } = GlobalContext();
const [currRecords, setCurrRecords] = useState([]);
const [selectSport, setSelectSport] = useState('all');
const [totalMain, setTotalMain] = useState(0);
const [startDate, setStartDate] = useState(new Date());
const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)));
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currRecords.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currRecords.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const userEventPls = (sport,event) => {
Navigate('/profit-loss/event' , {state:{sport: sport, event: event, startDate: startDate, endDate: endDate}});
}

useEffect(() => {
var select_sport = selectSport;
var start_date = new Date(startDate).toDateString();
var end_date = new Date(endDate).toDateString();
GetProfitLoss(select_sport,start_date,end_date);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[selectSport, startDate, endDate]);

useEffect(() => {
if(profitLossUser.length > 0){
setCurrRecords(
profitLossUser.filter((e) => e.settlement === 'settle').reduce((a, b) => {
const { sport, eventName, win_status, profit, loss} = b;
const item = a.find((y) => y.sport === sport && y.eventName === eventName);
let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({sport,eventName,pls});
return a;
},[])
);
}else{
setCurrRecords([]);  
}
},[profitLossUser]);

useEffect(() => {
if(records.length > 0){
const totalAmt = records.reduce((a, b) => {
a = a + b.pls;
return a;
},0);

setTotalMain(totalAmt);
}
else{
setTotalMain(0);  
}
},[records]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/pls.png' alt='highImg' /> <span>profi loss</span></div>

<div className='simple-div'>

<div className='searchItemsDiv'>
<div className='four-column-row'>
<div className='four-column'>
<div className='input-div-md'>
<label>select sport</label>
<select id='select_sport' onChange={(e) => setSelectSport(e.target.value)}>
<option value='all'>all</option>
<option value='null'>--select one sport--</option>
<option value='cricket'>cricket</option>
<option value='soccer'>soccer</option>
<option value='tennis'>tennis</option>
<option value='teenpatti-t20'>teenpatti t20</option>
<option value='teenpatti-oneday'>teenpatti oneday</option>
<option value='teenpatti-test'>teenpatti test</option>
<option value='dragon-tiger-t20'>dragon-tiger t20</option>
<option value='dragon-tiger-oneday'>dragon-tiger oneday</option>
<option value='lucky7-a'>lucky7 a</option>
<option value='lucky7-b'>lucky7 b</option>
<option value='andar-bahar'>andar bahar</option>
<option value='head-tail'>head tail</option>
<option value='lucky-0-to-9'>lucky 0 to 9</option>
</select>
</div>
</div>
<div className='four-column'>
<div className='input-div-md'>
<label>start date</label>
<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
</div>
</div>
<div className='four-column'>
<div className='input-div-md'>
<label>end date</label>
<DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
</div>
</div>
</div>
</div>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currRecords.length} records...</span>
</div>

<div className='tabel-responsiveDiv'>
<table className='tabel-md-res'>
<thead>
<tr className='plsThTr'>
<th>sport</th>
<th>event</th>
<th>profit / loss</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return (
<tr key={index} className='plsTr'>
<td>{data.sport}</td>
<td><span className='links' onClick={() => userEventPls(data.sport,data.eventName)}>{data.eventName}</span></td>
<td>{data.pls > 0 ? <span className='successTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span> : data.pls < 0 ? <span className='dangerTxt'>{parseFloat(data.pls.toFixed(2)).toLocaleString()}</span> : 0}</td>
</tr>
)
})  
:
<tr className='plsTr'>
<td colSpan='3'><span className='noRecordFound_td'>no record found...</span></td>
</tr>  
}
<tr className='plsTr'>
<td><b>Total</b></td>
<td>--</td>
<td>{totalMain > 0 ? <span className='successTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : totalMain < 0 ? <span className='dangerTxt'>{parseFloat(totalMain.toFixed(2)).toLocaleString()}</span> : 0}</td>
</tr>
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default ProfitLoss;
