import React from 'react';

const ElectionRules = () => {
return (
<>
<div className='toogle-Items electionRules'>
<ul>
<li><span className='danger'>Indian state legislative assembly elections.</span></li>
<li><span>1. This event is to decide the winner of various legislative assemblies of india .</span></li>
<li><span>2. The final result declared by election commission of india for assembly elections of various states of india for a particular year will be valid in our exchange ,The customers are entirely responsible for their bets at all times.</span></li>
<li><span>3. All bets will be voided if the election doesn’t take place in given time by election commission or as per our exchange management decision.</span></li>
<li><span>4. Company reserves the right to suspend/void any bets on this event at any time if we find the same not to be legitimate with the certainty of the outcome.</span></li>
<li><span>5. Accidental issues during assembly elections will not be counted in our exchange ,If required Additional candidates may be added on request.</span></li>
<li><span>6. Kindly be informed no candidates will be partially settled and will remain in the market until it is fully settled. This is to ensure that all customers can continue trading for the candidates that they have positions on, since each candidate is still a valid runner in this market.</span></li>
<li><span>7. Please be informed that the transmissions described as “live” by few broadcasters may actually be delayed due to multiple scenarios.</span></li>
<li><span>8. If any candidate withdraws for any reason, including death, all bets on the market will be valid and be settled as per the defined rules.</span></li>
</ul>
</div>
</>
)
}

export default ElectionRules;
