import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../allBets/Betsmarket';

let count = 0;
var startTimer;
var startMid;
var endMid;
var startFlip;
var settleData;
var runTimerData;
var headWinnerData;
var tailWinnerData;

function setMarketId(){
let marketId = '12.' + Math.floor((Math.random() * 1000000 * Date.now()).toString().slice(0, 10));
let eventId =  Math.floor((Math.random() * 1000000 * Date.now()).toString().slice(0, 10));
$('#marketid').text(marketId);
$('#marketEid').text(eventId);
}

function runTimerNow(){
count = 11;
startTimer = setInterval(goTimer, 1000);  
}

function goTimer(){
if (count <= 0){
$('#countstart').html(count);
stopTimer();
} else{
count--;
$('#countstart').html(count);
}
}

function stopTimer(){
count = 0;
$('.casinoCoins').addClass('inactive').attr('disabled', 'disabled');
$('.casinoBets').addClass('inactives').attr('disabled', 'disabled');
$('.checkImg').hide();
clearInterval(startTimer);
startFlip = setTimeout(() => flipCoin(), 2000);
}

function flipCoin(){
let coins = document.querySelector('.coins');
let i = Math.floor(Math.random() * 2);
if(coins !== null){
coins.style.animation = 'none';
if(i){
setTimeout(function(){
coins.style.animation = 'spin-heads 2s forwards';
playSound();
},100);
headWinnerData = setTimeout(() => headWinner(), 2000);
}else{
setTimeout(function(){
coins.style.animation = 'spin-tails 2s forwards';
playSound();
},100);
tailWinnerData = setTimeout(() => tailWinner(), 2000);
}
}
}

function playSound(){
var audioPla = document.getElementById('audioPla');
var playPromise = audioPla.play();
if (playPromise !== undefined) {
playPromise.then(_ => {
// Automatic playback started!
// Show playing UI.
})
.catch(error => {
// Auto-play was prevented
// Show paused UI.
});
}
}

async function headWinner(){
var mid = $('#marketid').text() !== '' ? $('#marketid').text() : 0;
var eid = $('#marketEid').text() !== '' ? $('#marketEid').text() : 0;
var eventName = 'virtual casino';
var sport = 'head-tail';
var winner = 'head';
$('.winner-hide').show();
if(mid === 0 || eid === 0){
$('#winner').html('bet rejected mid error');
settleData = setTimeout(() => settleResult(), 1000);
endMid = setTimeout(() => closeMid(), 3000);
runTimerData = setTimeout(() => runTimer(), 5000);
}
else{
$('#winner').html('head win');
try {
const sendData = await axios.post('/server/head-tail-result', {
mid: mid,
eid: eid,
eventName: eventName,
sport: sport,
winner: winner
});
const resData = await sendData.data;
//console.log('head ' + resData);
if(resData === 'success'){
//console.log('result head inserted');
setTimeout(() => $('.winner-hide').hide(), 3000);
settleData = setTimeout(() => settleResult(), 1000);
endMid = setTimeout(() => closeMid(), 3000);
runTimerData = setTimeout(() => runTimer(), 5000);
}   
else{
console.log('result head inserted error');  
}
} catch (error) {
console.log('frontend head-tail-result head api error: ' + error);
}
}
}

async function tailWinner(){
var mid = $('#marketid').text() !== '' ? $('#marketid').text() : 0;
var eid = $('#marketEid').text() !== '' ? $('#marketEid').text() : 0;
var eventName = 'virtual casino';
var sport = 'head-tail';
var winner = 'tail';
$('.winner-hide').show();
if(mid === 0 || eid === 0){
$('#winner').html('bet rejected mid error');
settleData = setTimeout(() => settleResult(), 1000);
endMid = setTimeout(() => closeMid(), 3000);
runTimerData = setTimeout(() => runTimer(), 5000);
}
else{
$('#winner').html('tail win'); 
try {
const sendData = await axios.post('/server/head-tail-result', {
mid: mid,
eid: eid,
eventName: eventName,
sport: sport,
winner: winner
});
const resData = await sendData.data;
//console.log('tail ' + resData);
if(resData === 'success'){
//console.log('result tail inserted');
setTimeout(() => $('.winner-hide').hide(), 3000);
settleData = setTimeout(() => settleResult(), 1000);
endMid = setTimeout(() => closeMid(), 3000);
runTimerData = setTimeout(() => runTimer(), 5000);
}   
else{
console.log('result tail inserted error');  
}
} catch (error) {
console.log('frontend head-tail-result tail api error: ' + error);
}
}
}

async function settleResult(){
var eventId = $('#marketEid').text() !== '' ? $('#marketEid').text() : 0;
//console.log(eventId);
try {
const sendData = await axios.get('/server/virtual-head-tail-settel/'+eventId);
const resData = await sendData.data;
//console.log(resData);
if(resData === 'success'){
setExposure();
}
} catch (error) {
console.log('front head-tail settle api error: ' + error); 
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);
}

function closeMid(){
$('#marketid').text(0);
$('#marketEid').text(0);
}

function runTimer(){
count = 10;
$('.casinoCoins').removeClass('inactive').removeAttr('disabled');
$('.casinoBets').removeClass('inactives').removeAttr('disabled');
$('#countstart').html(count);
$('#allMarket_bets').hide();
GetHeadResults();
startMid = setTimeout(() => setMarketId(), 500);
startTimer = setInterval(goTimer, 1000);
}

async function GetHeadResults(){
try {
const getData = await axios.get('/server/virtual-head-tail-result');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
document.getElementById('resultData').innerHTML = '';
resData.forEach(element => {
var winnerFirst = element.winner.split('');
if(winnerFirst[0] === 'h'){
document.getElementById('resultData').innerHTML += `<span class='activeYellow'>${winnerFirst[0]}</span>`;
}
else{
document.getElementById('resultData').innerHTML += `<span class='activeReddark'>${winnerFirst[0]}</span>`;
}
});
}
} catch (error) {
console.log('frontend head_tail_result error : ' + error);
}
}

const placeBet = async(amt,betTeam,setBetTeam,currency,userAct) => {
if(!userAct){
$('#AcceptAgeModal').show();
return false;
}
else{
var mid = $('#marketid').text();
var eid = $('#marketEid').text();
var team = betTeam;
var eventName = 'virtual casino';
var sport = 'head-tail';
var betamount = currency === 'inr' ? amt : amt / 100;
var bettype = 'back';
var sec = 'match odds'
var betRate = '1.98';

if(mid <= 0){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('market id changed');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(team === null){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('team no selected');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
if(betamount < (currency === 'inr' ? 100 : 100 / 100)){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('minimum betamount 100');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server/savebet-headTail', {
mid: mid,
eid: eid,
team: team,
eventName: eventName,
sport: sport,
betamount: betamount,
bettype: bettype,
betRate: betRate,
sec: sec
});
const resData = await sendData.data;
if (resData === 'success'){
$('#msgAlert').fadeIn().addClass('alert-success').removeClass('alert-danger').html('bet placed');
setTimeout(function(){
$('.checkImg').hide();
setExposure();
getAllBetsMarket(eid);
setBetTeam(null);
$('#msgAlert').fadeOut('slow');
},1000);
return false;   
}
else if (resData === 'login error'){
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html('please login or refresh page');
setTimeout(function(){
$('#msgAlert').fadeOut('slow');
window.location.reload();
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
setBetTeam(null);
$('.checkImg').hide();
$('#msgAlert').fadeOut('slow');
},1000);
return false;   
}
} catch (error) {
console.log('front savebet-headTail api error : ' + error);
}
}
}
}

export {
startMid,
endMid,
startFlip,
settleData,
runTimerData,
headWinnerData,
tailWinnerData,
startTimer,
GetHeadResults,
settleResult,
setMarketId,
runTimerNow,
placeBet
}