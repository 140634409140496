import { useEffect, useState } from "react";
import axios from 'axios';
import { GlobalContext } from "../context/AppContext";

const customerData = '/server/active-customer';

const AutoLogOut = () => {

const  { customer } = GlobalContext();
const [beforeIp, setBeforeIp] = useState(null);
const [currIp, setCurrIp] = useState(null);
const [currSta, setCurrSta] = useState(null);

useEffect(() => {
const startAuto = setInterval(fetchData, 1000);

async function fetchData() {
try {
const getData = await axios.get(`${customerData}`);
const resData = await getData.data;
if(resData.length > 0){
setCurrIp(resData[0].ip);
setCurrSta(resData[0].status);
}
else{
clearInterval(startAuto);
}
} catch (error) {
console.log('frontend auto_log_out error : ' + error);
}
}

fetchData();

},[]);

useEffect(() => {
if(customer.length > 0){
setBeforeIp(customer[0].ip);
}
}, [customer]);

useEffect(() => {
if(currIp !== null && beforeIp !== null){
if(currIp !== beforeIp || currSta === 'suspended'){
async function ipLogOut() {
try {
const sendData = await axios.get('/server/logout');
const resData = await sendData.data;
if(resData === 'success'){
window.location.replace('/');
return false;
}
} catch (error) {
console.log('backend logout-api error : ' + error);
} 
}

ipLogOut();
}
}

// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currIp, currSta, beforeIp]);

return null;
}

export default AutoLogOut;
