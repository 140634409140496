import React from 'react';

const BookmakerRules = () => {
return (
<>
<div className='toogle-Items bookmakerRules'>
<ul>
<li><span className='danger'>Due to any reason any team will be getting advantage or disadvantage we are not concerned.</span></li>
<li><span className='danger'>Company reserves the right to suspend/void any id/bets if the same is found to be illegitimate. For example incase of vpn/robot-use/multiple entry from same IP/ multiple bets at the same time (Punching) and others. Note : only winning bets will be voided.</span></li>
<li><span className='danger'>We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over comparison)</span></li>
<li><span className='danger'>We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over comparison)</span></li>
<li><span className='danger'>Any query about the result or rates should be contacted within 7 days of the specific event, the same will not be considered valid post 7 days from the event.</span></li>
<li><span className='danger'>If two team ends up with equal points, then result will be given based on the official point table</span></li>
<li><span className='danger'>Tennis:- Advance fancy market</span></li>
<li><span>If the second set is not completed all bets regarding this market will be voided</span></li>
<li><span>If a player retires after completion of second set, then the market will be settled as three sets</span></li>
<li><span className='danger'>Virtual Cricket</span></li>
<li><span>At any situation if the video gets interrupted/stopped then the same cannot be continued due to any technical issues bookmaker market will be voided</span></li>
</ul>
</div>
</>
)
}

export default BookmakerRules;
