import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';

const Tennis = () => {

const Navigate = useNavigate();
const { tennList, customer, spoSett, gameSett } = GlobalContext();
const [lockSport, setLockSport] = useState([]);

const openMarket = (eventid, marketid) => {
Navigate('/market-2/'+eventid+'/'+marketid);
}

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);
                        
useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'tennis');
//console.log(checkLock);
if(checkLock.length > 0){
$('#sports_lock10').css({display: 'flex'});
}else{
$('#sports_lock10').hide();
}
}
},[lockSport]);

useEffect(() => {
if(gameSett.length > 0 && tennList.length > 0){
tennList.filter((e) => gameSett.find((o) => parseFloat(e.cid) === parseFloat(o.eventid) && o.sport === 'tennis')).forEach((k) => {
$('#GameLock_'+k.cid+k.mid).css({display: 'flex'});
});
}
},[gameSett, tennList]);

return (
<>
<div className='multimarketdIV'><img src='/images/tennis.png' alt='highImg' /> <span>tennis in-play upcoming</span></div>

<div className='sports-page'>

<div className='sports_lock' id='sports_lock10'>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>

<table className='sports-page-tabel'>
<thead>
<tr>
<th></th>
<th></th>
<th>1</th>
<th>2</th>
</tr>
</thead>
{
tennList.length > 0 ? tennList.map((data, index) => {
var iplay = '';
var eventTime = '';
var timeParse = Date.parse(data.stime);
if(data.iplay === true || data.iplay === 'true'){
iplay = <span className='inplay-avail'><img src='/images/green-circle.png' alt='sportsIco' /></span>
}
else{
eventTime = <span className='event-time'>{new Date(timeParse).toLocaleString()}</span>;
}

var eventDateToday = new Date(timeParse).toLocaleDateString();
var today = new Date();
var tomorrowDate = new Date();
tomorrowDate.setDate(today.getDate()+1);
var parseDataDate = Date.parse(eventDateToday);
var parseCurrDate = Date.parse(tomorrowDate.toLocaleDateString());
if(parseDataDate > parseCurrDate){
return (
<tbody key={index}>

<tr>
<td className='GameLock' id={'GameLock_'+data.cid+data.mid}>
<span>event is locked <i className='fa fa-lock colLock'></i></span>
</td>
</tr>

<tr>
<td>
<span className='blockSpan'>
<span onClick={() => openMarket(data.gmid, data.mid)} className='event-name'>{data.ename.trim().replace('-', 'v')}</span>
{eventTime}
</span>
</td>
<td><span className='blockSpan2'>{iplay}</span></td>
{
data.section.length > 0 && data.section.map((data2, index2) => {
return (
<td className='counts-rates' key={index2}>
<span className='back-sports-page'>{data2.odds[0].otype === 'back' ? data2.odds[0].odds : data2.odds[1].odds}</span>
<span className='lay-sports-page'>{data2.odds[1].otype === 'lay' ? data2.odds[1].odds : data2.odds[0].odds}</span>
</td>
)
})   
}
</tr>
</tbody>
)
}
else{
return null;
}
})  
:
<tbody>
<tr>
<td colSpan='4'>No tennis event in inplay yesterday...</td>
</tr>
</tbody>
}
</table>
</div>
</>
)
}

export default Tennis;
