import React from 'react';

const IplRules = () => {
return (
<>
<div className='toogle-Items iplRules'>
<ul>
<li><span className='danger'>If IPL fixture of 74 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this)</span></li>
<li><span className='danger'>Management decision will be fina</span>l</li>
<li><span>Total 1st over runs: Average 5 runs will be given in case match abandoned or over reduced (only 1st innings valid)</span></li>
<li><span>Total 1st 6 over run:- Average 46 runs will be given in case match abandoned or over reduced (Only 1st Innings valid)</span></li>
<li><span>Total fours: Average 25 fours will be given in case match abandoned or over reduced</span></li>
<li><span>Total sixes: Average 13 sixes will be given in case match abandoned or over reduced</span></li>
<li><span>Total Wickets - Average will 12 Wickets be given in case match abandoned or over reduced</span></li>
<li><span>Total Wides - Average 8 wides will be given in case match abandoned or over reduced</span></li>
<li><span>Total Extras - Average 17 extras will be given in case match abandoned or over reduced</span></li>
<li><span>Total Fifties - Average 2 fifties will be given in case match abandoned or over reduced</span></li>
<li><span>Total Caught outs: Average 8 caught out will be given in case match abandoned or over reduced</span></li>
<li><span>Total Bowled:- Average 2 Bowled out will be given in case match abandoned or over reduced</span></li>
<li><span>Total LBW:- Average 1 LBW will be given in case match abandoned or over reduced</span></li>
<li><span>Total Run out:- Average 1 Run out will be given in case match abandoned or over reduced</span></li>
<li><span>Total No ball :- Average 1 No ball will be given in case match abandoned or over reduced</span></li>
<li><span className='danger'>At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid</span></li>
<li><span>How many matches win by a team : Only valid for league stage matches. Ex. For CSK, How many matches CSK win during league stages only considered. Playoffs matches not considered in this.</span></li>
<li><span>Most 4's by individual batsman of IPL : Maximum Number of Fours Hit By A Batsman in full Tournament. Ex. Last Season (2021) R Gaikwad Hit 64 Fours in 16 Matches. So, 64 was the Result for last season.</span></li>
<li><span>Most 4's by an individual batsman in an Inning of IPL : Maximum Number of Fours Hit By A Batsman in one Innings. Ex. Last Season (2021) S Yadav Hit 13 Fours in 55th league Match. So, 13 was the Result for last season.</span></li>
<li><span>Most 6's by individual batsman of IPL : Maximum Number of Sixes Hit By A Batsman in full Tournament. Ex. Last Season (2021) KL Rahul Hit 30 Sixes in 13 Matches. So, 30 was the Result for last season.</span></li>
<li><span>Most 6's by individual batsman in an Inning of IPL : Maximum Number of Sixes Hit By A Batsman in one Innings. Ex. Last Season (2021) K Pollard Hit 8 Sixes in 27th league Match. So, 8 was the Result for last season.</span></li>
<li><span>Most runs given by Bowler in an Inning of IPL : Maximum How much Runs conceded by a individual Bowler in an Innings. Ex : Last Season (2021) L Ngidi concede 62 runs in 4 overs of 27th Match. So, 62 was the Result for last season.</span></li>
<li><span>Most wickets by Bowler in an inning of IPL : Maximum How much Wickets taken by a individual Bowler in an Innings. Ex : Last Season (2021) H Patel taken 5 wickets in 1st Match. So, 5 was the Result for last season.</span></li>
<li><span>Most 50's by individual batsman of IPL : Maximum Number of Fifties Hit By A Batsman in full Tournament. Ex. Last Season (2021) Faf duPlessis Hit 6 fifties in 16 Matches. So, 6 was the Result for last season.</span></li>
<li><span>Highest total runs in individual match of IPL : Maximum total runs of both Teams in individual match. Ex . Last Season (2021) RR vs PBKS match both teams Total runs 438 (4th League Match) . So, 438 was the Result for Last Season.</span></li>
<li><span>Highest innings run - Both innings are valid</span></li>
<li><span>Lowest innings run - Only first innings is valid</span></li>
<li><span>Highest over run: Both innings are valid</span></li>
<li><span>Highest Match 1st over run in individual match: Only first innings is valid</span></li>
<li><span>Highest Fours in individual match: Both innings are valid</span></li>
<li><span>Highest Sixes in individual match: Both innings are valid</span></li>
<li><span>Highest Extras in individual match: Both innings are valid</span></li>
<li><span>Highest Wicket in individual match: Both innings are valid</span></li>
<li><span>Highest 6 over run: - Both innings are valid</span></li>
<li><span className='danger'>Super over will not be included</span></li>
<li><span>In fastest fifty always the first 50 runs will be considered, for example of R Sharma scores 1st fifty in 17 balls and scores 100 in next 14 balls, fastest 50 will be given based on the balls for the 1st fifty runs</span></li>
</ul>
</div>
</>
)
}

export default IplRules;
