import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';
import numFormat from '../NumFormat';
import { 
oddsClick,
allSid,
cancelBet,
minusRate,
plusRate,
minusAmt,
plusAmt,
stakeBet,
changeAmt,
betSend,
matchOddsRunner,
matchOdds_pls
} from './js/betting';

const AllOdds = () => {

const { customer, tennMarket, tennList, betSett } = GlobalContext();
const [ currData, setCurrData ] = useState([]);
const [availBetSet, setAvailBetSet] = useState([]);
const { eventid, marketid } = useParams();

useEffect(() => {
if(tennMarket.length !== 0){
setCurrData(tennMarket);
}
}, [tennMarket]);

useEffect(() => {
if(currData.length !== 0 && currData.data !== undefined){
matchOdds_pls(eventid,'tennis');
}
},[currData, eventid]);

useEffect(() => {
if(betSett.length > 0){
const availData = betSett.filter((e) => tennList.find((f) => parseFloat(e.eventid) === parseFloat(f.cid) && parseFloat(f.gmid) === parseFloat(eventid)));
if(availData.length > 0){
const availData2 = betSett.filter((e) => availData.find((f) => e.sec !== f.sec && e.sport === 'tennis'));
setAvailBetSet(availData.concat(availData2));
}
else if(availData.length <= 0){
const availData2 = betSett.filter((e) => e.sport === 'tennis' && e.type === 'default');  
setAvailBetSet(availData2);
}
}
},[tennList, betSett, eventid]);

useEffect(() => {
if(availBetSet.length > 0 && customer.length > 0){
availBetSet.forEach((e) => {
if(e.sec === 'match_odds'){
if(customer[0].currency === 'inr'){
$('.matchMin').text(e.min_bet +'-'+ e.max_bet); 
}
else{
$('.matchMin').text((e.min_bet / 100) +'-'+ (e.max_bet / 100));   
}
}
if(e.sec === 'bookmaker'){
if(customer[0].currency === 'inr'){
$('.matchMinBook').text(e.min_bet +'-'+ e.max_bet); 
}
else{
$('.matchMinBook').text((e.min_bet / 100) +'-'+ (e.max_bet / 100));      
}
}
    
var data = {
sec : e.sec,
min_bet : customer[0].currency === 'inr' ? e.min_bet : (e.min_bet / 100),
max_bet : customer[0].currency === 'inr' ? e.max_bet : (e.max_bet / 100),
min_odds : e.min_odds,
max_odds : e.max_odds,
bet_delay : e.bet_delay,
type : e.type
}

const findData = allSid.findIndex((o) => o.sec === e.sec && o.type === e.type);
if(findData === -1){
allSid.push(data);
}
});
}
}, [availBetSet, customer]);

return (
<>
{
currData.length !== 0 && currData.data !== undefined && currData.data.map((data, index) => {
//console.log(data);
return (
<div className='marketOdds-wrap' id={'OddsDiv_'+data.mid} key={index}>

<div className='headTitleRow-market'>
<div className='col'>
<div className='oddsTitleNew'>
<div><span>{data.mname.replaceAll('_', ' ')}</span></div> 
<div><i className='fa fa-exclamation-circle'></i></div>
</div>
</div>
<div className='col'>
<div className='gameStatusSec'>
<span>in-play</span>
{
data.iplay === (true || 'true') ?
<img src='/images/bell-green.png' alt='bell-green' />
:
<img src='/images/bell-red.png' alt='bell-red' />
}
</div>
</div>
</div>

{
data.gtype.trim() === 'match' ?
<table className='oddsTable'>
<thead>
{
parseInt(data.ocnt) === (6 || '6') ?
<tr>
<th colSpan='3' className='mobHide'></th>
<th className='diskHide'>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th>
<th className='backth'>back</th>
<th className='layth'>lay</th>
<th colSpan='2' className='mobHide'>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th>
</tr>
: parseInt(data.ocnt) === (2 || '2') ?
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th>
<th className='yesth'>back</th>
<th className='noth'>lay</th>
</tr>
:
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMin'>0-1</span></span>
</th> 
<th className='yesth'>back</th>
</tr>
}
</thead>

<tbody className='blockTbody' id={'blockTbody_'+data.mid}>
<tr>
<td><span id={'blockBodyTxt_'+data.mid}>{data.status}</span></td> 
</tr>
</tbody>

{
data.section.map((data2, index2) => {

var RunData = {
rc: data.rc,
sec : data.mname,
runner: data2.nat,
mid: data2.mid,
sid: data2.sid,
}

const findSid = matchOddsRunner.findIndex(o => o.sid === data2.sid && o.mid === data2.mid);

if(findSid === -1){
matchOddsRunner.push(RunData);
}

return (
<tbody key={index2}>
<tr>
<td>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back3').map((data3, index3) => {
return (
<td className='counts-td back3Div' key={index3}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>   
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back2').map((data3, index3) => {
return (
<td className='counts-td back2Div' key={index3}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>   
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'back' && e.oname === 'back1').map((data3, index3) => {
return (
<td className='counts-td back1Div' key={index3}>
<button className='oddsDiv' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>   
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay1').map((data3, index3) => {
return (
<td className='counts-td lay1Div' key={index3}>
<button className='oddsDiv' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay2').map((data3, index3) => {
return (
<td className='counts-td lay2Div' key={index3}>
<button className='oddsDiv' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>
</td>
)
})
}

{
data2.odds.filter((e) => e.otype === 'lay' && e.oname === 'lay3').map((data3, index3) => {
return (
<td className='counts-td lay3Div' key={index3}>
<button className='oddsDiv' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{numFormat(data3.size)}</span>
</button>
</td>
)
})
}

</tr>

<tr style={{display:'none'}} id={'placeBet_'+data2.mid+data2.sid}>
<td style={{padding:'0'}} colSpan='7'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrap_'+data2.mid+data2.sid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msg_'+data2.mid+data2.sid}></span>
</div>
<div className='bet-input-row' id={'betRow_'+data2.mid+data2.sid}>
<span style={{display:'none'}} id={'selBetType_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'tno_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'sec_'+data2.mid+data2.sid}>{data.mname}</span>
<span style={{display:'none'}} id={'gtype_'+data2.mid+data2.sid}>{data.gtype}</span>
<span style={{display:'none'}} id={'sport_'+data2.mid+data2.sid}>tennis</span>
<span style={{display:'none'}} id={'eventid_'+data2.mid+data2.sid}>{eventid}</span>
<span style={{display:'none'}} id={'marketid_'+data2.mid+data2.sid}>{marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div>
<span onClick={() => minusRate(data2.mid,data2.sid)}>-</span>
<input type='number' id={'bet-rate_'+data2.mid+data2.sid} placeholder='0' disabled />
<span onClick={() => plusRate(data2.mid,data2.sid)}>+</span>
</div>

<div>
<span onClick={() => minusAmt(data2.mid,data2.sid)}>-</span>
<input type='number' id={'bet-amount_'+data2.mid+data2.sid} placeholder='0' onChange={(e) => changeAmt(data2.mid,data2.sid,e.target.value)} />
<span onClick={() => plusAmt(data2.mid,data2.sid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace_'+data2.mid+data2.sid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_'+data2.mid+data2.sid}>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace2_'+data2.mid+data2.sid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>
</tbody>
)
})
}

</table>
:
<div className='bookmakerWrap'>
<div className='scrollTxt_flat'><div><span>{data.rem}</span></div></div>
<table className='oddsTable bookmaker_Table'>
<thead>
<tr>
<th>
<span className='min-max'><span className='minF'>min/max :</span> <span className='minS matchMinBook'>0-1</span></span>
</th>
<th className='backth_book' colSpan='3' style={{textAlign: 'right', paddingRight: '15px'}}>back</th>
<th className='layth_book' colSpan='3' style={{textAlign: 'left', paddingLeft: '15px'}}>lay</th>
</tr>
</thead>

<tbody className='blockTbody' id={'blockTbody_'+data.mid}>
<tr>
<td><span id={'blockBodyTxt_'+data.mid}>{data.status}</span></td>   
</tr>
</tbody>

{
data.section.map((data2, index2) => {

var RunData = {
rc: data.rc,
sec : data.mname,
runner: data2.nat,
mid: data2.mid,
sid: data2.sid,
}

const findSid = matchOddsRunner.findIndex(o => o.sid === data2.sid && o.mid === data2.mid);

if(findSid === -1){
matchOddsRunner.push(RunData);
}

if(data2.odds.length > 2) {
return (
<tbody key={index2}>
<tr>
<td className='teamTd'>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>
<td colSpan='3' className='bookTd1'>
<div className='bookBack'>
{
data2.odds.map((data3, index3) => {
if(data3.otype === 'back'){
return(
<div key={index3}>
{
data3.otype === 'back' && data3.oname === 'back3' &&
<button className='' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'back' && data3.oname === 'back2' &&
<button className='' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'back' && data3.oname === 'back1' &&
<button className='bookBackMain' id={'oddsDiv_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_size_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}
</div>
)
}
else { return null; }
}) 
}
</div>

<div className='blockTdSingle' id={'blockTdSingle_'+data2.mid+data2.sid}>
<span id={'blockTdSingleTxt_'+data2.mid+data2.sid}>{data2.gstatus}</span>
</div>
</td>

<td colSpan='3' className='bookTd2'>
<div className='bookLay'>
{
data2.odds.map((data3, index3) => {
if(data3.otype === 'lay'){
return(
<div key={index3}>
{
data3.otype === 'lay' && data3.oname === 'lay1' &&
<button className='bookLayMain' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'lay' && data3.oname === 'lay2' &&
<button className='' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}

{
data3.otype === 'lay' && data3.oname === 'lay3' &&
<button className='' id={'oddsDivLay_'+data.mid+data3.sid+data3.tno} onClick={() => oddsClick(data.mid,data3.sid,data3.otype,data3.tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data.mid+data3.sid+data3.tno}>{data3.odds !== 0 ? data3.odds : ''}</span>
<span className='size' id={'price_laysize_'+data.mid+data3.sid+data3.tno}>{data3.size !== 0 ? numFormat(data3.size) : ''}</span>
</button>
}
</div>
)
}
else { return null; }
}) 
}
</div>
</td>
</tr>

<tr style={{display:'none'}} id={'placeBet_'+data2.mid+data2.sid}>
<td style={{padding:'0'}} colSpan='7'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrap_'+data2.mid+data2.sid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msg_'+data2.mid+data2.sid}></span>
</div>
<div className='bet-input-row' id={'betRow_'+data2.mid+data2.sid}>
<span style={{display:'none'}} id={'selBetType_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'tno_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'sec_'+data2.mid+data2.sid}>{data.mname}</span>
<span style={{display:'none'}} id={'gtype_'+data2.mid+data2.sid}>{data.gtype}</span>
<span style={{display:'none'}} id={'sport_'+data2.mid+data2.sid}>tennis</span>
<span style={{display:'none'}} id={'eventid_'+data2.mid+data2.sid}>{eventid}</span>
<span style={{display:'none'}} id={'marketid_'+data2.mid+data2.sid}>{marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet-rate_'+data2.mid+data2.sid} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt(data2.mid,data2.sid)}>-</span>
<input type='number' id={'bet-amount_'+data2.mid+data2.sid} placeholder='0' onChange={(e) => changeAmt(data2.mid,data2.sid,e.target.value)} />
<span onClick={() => plusAmt(data2.mid,data2.sid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace_'+data2.mid+data2.sid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_'+data2.mid+data2.sid}>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace2_'+data2.mid+data2.sid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>
</tbody>
)
}
else{
return(
<tbody key={index2}>
<tr>  
<td className='teamTd'>
<span className='teamName' id={'teamSel_'+data2.mid+data2.sid}>{data2.nat}</span>
<span className='pls' id={'plsArr_'+data2.mid+data2.sid}><i className='fa fa-arrow-right'></i> <span id={'teamPrf_'+data2.mid+data2.sid}> 0.00</span></span>
<span className='pls2' id={'pls2_'+data2.mid+data2.sid}></span>
</td>

<td colSpan='3' className='bookTd1'>
<div className='bookBack'>
<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

{
data2.odds[0].otype === 'back' && data2.odds[0].oname === 'back1' ?
<div>
<button className='bookBackMain' id={'oddsDiv_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno} onClick={() => oddsClick(data2.mid,data2.odds[0].sid,data2.odds[0].otype,data2.odds[0].tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].odds !== 0 ? data2.odds[0].odds : ''}</span>
<span className='size' id={'price_size_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].size !== 0 ? numFormat(data2.odds[0].size) : ''}</span>
</button>
</div>
:
<div>
<button className='bookBackMain' id={'oddsDiv_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno} onClick={() => oddsClick(data2.mid,data2.odds[1].sid,data2.odds[1].otype,data2.odds[1].tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_back_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].odds !== 0 ? data2.odds[1].odds : ''}</span>
<span className='size' id={'price_size_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].size !== 0 ? numFormat(data2.odds[1].size) : ''}</span>
</button>
</div>
}
</div>

<div className='blockTdSingle' id={'blockTdSingle_'+data2.mid+data2.sid}>
<span id={'blockTdSingleTxt_'+data2.mid+data2.sid}>{data2.gstatus}</span>
</div>
</td>

<td colSpan='3' className='bookTd2'>
<div className='bookLay'>
{
data2.odds[1].otype === 'lay' && data2.odds[1].oname === 'lay1' ?
<div>
<button className='bookLayMain' id={'oddsDivLay_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno} onClick={() => oddsClick(data2.mid,data2.odds[1].sid,data2.odds[1].otype,data2.odds[1].tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].odds !== 0 ? data2.odds[1].odds : ''}</span>
<span className='size' id={'price_laysize_'+data2.mid+data2.odds[1].sid+data2.odds[1].tno}>{data2.odds[1].size !== 0 ? numFormat(data2.odds[1].size) : ''}</span>
</button>
</div>
:
<div>
<button className='bookLayMain' id={'oddsDivLay_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno} onClick={() => oddsClick(data2.mid,data2.odds[0].sid,data2.odds[0].otype,data2.odds[0].tno,customer.length > 0 ? true : false)}>
<span className='price' id={'price_lay_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].odds !== 0 ? data2.odds[0].odds : ''}</span>
<span className='size' id={'price_laysize_'+data2.mid+data2.odds[0].sid+data2.odds[0].tno}>{data2.odds[0].size !== 0 ? numFormat(data2.odds[0].size) : ''}</span>
</button>
</div>
}

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>

<div>
<button className='' disabled>
<span className='price'></span>
<span className='size'></span>
</button>
</div>
</div>
</td>

</tr>

<tr style={{display:'none'}} id={'placeBet_'+data2.mid+data2.sid}>
<td style={{padding:'0'}} colSpan='7'>
<div className='wrapbet-row'>
<div className='bet-msgWrap' id={'betMsgWrap_'+data2.mid+data2.sid}>
<div className='loaderDiv'>
<span className='dot1'></span>
<span className='dot2'></span>
<span className='loadTx'>Loading...</span>
</div>
<span id={'bet_msg_'+data2.mid+data2.sid}></span>
</div>
<div className='bet-input-row' id={'betRow_'+data2.mid+data2.sid}>
<span style={{display:'none'}} id={'selBetType_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'tno_'+data2.mid+data2.sid}></span>
<span style={{display:'none'}} id={'sec_'+data2.mid+data2.sid}>{data.mname}</span>
<span style={{display:'none'}} id={'gtype_'+data2.mid+data2.sid}>{data.gtype}</span>
<span style={{display:'none'}} id={'sport_'+data2.mid+data2.sid}>tennis</span>
<span style={{display:'none'}} id={'eventid_'+data2.mid+data2.sid}>{eventid}</span>
<span style={{display:'none'}} id={'marketid_'+data2.mid+data2.sid}>{marketid}</span>

<div className='col'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div>
<span>-</span>
<input type='number' id={'bet-rate_'+data2.mid+data2.sid} placeholder='0' disabled />
<span>+</span>
</div>

<div>
<span onClick={() => minusAmt(data2.mid,data2.sid)}>-</span>
<input type='number' id={'bet-amount_'+data2.mid+data2.sid} placeholder='0' onChange={(e) => changeAmt(data2.mid,data2.sid,e.target.value)} />
<span onClick={() => plusAmt(data2.mid,data2.sid)}>+</span>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace_'+data2.mid+data2.sid}>place bet</button>
</div>

</div>
</div>

<div className='colbtnBet' id={'betColBet_'+data2.mid+data2.sid}>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake1 : 100)}>{customer.length > 0 ? customer[0].stake1 : 100}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake2 : 200)}>{customer.length > 0 ? customer[0].stake2 : 200}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake3 : 500)}>{customer.length > 0 ? customer[0].stake3 : 500}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake4 : 1000)}>{customer.length > 0 ? customer[0].stake4 : 1000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake5 : 2000)}>{customer.length > 0 ? customer[0].stake5 : 2000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake6 : 5000)}>{customer.length > 0 ? customer[0].stake6 : 5000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake7 : 10000)}>{customer.length > 0 ? customer[0].stake7 : 10000}</button>
<button onClick={() => stakeBet(data2.mid,data2.sid, customer.length > 0 ? customer[0].stake8 : 25000)}>{customer.length > 0 ? customer[0].stake8 : 25000}</button>
</div>

<div className='mobilePlace'>
<div className='cancel'>
<button onClick={() => cancelBet(data2.mid,data2.sid)}>cancel</button>
</div>

<div className='place'>
<button onClick={() => betSend(data2.mid,data2.sid)} className='' id={'betsPlace2_'+data2.mid+data2.sid}>place bet</button>
</div> 
</div>
</div>
</td>
</tr>
</tbody>
)
}
})
}
</table>
</div>
}
</div>
)
})
}
</>
)
}

export default AllOdds;
