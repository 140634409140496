import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const MainMenu = () => {

const { cicList, soccList, tennList, spoSett, customer } = GlobalContext();
const Navigate = useNavigate();
const [menuSide, setMenuSide] = useState(false);
const [lockSport, setLockSport] = useState([]);
const location = useLocation();

const cricketLive = cicList.length > 0 && cicList.filter((e) => e.inplay === 'true');
const soccerLive = soccList.length > 0 && soccList.filter((e) => e.iplay === true);
const tennisLive = tennList.length > 0 && tennList.filter((e) => e.iplay === true);

const SideMenu = () => {
if(window.screen.width > 768){
if(menuSide === false){
$('.bcols-md').hide();
$('.bcols-lg').removeClass('bcols_resp');
setMenuSide(true); 
}
else{
$('.bcols-md').show();  
$('.bcols-lg').addClass('bcols_resp');
setMenuSide(false);  
}
}
else{
if(menuSide === false){
$('.bcols-md').show();
setMenuSide(true); 
}   
else{
$('.bcols-md').hide(); 
setMenuSide(false);    
}
}
}

const Cricket = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'cricket');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/cricket');   
}
}
else{
Navigate('/cricket');
}
}

const Soccer = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'soccer');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/soccer');   
}
}
else{
Navigate('/soccer');
}
}

const Tennis = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'tennis');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/tennis');   
}
}
else{
Navigate('/tennis');
}
}

const MultiMarket = () => {
Navigate('/multimarket');   
}

const home = () => {
Navigate('/');     
}

const inplay = () => {
Navigate('/in-play');       
}

const Casino = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-casino');   
}
}
else{
Navigate('/all-casino');
}  
} 

const virtualCasino = () => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
if(checkLock.length > 0){
//console.log('lock sport');
$('#sports_lock').show();
return false;
}
else{
Navigate('/all-virtual-casino');   
}
}
else{
Navigate('/all-virtual-casino');
}     
}

useEffect(() => {
if(location.pathname === '/'){
$('.1').addClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').addClass('mobileFooteractive');
$('#footMenu_2').removeClass('mobileFooteractive');
$('#footMenu_3').removeClass('mobileFooteractive');
$('#footMenu_4').removeClass('mobileFooteractive');
$('#footMenu_5').removeClass('mobileFooteractive');
}
else if(location.pathname === '/in-play'){
$('.1').removeClass('activeMain');
$('.2').addClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').removeClass('mobileFooteractive');
$('#footMenu_2').addClass('mobileFooteractive');
$('#footMenu_3').removeClass('mobileFooteractive');
$('#footMenu_4').removeClass('mobileFooteractive');
$('#footMenu_5').removeClass('mobileFooteractive');
}
else if(location.pathname === '/multimarket'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').addClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').removeClass('mobileFooteractive');
$('#footMenu_2').removeClass('mobileFooteractive');
$('#footMenu_3').removeClass('mobileFooteractive');
$('#footMenu_4').addClass('mobileFooteractive');
$('#footMenu_5').removeClass('mobileFooteractive');
}
else if(location.pathname === '/cricket'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').addClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('.footMenu').removeClass('mobileFooteractive');
}
else if(location.pathname === '/soccer'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').addClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('.footMenu').removeClass('mobileFooteractive');
}
else if(location.pathname === '/tennis'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').addClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('.footMenu').removeClass('mobileFooteractive');
}
else if(location.pathname === '/all-casino'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').addClass('activeMain');
$('.8').removeClass('activeMain');
$('.footMenu').removeClass('mobileFooteractive');
}
else if(location.pathname === '/all-virtual-casino'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').addClass('activeMain');
$('.footMenu').removeClass('mobileFooteractive');
}
else if(location.pathname === '/all-sports'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').removeClass('mobileFooteractive');
$('#footMenu_2').removeClass('mobileFooteractive');
$('#footMenu_3').addClass('mobileFooteractive');
$('#footMenu_4').removeClass('mobileFooteractive');
$('#footMenu_5').removeClass('mobileFooteractive');
}
else if(location.pathname === '/mobile-account'){
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').removeClass('mobileFooteractive');
$('#footMenu_2').removeClass('mobileFooteractive');
$('#footMenu_3').removeClass('mobileFooteractive');
$('#footMenu_4').removeClass('mobileFooteractive');
$('#footMenu_5').addClass('mobileFooteractive');
}
else{
$('.1').removeClass('activeMain');
$('.2').removeClass('activeMain');
$('.3').removeClass('activeMain');
$('.4').removeClass('activeMain');
$('.5').removeClass('activeMain');
$('.6').removeClass('activeMain');
$('.7').removeClass('activeMain');
$('.8').removeClass('activeMain');
$('#footMenu_1').removeClass('mobileFooteractive');
$('#footMenu_2').removeClass('mobileFooteractive');
$('#footMenu_3').removeClass('mobileFooteractive');
$('#footMenu_4').removeClass('mobileFooteractive');
$('#footMenu_5').removeClass('mobileFooteractive'); 
}
}, [location]);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

return (
<>
<div className='main-menu'>
<div className='rows'>
<div className='menu-col'><img src='/images/menu.png' alt='menu' onClick={() => SideMenu()} /></div>
<div className='menu-col-lg'>
<div className='allmain-menus-wrap'>
<ul id='mainMainu'>
<li className='mainMenuLi 1 mobHide' onClick={() => home()}><img src='/images/home.png' alt='main-menu' /> home</li>
<li className='mainMenuLi 2 mobHide' onClick={() => inplay()}><img src='/images/inplay.png' alt='main-menu' /> in-play</li>
<li className='mainMenuLi 3 mobHide' onClick={() => MultiMarket()}><img src='/images/pinMenu.png' alt='main-menu' /> multi-market</li>
<li className='mainMenuLi 4' onClick={() => Cricket()}>
<img src='/images/cricket.png' alt='main-menu' /> cricket
<div className='liveMenu'>
<span>live</span>
<span>{cricketLive.length !== undefined ? cricketLive.length : 0}</span>
</div>
</li>
<li className='mainMenuLi 5' onClick={() => Soccer()}>
<img src='/images/soccer.png' alt='main-menu' /> soccer
<div className='liveMenu'>
<span>live</span>
<span>{soccerLive.length !== undefined ? soccerLive.length : 0}</span>
</div>
</li>
<li className='mainMenuLi 6' onClick={() => Tennis()}>
<img src='/images/tennis.png' alt='main-menu' /> tennis
<div className='liveMenu'>
<span>live</span>
<span>{tennisLive.length !== undefined ? tennisLive.length : 0}</span>
</div>
</li>
<li className='mainMenuLi 7' onClick={() => Casino()}>
<img src='/images/casino.png' alt='main-menu' /> casino
<div className='liveMenu'>
<span>live</span>
<span>8</span>
</div>
</li>
<li className='mainMenuLi 8' onClick={() => virtualCasino()}>
<img src='/images/virtualCasino.png' alt='main-menu' /> virtual casino
<div className='liveMenu'>
<span>live</span>
<span>2</span>
</div>
</li>
</ul>
</div>
</div>
<div className='menu-col'><img src='/images/search.png' alt='menu' /></div>
</div>
</div>
</>
)
}

export default MainMenu;
