import axios from 'axios';

async function getAllBetsMarket(eventid){
//console.log(eventid);
try {
const getData = await axios.get('/server/all-bets');
const resData = await getData.data;
//console.log(resData);
if(resData.length > 0){
const findBets = resData.filter((e) => parseFloat(e.eventid) === parseFloat(eventid));

//console.log(findBets);
if(findBets.length > 0){
if(document.getElementById('allMarketBets') !== null && document.getElementById('allMarket_bets') !== null){
document.getElementById('allMarketBets').innerHTML = '';
document.getElementById('allMarket_bets').style.display = 'block';
findBets.forEach(element => {
if(element.bettype === 'back'){
document.getElementById('allMarketBets').innerHTML += `<tr class='backTr'>
<td>${element.team}</td>
<td>${element.sec}</td>
<td>
${element.sec === 'fancy' || element.sec === 'over' || element.sec === 'oddeven'
|| element.sec === 'meter' || element.sec === 'line' || element.sec === 'khado'
|| element.sec === 'ball' || element.sec === 'fancy1' ? 'yes' : element.bettype }
</td>
<td>${element.betRate}</td>
<td>${element.betSize !== null ? element.betSize : 0}</td>
<td>${parseFloat((element.betamount).toFixed(2)).toLocaleString()}</td>
<td>${parseFloat((element.profit).toFixed(2)).toLocaleString()}</td>
<td>${parseFloat((element.loss).toFixed(2)).toLocaleString()}</td>
<td>${element.bet_dateTime}</td>
</tr>`;
}
if(element.bettype === 'lay'){
document.getElementById('allMarketBets').innerHTML += `<tr class='layTr'>
<td>${element.team}</td>
<td>${element.sec}</td>
<td>
${element.sec === 'fancy' || element.sec === 'over' || element.sec === 'oddeven'
|| element.sec === 'meter' || element.sec === 'line' || element.sec === 'khado'
|| element.sec === 'ball' || element.sec === 'fancy1' ? 'no' : element.bettype }
</td>
<td>${element.betRate}</td>
<td>${element.betSize !== null ? element.betSize : 0}</td>
<td>${parseFloat((element.betamount).toFixed(2)).toLocaleString()}</td>
<td>${parseFloat((element.profit).toFixed(2)).toLocaleString()}</td>
<td>${parseFloat((element.loss).toFixed(2)).toLocaleString()}</td>
<td>${element.bet_dateTime}</td>
</tr>`;
}
});
}
}
}
} catch (error) {
console.log('front market bets data api error : ' + error);
}
}

export {getAllBetsMarket};