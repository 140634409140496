import React from 'react';
import Cricket from './Cricket';
import Soccer from './Soccer';
import Tennis from './Tennis';

const InPlay2 = () => {
return (
<>
<Cricket />
<Soccer />
<Tennis />
</>
)
}

export default InPlay2;
