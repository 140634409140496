import React, { useEffect, useState } from 'react';
import $ from 'jquery';

const Slider = () => {

const [slider, setSlider] = useState('../images/slide1.jpg');

const sliderImg = [
{slide: '/images/slide1.jpg'},
{slide: '/images/slide2.jpg'},
{slide: '/images/slide3.jpg'},
{slide: '/images/slide4.jpg'},
{slide: '/images/slide5.jpg'}
];

const setDot = (data) => {
setSlider(data);  
}

useEffect(() => {
let slideIndex = 0;
const sliderImg2 = [
{slide: '/images/slide1.jpg'},
{slide: '/images/slide2.jpg'},
{slide: '/images/slide3.jpg'},
{slide: '/images/slide4.jpg'},
{slide: '/images/slide5.jpg'}
];

const updateSlider = () => {
slideIndex++;
$('#dots_'+slideIndex).addClass('Dotactive');

if(slideIndex >= parseInt(sliderImg2.length)){
slideIndex = -1;
$('.dots').removeClass('Dotactive');
}
else{
setSlider(sliderImg2[slideIndex].slide);   
}
}

setInterval(function(){
updateSlider();
},3000);
},[]);

return (
<>
<div className='slider-area'>
<div className='slider-all'>
<img src={slider} alt='slider' />
</div>

<div className='slider-dots'>
{
sliderImg.length > 0 && sliderImg.map((data, index) => {
return(
<span onClick={() => setDot(data.slide)} className='dots' id={'dots_'+index} key={index}></span>
)
})
}
</div>

</div>
</>
)
}

export default Slider;
