import React, { useEffect, useState } from 'react';
import SideMenu from '../extra/SideMenu';
import { GlobalContext } from '../context/AppContext';

const ActivityLog = () => {

const { activityLog } = GlobalContext();
const [currPage, setCurrPage] = useState(1);
const [pageData, setPageData] = useState([]);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = pageData.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(pageData.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

useEffect(() => {
if(activityLog.length > 0){
setPageData(activityLog);
}
},[activityLog]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='text-page'>
<div className='text-page-wrap'>
<div className='multimarketdIV'><img src='/images/log.png' alt='highImg' /> <span>activity log</span></div>

<div className='simple-div'>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {pageData.length} records...</span>
</div>

<div className='tabel-responsiveDiv'>
<table className='tabel-md'>
<thead>
<tr>
<th>ip</th>
<th>city</th>
<th>region</th>
<th>postal</th>
<th>country</th>
<th>Org</th>
<th>timezone</th>
<th>date-time</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return (
<tr key={index}>
<td>{data.ip}</td>
<td>{data.city}</td>
<td>{data.region}</td>
<td>{data.postal}</td>
<td>{data.country}</td>
<td>{data.org}</td>
<td>{data.timezone}</td>
<td>{data.create_date}</td>
</tr>
)
})   
:
<tr>
<td colSpan='8'><span>no records found...</span></td>
</tr> 
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>
</div>
</div>
</div>
</div>
</>
)
}

export default ActivityLog;
