import $ from 'jquery';
import axios from 'axios';
import { getAllBetsMarket } from '../../../allBets/Betsmarket';
import { allSid } from './betting';

let betAmt = 0;
let betRate = 0;
//let betSize = 0;
var allTeamOdd = [];
var allTeamFancy1 = [];
var allSidFodds = [];
var bettingAct3 = false;

//console.log(allSidFodds);

function clearOddId(){
allSidFodds = [];
allTeamOdd = [];   
allTeamFancy1 = [];
}

function stakeBet(mid,sid,amt){
//console.log(sid +' -- '+ amt);
$('#bet-amount_'+mid+sid).val(amt);
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');
beforePls(mid,sid);
}

function minusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) / 2).toFixed());
beforePls(mid,sid);
}

function plusAmt(mid,sid){
betAmt = $('#bet-amount_'+mid+sid).val();
$('#bet-amount_'+mid+sid).val((parseFloat(betAmt) + parseFloat(betAmt)).toFixed());
beforePls(mid,sid);
}

function changeAmt(mid,sid,val){
if(parseFloat(val !== '' ? val : 0) <= 0){
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#bet-amount_'+mid+sid).val(0);
}else{
$('#betsPlace_'+mid+sid).addClass('active').removeAttr('disabled');  
$('#betsPlace2_'+mid+sid).addClass('active').removeAttr('disabled');    
$('#bet-amount_'+mid+sid).val(Math.round(val));
}
beforePls(mid,sid);
}

function oddsClick(mid,sid,type,tno,userAct){
if(!userAct){
$('#AcceptAgeModal').show();
return false;
}
if(type === 'back'){
betRate = $('#price_back_'+mid+sid+tno).text();
}else{
betRate = $('#price_lay_'+mid+sid+tno).text();
}

$('#bet-rate_'+mid+sid).val(betRate);
$('#bet-amount_'+mid+sid).val(0);
$('#selBetType_'+mid+sid).text(type);
$('#tno_'+mid+sid).text(tno);
$('#betsPlace_'+mid+sid).removeClass('active').attr('disabled', 'disabled');
$('#betsPlace2_'+mid+sid).removeClass('active').attr('disabled', 'disabled');

if(type === 'back'){
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(207, 100%, 26%, 1)', background: 'linear-gradient(180deg, hsla(207, 100%, 26%, 1) 0%, hsla(207, 80%, 61%, 1) 50%, hsla(207, 100%, 26%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#badcf7', borderTop: '2px solid #217ec4'});
}else{
$('#betRow_'+mid+sid).css({backgroundColor: 'hsla(347, 98%, 68%, 1)', background: 'linear-gradient(180deg, hsla(347, 98%, 68%, 1) 0%, hsla(347, 79%, 85%, 1) 50%, hsla(347, 98%, 68%, 1) 100%)'});
$('#betColBet_'+mid+sid).css({background: '#fee7ec', borderTop: '2px solid #f76e8c'});    
}

for(let i=0; i < allSidFodds.length; i++){
if(sid === allSidFodds[i]){
$('#placeBet_'+mid+allSidFodds[i]).show();
$('#pls2_'+mid+allSidFodds[i]).css({color: '#bd0000'}).text('('+0+')');
}   
else{
$('#placeBet_'+mid+allSidFodds[i]).hide();
$('#pls2_'+mid+allSidFodds[i]).css({color: '#bd0000'}).text('('+0+')');
} 
}
}

function beforePls(mid,sid){
let rate = $('#bet-rate_'+mid+sid).val();
const type = $('#selBetType_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val(); 
let a = 0;
a = parseFloat((rate * betAmt - betAmt).toFixed(2)).toLocaleString();
if(type === 'back'){
$('#pls2_'+mid+sid).css({color: '#44B10A'}).show().text('('+a+')');
}
else if(type === 'lay'){
$('#pls2_'+mid+sid).css({color: '#bd0000'}).show().text('('+a+')');   
}
}

function cancelBet(mid,sid){
$('#placeBet_'+mid+sid).hide();
$('#bet-rate_'+mid+sid).val(0);
$('#bet-amount_'+mid+sid).val(0);

for(let i=0; i < allSidFodds.length; i++){
$('#pls2_'+mid+allSidFodds[i]).hide();   
}
}

function betSend(mid,sid){
var bet_type = $('#selBetType_'+mid+sid).text().trim();
var tno = $('#tno_'+mid+sid).text();
betAmt = $('#bet-amount_'+mid+sid).val();
betRate = $('#bet-rate_'+mid+sid).val();
let currMin = 0;
let currMax = 0;
let betDelay = 1;
bettingAct3 = true;

$('#betMsgWrap_'+mid+sid).css({display: 'grid'});
$('.oddsDiv').attr('disabled', 'disabled');

allSid.forEach((e) => {
if(e.sec === 'fancy'){
if(e.type === 'series'){
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;
}else{
currMin = e.min_bet;
currMax = e.max_bet;
betDelay = e.bet_delay;    
}
}
});

if (parseFloat(betAmt) < parseFloat(currMin)){
$('#bet_msg_'+mid+sid).show().html('Minimum bet '+ currMin);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(mid,sid);
},1000);
return false;
}
else if(parseFloat(betAmt) > parseFloat(currMax)){
$('#bet_msg_'+mid+sid).show().html('Maximum bet '+ currMax);
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(mid,sid);
},1000);
return false;
}

setTimeout(function(){
if(bet_type === 'back'){
let currRateBack = $('#price_back_'+mid+sid+tno).text();
if(parseFloat(betRate) > parseFloat(currRateBack)){
//console.log('bet no allowed' + betRate +' -- '+ currRateBack);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
else if(bet_type === 'lay'){
let currRateLay = $('#price_lay_'+mid+sid+tno).text();
if(parseFloat(betRate) < parseFloat(currRateLay)){
//console.log('bet no allowed' + betRate +' -- '+ currRateLay);
$('#bet_msg_'+mid+sid).show().html('Odds rate changed');
setTimeout(function(){
$('#betMsgWrap_'+mid+sid).css({display: 'none'});
$('#bet_msg_'+mid+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(mid,sid);
},1000);
return false;
}
}
finalPlaceBet(mid,sid,betDelay);
},(betDelay * 1000));
}

async function finalPlaceBet(marketId,sid,betDelay){
var currRate = 0;
var eid = $('#eventid_'+marketId+sid).text();
var mid = $('#marketid_'+marketId+sid).text();
var eventname = $('#eventname').text().trim();
var team = $('#teamSel_'+marketId+sid).text().trim();
var bet_type = $('#selBetType_'+marketId+sid).text().trim();
var tno = $('#tno_'+marketId+sid).text();
betAmt = $('#bet-amount_'+marketId+sid).val();
if(bet_type === 'back'){
currRate = $('#price_back_'+marketId+sid+tno).text();
}
else{
currRate = $('#price_lay_'+marketId+sid+tno).text(); 
}
var sec = $('#sec_'+marketId+sid).text();
var sport = $('#sport_'+marketId+sid).text();

// console.log(eid +' -- '+ mid +' -- '+ eventname +' -- '+ team +' -- '+ bet_type +' -- '+ tno +' -- '+ betAmt +' -- '+ currRate +' -- '+ sec +' -- '+ sport);

if(currRate > 0 || eid !== '' || mid !== '' || eventname !== ''){
try {
const sendData = await axios.post('/server/cricket-oddEven-saveBet/', {
eid: eid,
mid: mid,
eventname: eventname,
team: team,
bet_type: bet_type,
betAmt: betAmt,
betRate: currRate,
sec: sec,
sport: sport,
tno: tno,
betDelay: betDelay
});
const resData = await sendData.data;
if(resData === 'success'){
// console.log(resData);
setExposure();
getAllBetsMarket(eid,mid);

if(sec === 'oddeven'){
oddEvenFancy_pls(eid,sport);
}
else if(sec === 'fancy1'){
fancy1_pls(eid,sport);
}

$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(marketId,sid);
},1000);    

}
else{
$('#bet_msg_'+marketId+sid).show().html(resData);
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(marketId,sid);
},1000);    
}
} catch (error) {
console.log('front cricket-matchodds-savebet error: ' + error);
}
}else{
$('#bet_msg_'+marketId+sid).show().html('Bet no placed');
setTimeout(function(){
$('#betMsgWrap_'+marketId+sid).css({display: 'none'});
$('#bet_msg_'+marketId+sid).hide().html('');
$('.oddsDiv').removeAttr('disabled');
bettingAct3 = false;
cancelBet(marketId,sid);
},1000);  
}
}

async function setExposure(){
let currBal = 0;
let currExpo = 0;
const getBal = await axios.get('/server/active-customer');
const balRes = await getBal.data;
if(balRes.length > 0){
currBal = balRes[0].total_balance;
}

const getExpo = await axios.get('/server/user-exposure');
const expoRes = await getExpo.data;
if(expoRes.length > 0){
if(expoRes[0].exposure !== null){
currExpo = expoRes[0].exposure;
}
}

$('#mainBal').html(parseFloat((currBal - currExpo).toFixed(2)).toLocaleString());
$('#mainExpo').html(parseFloat((currExpo).toFixed(2)).toLocaleString());
//console.log('curr balance : '+currBal +' exposure : '+ currExpo);

}

async function oddEvenFancy_pls(eventid, sport){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server/all-fancy-exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamOdd.length > 0){
//console.log(allTeamOdd);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'oddeven').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamOdd.length; p++){
if(filterData[i].team === allTeamOdd[p].runner && allTeamOdd[p].sec === 'oddeven'){
$('#teamPrf_'+allTeamOdd[p].mid+allTeamOdd[p].sid).html(parseFloat(filterData[i].loss.toFixed(2)).toLocaleString()).css({color: 'rgb(189, 0, 0)'});
}
}
}
}
}
}
} catch(error){
console.log('front oddEven fancy pls error : ' + error);
}
}

async function fancy1_pls(eventid, sport){
//console.log(eventid +' -- '+ sport);
try {
const getData = await axios.get('/server/all-fancy-exposure/'+eventid);
const resData = await getData.data;
if(resData.length > 0){
if(allTeamFancy1.length > 0){
//console.log(allTeamFancy1);
//console.log(resData);

const filterData = resData.filter((e) => e.sec === 'fancy1').reduce((c, j) => {
const { team, loss } = j;
const item = c.find((e) => e.team === team);
item ? item.loss += loss : c.push({team, loss});
return c;
},[]);

//console.log(filterData);

if(filterData.length > 0){
for(let i = 0; i < filterData.length; i++){
for(let p = 0; p < allTeamFancy1.length; p++){
if(filterData[i].team === allTeamFancy1[p].runner && allTeamFancy1[p].sec === 'fancy1'){
$('#teamPrf_'+allTeamFancy1[p].mid+allTeamFancy1[p].sid).html(parseFloat(filterData[i].loss.toFixed(2)).toLocaleString()).css({color: 'rgb(189, 0, 0)'});
}
}
}
}
}
}
} catch(error){
console.log('front fancy1 pls error : ' + error);
}
}

export {
stakeBet,
minusAmt,
plusAmt,
changeAmt, 
oddsClick, 
beforePls, 
allSidFodds,
allTeamOdd,
allTeamFancy1,
cancelBet,
betSend,
finalPlaceBet,
clearOddId,
oddEvenFancy_pls,
fancy1_pls,
bettingAct3
};