import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import SideMenu from '../extra/SideMenu';
import AcceptAge from '../modals/AcceptAge'
import { GlobalContext } from '../context/AppContext';
import MarketBets from '../allBets/MarketBets';
import {
startMid,
endMid,
startFlip,
settleData,
runTimerData,
headWinnerData,
tailWinnerData,
startTimer,
GetHeadResults,
settleResult,
setMarketId,
runTimerNow,
placeBet
} from './js/gaming';

const HeadTails = () => {

const { customer, spoSett } = GlobalContext();
const [betTeam, setBetTeam] = useState(null);
const [lockSport, setLockSport] = useState([]);
const [lockShow, setLockShow] = useState(false);

$('.headDivBtn').on('click', function(){
$('.checkImg1').show();
$('.checkImg2').hide();
});

$('.tailDiv').on('click', function(){
$('.checkImg1').hide();
$('.checkImg2').show();
});

useEffect(() => {
setMarketId();
runTimerNow();
GetHeadResults();
return () => {
clearInterval(startTimer);
clearTimeout(runTimerData);
clearTimeout(startMid);
clearTimeout(startFlip);
clearTimeout(settleData);
clearTimeout(endMid);
clearTimeout(headWinnerData);
clearTimeout(tailWinnerData);
settleResult();
};
}, []);

useEffect(() => {
if(customer.length > 0){
if(customer[0].creator !== 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.sub_admin || e.username === f.super_master || e.username === f.master || e.username === f.agent || e.username === f.customer)));
}
else if(customer[0].creator === 'admin'){
setLockSport(spoSett.filter((e) => customer.find((f) => e.username === f.customer)));
}
}
},[customer, spoSett]);

useEffect(() => {
if(lockSport.length > 0){
const checkLock = lockSport.filter((e) => e.sport === 'virtual casino');
//console.log(checkLock);
if(checkLock.length > 0){
setLockShow(true);
}else{
setLockShow(false);
}
}
},[lockSport]);

return (
<>
<div className='wrap-body'>
<div className='body-content'>
<div className='brows'>
<div className='bcols-md'><SideMenu /></div>
<div className='bcols-lg bcols_resp'>
<div className='game-body'>
<div className='game-contents'>
<audio id="audioPla" preload="none" src="/audio/coin-flip.mp3"></audio>

<div className='casino-wrap'>

{
lockShow === true && 
<div className='sports_lock' id='sports_lock2' style={{display: 'flex'}}>
<div className='sport_lockTxt'>
<img src='/images/lock.png' alt='lock' />
<span>sport is locked</span>
<span>contact your service provider</span>
</div>
</div>
}

<div className='casino-content'>
<div className='casino-headRow'>
<div className='col'><img src='/images/coin.png' alt='highImg' /><span>Head - Tails</span></div>
<div className='col'><span>Market Id:</span><span id='marketid'>0</span> <span style={{display: 'none'}} id='marketEid'>0</span></div>
</div>

<div className='coins-wrap'>
<div className='countdown-div'><span id='countstart'>0</span></div>
<div className='winner-hide'>
<div className='winner-div'><span className='win-title'>Winner</span><span id='winner'>head</span></div>
</div>
<div className='coins'>
<div className='head-div'>
<div className='coin-div'>
<div>
<span>₹</span>
<span>heads</span>
</div>
</div>
</div>
<div className='tail-div'>
<div className='coin-div'>
<div>
<span>₹</span>
<span>tails</span>
</div>
</div>
</div>
</div>
</div>

<div className='result-casino'>
<div className='col'><span>Result</span><i className='fa fa-arrow-right' /></div>
<div className='col' id='resultData'></div>
</div>

<div className='casinoBetsDivWrap'>
<div className='casinoBetsDiv headDiv'>
<button className='casinoBets headDivBtn' onClick={() => setBetTeam('head')}>
<span className='checkImg checkImg1'><img src='../images/check-mark.png' alt='check-mark' /></span>
<span>1.98</span>
<span>head coin</span>
</button>
</div>

<div className='casinoBetsDiv tailDiv'>
<button className='casinoBets tailDivBtn' onClick={() => setBetTeam('tail')}>
<span className='checkImg checkImg2'><img src='../images/check-mark.png' alt='check-mark' /></span>
<span>1.98</span>
<span>tail coin</span>
</button>
</div>
</div>

<div className='casinosBtnsDiv'>
<div className='casinosBtnsDivWrap'>
<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(100,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 100 : 100 / 100)}</span>
</button>
</div>

<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(250,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 250 : 250 / 100)}</span>
</button>
</div>

<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(500,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 500 : 500 / 100)}</span>
</button>
</div>

<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(1000,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 1000 : 1000 / 100)}</span>
</button>
</div>

<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(2000,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 2000 : 2000 / 100)}</span>
</button>
</div>

<div className='casinoCoinsDiv'>
<button onClick={() => placeBet(3000,betTeam,setBetTeam,customer[0].currency,customer.length > 0 ? true : false)} className='casinoCoins'>
<span>{customer.length > 0 && (customer[0].currency === 'inr' ? 3000 : 3000 / 100)}</span>
</button>
</div>
</div>
</div>

</div>

<MarketBets eventid={0} />
</div>

</div>
</div>
</div>
</div>
</div>
</div>

<AcceptAge />
</>
)
}

export default HeadTails;
