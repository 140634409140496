import React from 'react';
import $ from 'jquery';

const Upcoming = () => {

const upcomClose = () => {
$('#upcomingModal').hide();
}

return (
<>
<div className='upcomingModal' id='upcomingModal'>
<div className='upcomingModalContent'>
<div className='upcomingModalBody'>
<i className='fa fa-close' onClick={upcomClose}></i>
<div className='upcomingModalTxt'>
<img src='/images/comingSoon.png' alt='comingSoon' />
<span className='upComTxt1'>coming soon</span>
<span className='upComTxt2'>try another games</span>
</div>
</div>
</div>
</div>
</>
)
}

export default Upcoming;
