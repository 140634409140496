import React from 'react';

const FancyMarRules = () => {
return (
<>
<div className='toogle-Items fancyMarRules'>
<ul>
<li><span className='danger'>1. Even odd game betting rate rules.</span></li>
<li><span>1.1 Completed game is valid , in case due to rain over are reduced or match abandoned particular game will be deleted.</span></li>
<li><span>1.2 All bets regarding to ODD/EVEN player/partnership are valid if one legal delivery is being played, else the bets will be deleted. Player odd/even all advance bets will be valid if one legal delivery is being played in match otherwise voided.</span></li>
<li><span>1.3 All bets regarding Odd/Even sessions will be deleted if the particular session is incomplete, for example match got abandoned or finished before the end of particular session.</span></li>
<li><span>1.4 In any circumstances management decision will be final.</span></li>
<li><span className='danger'>2 Top batsman rules:-</span></li>
<li><span className='danger'>2.1 If any player does not come as per playing eleven then all bets will be get deleted for the particular player.</span></li>
<li><span>2.2 two players done the same run in a single match (M Agarwal 30 runs and A Rayudu 30 runs, whole inning top batsmen score also 30 run) then both player settlement to be get done 50 percent (50% , 50%)rate on their original value which given by our exchange.</span></li>
<li><span>Suppose we have opened value of M Agarwal 3.75 back and customer place bets on 10000 @ 3.75 rates and A Rayudu 3.0 back and customer place bets on 10000 @ 3.0 rates.</span></li>
<li><span>Whole inning result announces 30 run by both player then</span></li>
<li><span className='danger'>Rule of top batsman:-if you bet on M Agarwal you will be get half amount of this rate (10000*3.75/2=18750 you will get)</span></li>
<li><span className='danger'>Rule of top batsman:-if you bet on A Rayudu you will be get half amount of this rate (10000*3.00/2=15000 you will get)</span></li>
<li><span>Top batsman only 1st inning valid.</span></li>
<li><span>For one day 50 over and for t-20 match 20 over must be played for top batsmen otherwise all bets will be deleted.</span></li>
<li><span className='danger'>Man of the Match Rules</span></li>
<li><span>1. All bets will be deleted in case the match is abandoned or over reduced.</span></li>
<li><span>2. All bets will be deleted if the mentioned player is not included in playing 11.</span></li>
<li><span>3. In case Man of the Match is shared between two players then Dead heat rule will be applicable, For example K Perera and T Iqbal shares the Man of the Match, then the settlement will be done 50% of the rates accordingly.</span></li>
<li><span>4. Rules similar to our Top Batsman rules.</span></li>
<li><span className='danger'>Maximum Sixes by Team</span></li>
<li><span>1. All bets will be deleted if match abandoned or over reduced</span></li>
<li><span>2. All bets will be deleted if both the teams hits same number of sixes.</span></li>
<li><span>3. Super over will not be considered.</span></li>
<li><span className='danger'>Maximum 6 or 10 over runs</span></li>
<li><span>1. All bets will be deleted if match abandoned or over reduced.</span></li>
<li><span>2. All the bets will be deleted if both the teams score is same (Runs scored in 6 or 10 overs)</span></li>
<li><span>3. 6 overs for T20 and 10 overs for ODI</span></li>
<li><span>4. Both the innings are valid.</span></li>
<li><span>5. This fancy will be valid for 1st 6 overs of both innings for T20 and 1st 10 overs of both innings for ODI</span></li>
<li><span className='danger'>Batsman Match</span></li>
<li><span>Batsman Match:- Bets for Favourite batsman from the two batsman matched.</span></li>
<li><span>All bets will be deleted if any one of the mentioned player is not included in playing 11.</span></li>
<li><span>All bets will be deleted unless one ball being played by both the mentioned players.</span></li>
<li><span>All bets will be deleted if over reduced or Match abandoned.</span></li>
<li><span>All bets will be deleted if both the player scored same run. For example H Amla and J Bairstow are the batsman matched, H Amla and J Bairstow both scored 38 runs then all bets will be deleted.</span></li>
<li><span>Both innings will be valid</span></li>
<li><span className='danger'>Opening Pair</span></li>
<li><span>1. Bets for Favourite opening pair from the two mentioned opening pair.</span></li>
<li><span>2. Runs made by both the opening player will be added. For example:- J Roy scored 20 runs and J Bairstow scored 30 runs result will be 50 runs.</span></li>
<li><span>3. Highest run made by the pair will be declared as winner. For example: Opening pair ENG total is 70 runs and Opening pair SA is 90 runs, then SA 90 runs will be declared as winner.</span></li>
<li><span>Both innings will be valid</span></li>
<li><span className='danger'>Our exchange Special</span></li>
<li><span>All bets will be deleted if the mentioned player is not included in playing 11.</span></li>
<li><span>All bets will be deleted if match abandoned or over reduced.</span></li>
<li><span>Both innings will be valid</span></li>
<li><span className='danger'>Direction of First Boundary</span></li>
<li><span>All bets will be deleted if the mentioned batsman is not included in playing 11.</span></li>
<li><span>All bets will be deleted if match abandoned or over reduced.</span></li>
<li><span>The boundary hit through off side of the stump will be considered as off side four.</span></li>
<li><span>The boundary hit through leg side of the stump will be considered as leg side four.</span></li>
<li><span>Boundaries through extras (byes,leg byes,wide,overthrow) will not be considered as valid.</span></li>
<li><span>Only 1st Inning will be considered</span></li>
<li><span className='danger'>Fifty & Century by Batsman</span></li>
<li><span>All bets will be deleted if match abandoned or over reduced.</span></li>
<li><span>All bets will be deleted if the mentioned batsman is not included in playing 11.</span></li>
<li><span>All bets will be deleted unless the batsman faces one legal ball.</span></li>
<li><span>Both Innings will be valid.</span></li>
<li><span className='danger'>1st over Fancy</span></li>
<li><span>Boundaries through extras (byes,leg byes,wide,overthrow) will not be considered.</span></li>
<li><span>Only 1st inning will be valid</span></li>
<li><span className='danger'>Odd Even Fancy</span></li>
<li><span>Incompleted games will be deleted. Over reduced or abandoned all bets will be deleted.</span></li>
<li><span className='danger'>For example:-275 run SL bhav must be played 50 over if rain comes or any condition otherwise 275 run SL bets will be deleted.</span></li>
<li><span className='danger'>Next Man out</span></li>
<li><span>Next man out fancy advance & in regular. Both inning will be valid. If any player does not come in opening then all bets will be deleted. If over reduced or abandoned then all bets will be deleted.</span></li>
<li><span className='danger'>Caught out</span></li>
<li><span>Caught out fancy in advance & in regular. Both inning will be valid. If over reduced or match abandoned then all bets will be deleted.</span></li>
<li><span className='danger'>Wkt & All out Fancy</span></li>
<li><span>5 wkt in 10 over & All out in 20 over fancy is valid for both inning. If match abandoned or over reduced all bets will be deleted.</span></li>
<li><span className='danger'>Test Match: Game Completed Fancy</span></li>
<li><span>1. This is the fancy for match to be won/ completed in which day & session (Completed: Game has to be completed)</span></li>
<li><span>2. If match drawn then all the sessions will be considered as lost.</span></li>
<li><span className='danger'>Meter Fancy</span></li>
<li><span>In case match abandoned or over reduced mid point rule will be applicable</span></li>
<li><span>For example: If Dhoni meter is 75 / 77 and the match abandoned or over reduced, then the result will be 76</span></li>
<li><span>In case of single difference result will be given for the higher rate of the final rate (eg 53/54) and match gets abandoned then the result will be given as 54</span></li>
<li><span>Midpoint rule is applicable for test match also. However for lambi meter/ inning meter 70 overs has to be played only then the same will be considered as valid</span></li>
<li><span className='danger'>Maximum Boundaries:-</span></li>
<li><span>If the number of fours or sixes of both the team is equal, then all bets of the respective event will get voided</span></li>
<li><span className='danger'>Khado:- Test</span></li>
<li><span>Minimum 70 over has to be played by the particular team only then the Khado of the team will be considered as valid, else the same will be voided</span></li>
<li><span className='danger'>Common Market:-</span></li>
<li><span>In future, if any circumstances happens like covid issue , natural disasters or any reasons series will postponed or cancelled then at that moment result will be given to difference of opening rate to present rate.</span></li>
<li><span>Due to any reasons company has rights to take final decisions.</span></li>
<li><span>Common Means Only 1 Win</span></li>
</ul>
</div>
</>
)
}

export default FancyMarRules;
