import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../context/AppContext';

const Notice = () => {

const { noticeAll } = GlobalContext();
const [currNotice, setCurrNotice] = useState([]);

useEffect(() => {
if(noticeAll.length > 0){
setCurrNotice(noticeAll.filter((e) => e.cus_allow === 'true'));
}
},[noticeAll]);

return (
<>
<div className='notificationsWrap'>
<div className='NoticeLabel'><img src='/images/horn.png' alt='horn' /></div>
<div className='noticeTxt'>
{
currNotice.length > 0 ? currNotice.map((data, index) => {
return (
<span key={index}>{data.msg}</span>
)
})
:
<span>no new notifications available...</span>
}
</div>
</div>
</>
)
}

export default Notice;
