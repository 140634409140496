import React from 'react';

const WcupRules = () => {
return (
<>
<div className='toogle-Items wcRules'>
<ul>
<li><span className='danger'>In case of any circumstances, management decision will be final for all the fancies under world cup.</span></li>
<li><span className='danger'>WC:-WORLD CUP</span></li>
<li><span className='danger'>MOM:-MAN OF THE MATCH.</span></li>
<li><span className='danger'>If World Cup fixture of 48 matches gets reduced due to any reason, then all the special fancies will be voided (Match abandoned due to rain/bad light will not be considered in this)</span></li>
<li><span className='danger'>Super over will not be included</span></li>
<li><span className='danger'>At any situation if result is given for any particular event based on the rates given for the same, then the particular result will be considered valid, similarly if the tournament gets canceled due to any reason the previously given result will be considered valid</span></li>
<li><span>Match 1st over run:- This fancy is valid only for first innings of the match, Average 4 runs will be given in case of match abandoned or the entire 50 over is not played.</span></li>
<li><span>Highest inning run:- This fancy is valid only for first innings of the match.</span></li>
<li><span>Lowest innings run:- This fancy is valid only for first innings of the match.</span></li>
<li><span>Total Fours:- Average 45 Fours will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Sixes:- Average 11 Sixes will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Wickets:- Average 15 Wickets will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Wide:- Average 16 Wide will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Extras:- Average 26 Extras will be given if the match is abandoned or over reduced</span></li>
<li><span>Total No ball:- Average 2 No ball will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Fifties:- Average 3 Fifties will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Centuries:- Average 1 century will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Run outs:- Average 1 Run out will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Ducks:- Average 1 Duck out will be given if the match is abandoned or over reduced. If the player is not out in the score of zero the same will not be considered as Duck out.</span></li>
<li><span>Total Caught Out:- Average 9 Caught Out will be given if the match is abandoned or over reduced</span></li>
<li><span>Total Match 1st over Dot ball : Average 4 runs will be given in case match abandoned or over reduced (Only First Innings is Valid)</span></li>
<li><span>Total Match 1st 10 over run:- Average 50 runs will be given in case match abandoned or over reduced (Only First Innings is Valid)</span></li>
<li><span>Total Maidens - Average 4 Maidens will be given in case match abandoned or over reduced</span></li>
<li><span>Total 50+ Partnerships - Average 3 Fifty plus Partnerships will be given in case match abandoned or over reduced. 50 and 50 Above Partnerships All Counted in this.</span></li>
<li><span>Highest 1st over run in individual match: Only First Innings is Valid</span></li>
<li><span>Highest Fours in individual match: Both innings are valid</span></li>
<li><span>Highest Sixes in individual match: Both innings are valid</span></li>
<li><span>Highest Wicket in individual match: Both innings are valid</span></li>
<li><span>Highest Extras in individual match: Both innings are valid</span></li>
<li><span>Highest Scoring runs in Over: Both innings are valid</span></li>
<li><span>Highest Run Scorer : Total Runs Scored by An Individual Batsman in Full Tournament</span></li>
<li><span>Highest Wicket Taker : Total Wickets Taken by a Bowler in Full Tournament</span></li>
<li><span>Most Balls Faced By a Batsman in the Match : Maximum Balls Faced by an Individual Batsman in any Single Match</span></li>
<li><span>Most 4s by a Batsman in the Match : Maximum 4s Hitted by an Individual Batsman in any Single Match</span></li>
<li><span>Most 6s by a Batsman in the Match : Maximum 6s Hitted by an Individual Batsman in any Single Match</span></li>
<li><span>Most Boundaries Given by a Bowler : Maximum Boundaries Given by an Individual Bowler in his Quota of any Single Match</span></li>
<li><span>Most Runs Given by a Bowler in the Match : Maximum Runs Given by an Individual Bowler in his Quota of any Single Match</span></li>
<li><span>Most Dotballs By a Bowler in the Match : Maximum Dotballs Bowled by an Individual Bowler in his Quota of any Single Match</span></li>
<li><span>Most Wides Given by a Bowler in the Match : Maximum Wides Given by an Individual Bowler in his Quota of any Single Match</span></li>
<li><span>Most Wickets by a Bowler in the Match : Maximum Wickets Given by an Individual Bowler in his Quota of any Single Match</span></li>
<li><span>In fastest fifty always the first 50 runs will be considered, for example of R Sharma scores 1st fifty in 17 balls and scores 100 in next 14 balls, fastest 50 will be given based on the balls for the 1st fifty runs</span></li>
<li><span>In fastest Hundred always the first 100 runs will be considered, for example of R Sharma scores 1st Hundred in 60 balls and scores 200 in next 40 balls, fastest 100 will be given based on the balls for the 1st hundred runs</span></li>
<li><span>Total Bowled:- Average 3 Bowled out will be given in case match abandoned or over reduced</span></li>
<li><span>Total LBW:- Average 2 LBW will be given in case match abandoned or over reduced</span></li>
<li><span>Highest 1st 10 over run in individual match: Only First Innings is Valid</span></li>
<li><span>All fancy related to Individual teams are valid only for league matches (9 matches played by the teams in league stages)</span></li>
<li><span>In case any player mentioned in our world cup fancy doesn’t play for the first three consecutive matches all the bets will be deleted.</span></li>
<li><span>1. In case any player mentioned in our world cup fancy got ruled out or doesn’t play post few matches the bets after the last match played by the above mentioned player will be deleted. For example: U Khawaja played for first three league matches and doesn’t play after that, then bets for the first three matches will be valid. Bets after third match will be deleted.</span></li>
<li><span>2. First 10 over runs Only First Innings is Valid</span></li>
<li><span>3. Total runs by team:- Average will be given if the match is abandoned or over reduced</span></li>
<li><span>4. First 10 over runs by team:- Average will be given if the match is abandoned or over reduced</span></li>
<li><span>5. Fours by team:- Average will be given if the match is abandoned or over reduced</span></li>
<li><span>6. Sixes by team:- Average will be given if the match is abandoned or over reduced</span></li>
<li><span>7. Opening wicket partnership:- Average will be given if the match is abandoned or over reduced</span></li>
<li><span>8. Runs by player:- Average will be given if the match is abandoned or over reduced, Average will be given unless one ball is being played after the player enters the crease</span></li>
<li><span>9. Wickets by player:- Average will be given if the match is abandoned or over reduced, Average will be given unless one legal delivery has been bowled by the mentioned player.</span></li>
<li><span>10. Sixes by player:- Average will be given if the match is abandoned or over reduced, Average will be given unless one ball is being played after the player enters the crease.</span></li>
<li><span className='danger'>Average of every fancy follows:</span></li>
<li><span className='danger'>Total runs by ENG 295 runs per game</span></li>
<li><span>First 10 over runs of ENG 56 runs per game</span></li>
<li><span>Total Fours by ENG 25 fours per game</span></li>
<li><span>Total Sixes by ENG 7 sixes per game</span></li>
<li><span>Opening wicket partnership runs of ENG 44 runs per game</span></li>
<li><span>J Roy runs 38 runs per game</span></li>
<li><span>J Root runs 43 runs per game</span></li>
<li><span>J Archer wickets 2 wickets per game</span></li>
<li><span>C Woakes wickets 2 wickets per game</span></li>
<li><span>A Rashid wickets 2 wickets per game</span></li>
<li><span>J Bairstow Sixes 2 sixes per game</span></li>
<li><span className='danger'>Total runs by IND 285 runs per game</span></li>
<li><span className='danger'>Total runs by ENG 295 runs per game</span></li>
<li><span>First 10 over runs of ENG 56 runs per game</span></li>
<li><span>Total Fours by ENG 25 fours per game</span></li>
<li><span>Total Sixes by ENG 7 sixes per game</span></li>
<li><span>Opening wicket partnership runs of ENG 44 runs per game</span></li>
<li><span>J Roy runs 38 runs per game</span></li>
<li><span>J Bairstow runs 43 runs per game</span></li>
<li><span>J Root runs 43 runs per game</span></li>
<li><span>J Archer wickets 2 wickets per game</span></li>
<li><span>C Woakes wickets 2 wickets per game</span></li>
<li><span>A Rashid wickets 2 wickets per game</span></li>
<li><span>J Bairstow Sixes 2 sixes per game</span></li>
<li><span>J Buttler Sixes 2 sixes per game</span></li>
<li><span className='danger'>Total runs by IND 285 runs per game</span></li>
<li><span>Total Four by IND 26 fours per game</span></li>
<li><span>Total Sixes by IND 6 sixes per game</span></li>
<li><span>Opening wicket partnership runs of IND 41 runs per game</span></li>
<li><span>S Dhawan runs 38 runs per game</span></li>
<li><span>R Sharma runs 43 runs per game</span></li>
<li><span>V Kohli runs 48 runs per game</span></li>
<li><span>J Bumrah wickets 2 wickets per game</span></li>
<li><span>M Shami wickets 2 wickets per game</span></li>
<li><span>K Yadav wickets 2 wickets per game</span></li>
<li><span>R Sharma Sixes 2 sixes per game</span></li>
<li><span>H Pandya Sixes 1 sixes per game</span></li>
<li><span className='danger'>Total runs by AUS 280 runs per game</span></li>
<li><span>First 10 over runs of AUS 52 runs per game</span></li>
<li><span>Total Four by AUS 26 fours per game</span></li>
<li><span>Total Sixes by AUS 6 sixes per game</span></li>
<li><span>Opening wicket partnership runs of AUS 40 runs per game</span></li>
<li><span>D Warner runs 43 runs per game</span></li>
<li><span>A Finch runs 38 runs per game</span></li>
<li><span>S Smith runs 42 runs per game</span></li>
<li><span>M Starc wickets 2 wickets per game</span></li>
<li><span>P Cummins wickets 2 wickets per game</span></li>
<li><span>A Zampa wickets 2 wickets per game</span></li>
<li><span>D Warner Sixes 2 sixes per game</span></li>
<li><span className='danger'>Total runs by SA 270 runs per game</span></li>
<li><span>First 10 over runs of SA 51 runs per game</span></li>
<li><span>Total Fours by SA 24 fours per game</span></li>
<li><span>Total Sixes by SA 5 sixes per game</span></li>
<li><span>Opening wicket partnership runs of SA 37 runs per game</span></li>
<li><span>H Amla runs 38 runs per game</span></li>
<li><span>F Du plessis runs 39 runs per game</span></li>
<li><span>V Der Dussen runs Runs per game</span></li>
<li><span>Q De Kock runs 36 Runs per game</span></li>
<li><span>I Tahir wickets 2 wickets per game</span></li>
<li><span>K Rabada wickets 2 wickets per game</span></li>
<li><span>Q De Kock Sixes 1 sixes per game</span></li>
<li><span className='danger'>Total runs by NZ 275 runs per game</span></li>
<li><span>First 10 over runs of NZ 50 runs per game</span></li>
<li><span>Total Fours by NZ 25 fours per game</span></li>
<li><span>Total Sixes by NZ 5 sixes per game</span></li>
<li><span>Opening wicket partnership runs of NZ 37 runs per game</span></li>
<li><span>D Steyn wickets 2 wickets per game</span></li>
<li><span>C Munro runs 32 runs per game</span></li>
<li><span>M Guptill runs 38 runs per game</span></li>
<li><span>K Williamson runs 45 runs per game</span></li>
<li><span>H Nicholls runs Runs per game</span></li>
<li><span>T Boult wickets 2 wickets per game</span></li>
<li><span>T Southee wickets 1 wickets per game</span></li>
<li><span>M Santner wickets 1 wickets per game</span></li>
<li><span>M Guptill Sixes 2 Sixes per game</span></li>
<li><span className='danger'>Total runs by WI 270 runs per game</span></li>
<li><span>First 10 over runs of WI 49 runs per game</span></li>
<li><span>Total Fours by WI 23 fours per game</span></li>
<li><span>Total Sixes by WI 7 sixes per game</span></li>
<li><span>Opening wicket partnership runs of WI 35 runs per game</span></li>
<li><span>C Gayle runs 37 runs per game</span></li>
<li><span>E Lewis runs 32 runs per game</span></li>
<li><span>DM Bravo runs 32 runs per game</span></li>
<li><span>S Hope runs 37 runs per game</span></li>
<li><span>K Roach wickets 1 wickets per game</span></li>
<li><span>S Cottrell wickets 1 wickets per game</span></li>
<li><span>J holder wickets 1 wicket per game</span></li>
<li><span>A Nurse wickets 1 wickets per game</span></li>
<li><span className='danger'>Total runs by PAK 270 runs per game</span></li>
<li><span>First 10 over runs of PAK 50 runs per game</span></li>
<li><span>Total Fours by PAK 24 fours per game</span></li>
<li><span>Total Sixes by PAK 5 sixes per game</span></li>
<li><span>Opening wicket partnership runs of PAK 36 runs per game</span></li>
<li><span>Imam Ul Haq runs 36 runs per game</span></li>
<li><span>B Azam runs 44 runs per game</span></li>
<li><span>F Zaman runs 34 runs per game</span></li>
<li><span>H Ali wickets 2 wickets per game</span></li>
<li><span>Shadab Khan wickets 2 wickets per game</span></li>
<li><span>Shaheen Afridi wickets 2 wickets per game</span></li>
<li><span>F Zaman Sixes 1 sixes per game</span></li>
<li><span className='danger'>C Gayle Sixes 2 Sixes per game</span></li>
<li><span className='danger'>A Russell Sixes 2 Sixes per game</span></li>
<li><span className='danger'>Total runs by SL 250 runs per game</span></li>
<li><span>First 10 over runs of SL 48 runs per game</span></li>
<li><span>Total Fours by SL 22 fours per game</span></li>
<li><span>Total Sixes by SL 4 sixes per game</span></li>
<li><span>Opening wicket partnership runs of SL 32 runs per game</span></li>
<li><span>D Karunaratne runs 31 runs per game</span></li>
<li><span>L Thirimanne runs 29 runs per game</span></li>
<li><span>K Mendis runs 33 runs per game</span></li>
<li><span>L Malinga wickets 1 wickets per game</span></li>
<li><span>S Lakmal wickets 1 wickets per game</span></li>
<li><span>J Vandersay wickets 1 wickets per game</span></li>
<li><span>T Perera Sixes 1 sixes per game</span></li>
<li><span className='danger'>Total runs by BAN 245 runs per game</span></li>
<li><span>First 10 over runs of BAN 48 runs per game</span></li>
<li><span>Total Fours by BAN 22 fours per game</span></li>
<li><span>Total Sixes by BAN 4 sixes per game</span></li>
<li><span>Opening wicket partnership runs of BAN 32 runs per game</span></li>
<li><span>T Iqbal runs 34 runs per game</span></li>
<li><span>S Sarkar runs 29 runs per game</span></li>
<li><span>M Rahim runs 31 runs per game</span></li>
<li><span>M Hasan wickets 1 wickets per game</span></li>
<li><span>M Rahman wickets 1 wickets per game</span></li>
<li><span>M Mortaza wickets 1 wickets per game</span></li>
<li><span>T Iqbal Sixes 1 sixes per game</span></li>
<li><span className='danger'>Total runs by AFG 235 runs per game</span></li>
<li><span>First 10 over runs of AFG 46 runs per game</span></li>
<li><span>Total Fours by AFG 20 fours per game</span></li>
<li><span>Total Sixes by AFG 4 sixes per game</span></li>
<li><span>Opening wicket partnership runs of AFG 28 runs per game</span></li>
<li><span>R Shah runs 27 runs per game</span></li>
<li><span>H Zazai runs 26 runs per game</span></li>
<li><span>A Afghan runs Runs per game</span></li>
<li><span>M Shahzad runs 27 runs per game</span></li>
<li><span>D Zadran wickets 1 wickets per game</span></li>
<li><span>Rashid khan wickets 2 wickets per game</span></li>
<li><span>Mujeeb ur rahman wickets 1 wickets per game</span></li>
<li><span>H Zazai Sixes 1 sixes per game</span></li>
</ul>
</div>
</>
)
}

export default WcupRules;
