import React from 'react';
import $ from 'jquery';

const BookFancy = () => {

const closeBook = () => {
$('#fancyBook_div').hide();
}

return (
<>
<div className='fancyBook_div' id='fancyBook_div'>
<div className='fancyBook_content'>
<div className='fancyBook_head'><span id='fancyBook_headTxt'>Book</span> <i className='fa fa-close closeModalTcon' onClick={() => closeBook()}></i></div> 
<div className='fancyBook_body'>

<div className='book-loader'>
<img src='/images/searchBook.gif' alt='searchBook' />
</div>

<table className='bookTbale'>
<thead>
<tr>
<th>run</th>
<th>amount</th>
</tr>
</thead>  
<tbody id='bookTbody'></tbody>  
</table>    
</div> 
</div>
</div>
</>
)
}

export default BookFancy;
