import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
return (
<>
<div className='footer'>
<div className='footer-row'>
<div className='col'><img src='/images/whatsapp.png' alt='footImg' /> <span>+91 xxxxxx xxxx</span></div>
<div className='col'><img src='/images/paper.png' alt='footImg' /> <span>info@24exchangebet.com</span></div>
<div className='col'><img src='/images/instagram.png' alt='footImg' /> <span>official24exchangebet</span></div>
</div>
<div className='footer-row2'>
<div className='cols'><img src='/images/Betfair.webp' alt='foot2Img' className='fottBet-img' /><span className='pwrd'>Powered By</span> </div>
<div className='cols'><img src='/images/telephone-call.png' alt='foot2img' className='fottBet-img2' /> <span>+91 xxxxxx xxxx / +91 xxxxxx xxxx</span></div>
<div className='cols'><img src='/images/mail.png' alt='foot2img' className='fottBet-img2' /> <span>support@24exchangebet.com</span></div>
</div>
<div className='footer-row3'>
<div>
<img src='/images/chrome.png' alt='foot3img' />
<img src='/images/firefox.png' alt='foot3img' />
</div>
<span>Our website works best in the newest and last version of these browsers: Google Chrome & Firefox.</span>
</div>
<hr className='footHr' />
<div className='footer-row4'>
<ul>
<li><Link to='/privacy'>Privacy Policy</Link></li>
<li><Link to='/terms'>Terms & Conditions</Link></li>
<li><Link to='/kyc'>Kyc</Link></li>
<li><Link to='/responsible-gaming'>Responsible Gaming</Link></li>
<li><Link to='/about'>About Us</Link></li>
</ul>
</div>
</div>
</>
)
}

export default Footer;
