import React, { useEffect } from 'react';
import $ from 'jquery';
import Normal from './Normal';
import Fancy1 from './Fancy1';
import Over from './Over';
import Ball from './Ball';
import OddEven from './OddEven';
import Khado from './Khado';
import Meter from './Meter';
import Line from './Line';

const MainFancy = () => {

function fancyBet(){
$('#mainFancy').show();
$('.optionF').css({display: 'flex'});
$('#normalFancy').show();
$('#lineMarket').hide();
$('#OverByOver').hide();
$('#BallByBall').hide();
$('#meterMarket').hide();
$('#khadoMarket').hide();
$('.optionS').hide();
$('.optionP').hide();
$('#sportBookFancy').hide();
$('#casinoFancy').hide();
}    

function optionF_all(){
$('#normalFancy').show();
$('#lineMarket').hide();
$('#OverByOver').hide();
$('#BallByBall').hide();
$('#meterMarket').hide();
$('#khadoMarket').hide();
}

function optionF_line(){
$('#normalFancy').hide();
$('#lineMarket').show();
$('#OverByOver').hide();
$('#BallByBall').hide();
$('#meterMarket').hide();
$('#khadoMarket').hide();
}

function optionF_over(){
$('#normalFancy').hide();
$('#lineMarket').hide();
$('#OverByOver').show();
$('#BallByBall').hide();
$('#meterMarket').hide();
$('#khadoMarket').hide();
}

function optionF_ball(){
$('#normalFancy').hide();
$('#lineMarket').hide();
$('#OverByOver').hide();
$('#BallByBall').show();
$('#meterMarket').hide();
$('#khadoMarket').hide();
}

function optionF_meter(){
$('#normalFancy').hide();
$('#lineMarket').hide();
$('#OverByOver').hide();
$('#BallByBall').hide();
$('#meterMarket').show();
$('#khadoMarket').hide();
}

function optionF_khado(){
$('#normalFancy').hide();
$('#lineMarket').hide();
$('#OverByOver').hide();
$('#BallByBall').hide();
$('#meterMarket').hide();
$('#khadoMarket').show();
}

function sportBookBet(){
$('#sportBookFancy').show();
$('.optionS').css({display: 'flex'});
$('#OddEven').show();
$('#Fancy1').hide();
$('.optionF').hide();
$('.optionP').hide();
$('#mainFancy').hide();
$('#casinoFancy').hide();
}

function optionS_all(){
$('#OddEven').show();
$('#Fancy1').hide();
}

function optionS_oddEven(){
$('#OddEven').show();
$('#Fancy1').hide();
}

function optionS_fancy1(){
$('#OddEven').hide();
$('#Fancy1').show();
}

useEffect(() => {
$('.optF').on('click', function(){
$(this).addClass('active').siblings().removeClass('active');
});

$('.optS').on('click', function(){
$(this).addClass('active').siblings().removeClass('active');
});

$('.optP').on('click', function(){
$(this).addClass('active').siblings().removeClass('active');
});
},[]);

return (
<>
<div className='fancy-marketWrap'>
<div className='fancy-market-options'>
<div className='fancyMainNew'>
<div onClick={() => fancyBet()}><span>fancy bet</span></div>
<div><i className='fa fa-exclamation-circle'></i></div>
</div>

<div className='fancyMainNew2'>
<div onClick={() => sportBookBet()}><span>sportsbook</span></div>
<div><i className='fa fa-exclamation-circle'></i></div>
</div>

{
/*
<div className='fancyMainNew3'>
<div><span>casino</span></div>
<div><i className='fa fa-exclamation-circle'></i></div>
</div>
*/
}

</div>

<div className='market-options optionF'>
<div className='optionFWrap'>
<ul>
<li className='optF active' onClick={() => optionF_all()}>all</li>
<li className='optF' onClick={() => optionF_line()}>line market</li>
<li className='optF' onClick={() => optionF_over()}>over by over</li>
<li className='optF' onClick={() => optionF_ball()}>ball by ball</li>
<li className='optF' onClick={() => optionF_meter()}>meter market</li>
<li className='optF' onClick={() => optionF_khado()}>khado market</li>
</ul>
</div>
</div>

<div className='market-options optionS'>
<div className='optionSWrap'>
<ul>
<li className='optS active' onClick={() => optionS_all()}>all</li>
<li className='optS' onClick={() => optionS_oddEven()}>odd even</li>
<li className='optS' onClick={() => optionS_fancy1()}>fancy1</li>
</ul>
</div>
</div>

<div className='market-options optionP'>
<div className='optionPWrap'>
<ul>
<li className='optS active'>all</li>
<li className='optS'>fancy</li>
<li className='optS'>line market</li>
<li className='optS'>ball by ball</li>
<li className='optS'>meter market</li>
<li className='optS'>khado market</li>
</ul>
</div>
</div>

<div id='mainFancy'>
<div id='normalFancy'>
<Normal />
</div>
<div className='hideElem' id='lineMarket'>
<Line />
</div>
<div className='hideElem' id='OverByOver'>
<Over />
</div>
<div className='hideElem' id='BallByBall'>
<Ball />
</div>
<div className='hideElem' id='meterMarket'>
<Meter />
</div>
<div className='hideElem' id='khadoMarket'>
<Khado />
</div>
</div>

<div className='hideElem' id='sportBookFancy'>
<div className='hideElem' id='OddEven'>
<OddEven />
</div>
<div className='hideElem' id='Fancy1'>
<Fancy1 />
</div>
</div>

<div className='hideElem' id='casinoFancy'></div>
</div>
</>
)
}

export default MainFancy;
