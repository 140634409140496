import React from 'react'

const KabaddiRules = () => {
return (
<>
<div className='toogle-Items kabaddiRules'>
<ul>
<li><span>In any circumstances management decision will be final related to all Fancy of kabaddi of our exchange.</span></li>
<li><span>All fancy bets will be validated when match has been tied.</span></li>
<li><span>The results Result of individual player of fancy will be validated only when player play that match.</span></li>
<li><span>In any case wrong rate has been given in fancy that particular bets will be deleted.</span></li>
<li><span>For Playoffs Final Result Of 40 Minutes Of Two Halves Will Be Valid In Our Exchange</span></li>
</ul>
</div>
</>
)
}

export default KabaddiRules
