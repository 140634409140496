import React from 'react';

const SoccerFancyRules = () => {
return (
<>
<div className='toogle-Items soccerFancyRules'>
<ul>
<li><span className='danger'>Tournament Total Goals, Team Total Goals goals.scored in 90 minutes or in extra-time will count.Goals scored in penalty shootouts do not count.</span></li>
<li><span className='danger'>Tournament Corners - Only corners taken in 90 minutes count.</span></li>
<li><span className='danger'>Tournament Penalties Missed/Converted - Penalties taken in 90 minutes, extra-time and penalty shootouts all count. If a penalty has to be re-taken the previous disallowed penalty(ies) do not count.</span></li>
<li><span>Match Odds: - All bets apply to the relevant full 'regular time' period including stoppage time. Any extra-time and/or penalty shoot-out is not included. For the cancellation of a goal, due to VAR, bets matched between the time of the goal being scored and the time at which the video assistant referee finishes the review will be voided. For the cancellation of a red card, due to VAR, bets matched after the time at which the video assistant referee commences the review will be voided.</span></li>
<li><span>Corners Number/Odds: - How many corners will be taken in the match? Only corners that are taken will be counted. All bets apply to Full Time according to the match officials, plus any stoppage time. Extra-time/penalty shoot-outs are not included.</span></li>
</ul>
</div>
</>
)
}

export default SoccerFancyRules;
