import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../context/AppContext';

const MainBal = () => {

const { customer, exposure } = GlobalContext();

const [customerBal, setCustomerBal] = useState(0);
const [customerExpo, setCustomerExpo] = useState(0);

useEffect(() => {

if(customer.length > 0){
setCustomerBal(customer[0].total_balance);
}
else{
setCustomerBal(0);  
}

if(exposure.length > 0){
if(exposure[0].exposure !== null){
setCustomerExpo(exposure[0].exposure);
}
else{
setCustomerExpo(0);
}
}

},[customer, exposure]);

return (
<>
<div className='ExpoBalDiv'>
<div className='loadingDots_sm'>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>
<span></span>
</div>

<div className='mainBalWrapFull'>
<div className='upDown'><span>{customer.length > 0 && customer[0].currency}</span></div> 
<div className='mainBalWrap'>
<div className='ExpoWrap'>
<span className='danger'>Bal:</span>
<span className='rupee'>₹</span>
<span id='mainBal'>{parseFloat((customerBal - customerExpo).toFixed(2)).toLocaleString()}</span>
</div>
<div className='ExpoWrap'>
<span className='danger'>Exp:</span>
<span className='rupee'>₹</span>
<span id='mainExpo'>{parseFloat((customerExpo).toFixed(2)).toLocaleString()}</span>
</div>
</div>
</div>
</div>
</>
)
}

export default MainBal;
