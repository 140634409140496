import React from 'react';
import $ from 'jquery';
import axios from 'axios';

const DemoLogin = () => {

const closeDemoLog = () => {
$('#demoLoginModal').hide();
}

const demoLogin = async() => {
var customer = 'demo';
var password = 'demo123';
$('#demologinBtn').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/login', {customer: customer, password: password});
const resData = await sendData.data;
if(resData === 'success'){
$("#msgAlert").fadeIn().addClass('alert-success').removeClass('alert-danger').html('login successfull');
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
$('#demologinBtn').removeAttr('disabled');
},1000);
}
} catch (error) {
console.log('frontend demo-login-api error: ' + error);   
}
}

const demoLogin2 = async() => {
var customer = 'demo2';
var password = 'demo12345';
$('#demologinBtn2').attr('disabled', 'disabled');
try {
const sendData = await axios.post('/server/login', {customer: customer, password: password});
const resData = await sendData.data;
if(resData === 'success'){
$("#msgAlert").fadeIn().addClass('alert-success').removeClass('alert-danger').html('login successfull');
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
window.location.reload();
},1000);
return false;   
}
else{
$("#msgAlert").fadeIn().addClass('alert-danger').removeClass('alert-success').html(resData);
setTimeout(function(){
$("#msgAlert").fadeOut('slow');
$('#demologinBtn2').removeAttr('disabled');
},1000);
}
} catch (error) {
console.log('frontend demo2-login-api error: ' + error);   
}
}

return (
<>
<div className='demoLoginModal' id='demoLoginModal'>
<div className='demoLoginModalCon'>
<div className='demoLoginModalHead'>
<span>demo login</span>
<i className='fa fa-close' onClick={closeDemoLog}></i>
</div>
<div className='demoLoginModalBody'>
<div className='demoLoginModalWrap'>
<span className='ponTxt1'>Rupee Login (INR)</span>
<button id='demologinBtn' onClick={() => demoLogin()}>Demo (INR)</button>
<span className='ponTxt2'>Point Login (HKD)</span>
<button id='demologinBtn2' onClick={() => demoLogin2()}>Demo2 (HKD)</button>
</div>
</div>
</div>
</div>
</>
)
}

export default DemoLogin;
